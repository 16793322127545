import {FC, useState} from 'react'
import { ProofComponentProps } from './interface'
import classes from './proofcomponent.module.scss'
import dayjs from 'dayjs';
import { useAppSelector } from '../../store/reduxHooks';
import { MdCheck, MdChecklistRtl, MdOutlineLibraryAdd, MdPlaylistAddCheck } from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';
import cx from 'classnames'
import { useHandleAddObjection, useHandleAddProof } from '../../hooks/useNewProofs';

export const ProofComponent:FC<ProofComponentProps> = (props) => {
    const {data} = props
    const {argument, objection, proof, date, creator} = data
    const formattedDate = dayjs(date).format('DD.MM.YYYY')
    const users = useAppSelector(state => state.users.users)
    const myUser = users.find(user => user.id === creator)

    const [objectionInput, setObjectionInput] = useState(false)
    const handleAddObjection = () => setObjectionInput(value => !value)

    const [proofInput, setProofInput] = useState(false)
    const handleAddProof = () => setProofInput(value => !value)

    const checkInfo = () => {
        if(!objection.length || !proof.length) {
            return false
        } else {return true}
    }
    const fullInfo = checkInfo()

    const [newObjection, setNewObjection] = useState<string>(objection)
    const [newProof, setNewProof] = useState<string>(proof)


    const updateObjection = useHandleAddObjection(data, newObjection)
    const updateProof = useHandleAddProof(data, newProof)

    const handleUpdateObjectionClick = () => {
        if(newObjection.length) {
            updateObjection()
            handleAddObjection()
        } else{alert('Заполните поле')}
    }

    const handleUpdateProofClick = () => {
        if(newProof.length) {
            updateProof()
            handleAddProof()
        } else{alert('Заполните поле')}
    }

  return (
    <div className={classes.root}>
        <div className={cx(classes.proofTitle, fullInfo === false && classes.proofTitleNotFullInfo)}>
            <div className={classes.proofInfo}>
                Создатель: {myUser?.label}
            </div>
            <div className={classes.proofInfo}>
                Дата создания: {formattedDate}
            </div>
        </div>
        <div className={classes.proofMainInfo}>
            <div className={classes.argumentInfo}>
                {argument}
            </div>
            <div className={classes.argumentInfo}>
                {objection.length ? (
                    <div>
                        {objection}
                    </div>
                ) : (
                    <>
                        {objectionInput === false ? (
                            <div className={classes.addNew} onClick={handleAddObjection}>
                                <MdOutlineLibraryAdd  size={25}  />
                            </div>                    
                        ) : (
                            <div className={classes.inputBlock}>
                                <input className={classes.input}                 
                                value={newObjection}
                                type={'text'}
                                placeholder={'Возражения'}
                                onChange={(e) => setNewObjection(e.target.value)} />
                                <div className={classes.changeButton} onClick={handleUpdateObjectionClick}>
                                    Изменить
                                </div>
                                <div className={classes.closeIcon} onClick={handleAddObjection}>
                                    <RiCloseFill size={18} />
                                </div>
                            </div>
                        )}
                    </>
                )}
                
            </div>
            <div className={classes.argumentInfo}>
                {proof.length ? (
                    <div>
                        {proof}
                    </div>
                ) : (
                    <>
                    {proofInput === false ? (
                        <div className={classes.addNew}>
                            <MdOutlineLibraryAdd  size={25} onClick={handleAddProof} />
                        </div>                  
                    ) : (
                        <div className={classes.inputBlock}>
                            <input className={classes.input}                                 
                                value={newProof}
                                type={'text'}
                                placeholder={'Возражения'}
                                onChange={(e) => setNewProof(e.target.value)} />
                            <div className={classes.changeButton} onClick={handleUpdateProofClick}>
                                Изменить
                            </div>
                            <div className={classes.closeIcon} onClick={handleAddProof}>
                                <RiCloseFill size={18} />
                            </div>
                        </div>
                    )}
                </>

                )}
            </div>
            <div className={classes.buttonsRoot}>
                <div className={classes.buttonField}>
                    <MdCheck size={25} />
                </div>
                <div className={classes.buttonField}>
                    <MdChecklistRtl size={25} />
                </div>
                <div className={classes.buttonField}>
                    <MdPlaylistAddCheck size={25} />
                </div>
            </div>
        </div>
        
    </div>
  )
}
