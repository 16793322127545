import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Case } from '../../api/interface';
import { v4 as uuid } from 'uuid';

const initialState: Case[] = []



export const caseSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        getCases(state, action) {
            return action.payload
        }
    }
})

export const {
    getCases
} = caseSlice.actions
export default caseSlice.reducer