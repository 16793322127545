import { useCallback } from "react";
import { useGetNewEvents } from "./useGetDocs";
import { arrayRemove, arrayUnion, doc, updateDoc, } from "firebase/firestore";
import { db } from "../firebase.config";
import { v4 as uuid } from 'uuid';
import { useAppSelector } from "../store/reduxHooks";


export function useHandleAddEvents(
    eventName: string,
    updateTime: string,
    finishTime: string,
    lawyer: string,
    disputId: string | undefined
    ) {
    const UniqeId = uuid()
    const getMyEvents = useGetNewEvents()
    return useCallback(() =>{
        const newEventItem = {
            id: UniqeId,
            title: eventName,
            start: updateTime,
            end: finishTime,
            lawyer: lawyer,
            disputId: disputId,
            isVisited: false,
            conclusion: ''
        }
        updateDoc(doc(db, 'newEvents', 'lawEvents'),{
            events: arrayUnion(newEventItem)
        }).then(getMyEvents)
    }, [UniqeId, disputId, eventName, finishTime, getMyEvents, lawyer, updateTime])
};

export function useHandleChageEvents(
    eventName: string,
    updateTime: string,
    finishTime: string,
    newLawyer: string,
    disputId: string | undefined,
    id: string
    ) {
        const getMyEvents = useGetNewEvents()
        const events = useAppSelector(state => state.newEvents.events)
        const myOldEvent = events.find((event) => event.id === id)
        return useCallback(() => {
            const newEventItem = {
                title: eventName,
                start: updateTime,
                end: finishTime,
                lawyer: newLawyer,
                disputId: disputId,
                id: id,
                isVisited: myOldEvent?.isVisited,
                conclusion: myOldEvent?.conclusion
            }
            updateDoc(doc(db, 'newEvents', 'lawEvents'), {
                events: arrayRemove(myOldEvent)
            })
            updateDoc(doc(db, 'newEvents', 'lawEvents'), {
                events: arrayUnion(newEventItem)
            }).then(getMyEvents)
        }, [disputId, eventName, finishTime, getMyEvents, id, myOldEvent, newLawyer, updateTime])
};

export function useHandleDeleteEvents(
    id: string
) {
    const getMyEvents = useGetNewEvents()
    const events = useAppSelector(state => state.newEvents.events)
    const myOldEvent = events.find((event) => event.id === id)
    return useCallback(() => {
        updateDoc(doc(db, 'newEvents', 'lawEvents'), {
            events: arrayRemove(myOldEvent)
        }).then(getMyEvents)
    }, [getMyEvents, myOldEvent])
}

export function useUpdateIsViitedEventsTrue(
    myEventId: string,
    isVisited: boolean,
    conclusion: string,
) {
    const getMyEvents = useGetNewEvents()
    const events = useAppSelector(state => state.newEvents.events)
    const myOldEvent = events.find((event) => event.id === myEventId)

    return useCallback(() => {

        const newEventItem = {
            title: myOldEvent?.title,
            start: myOldEvent?.start,
            end: myOldEvent?.end,
            lawyer: myOldEvent?.lawyer,
            disputId: myOldEvent?.disputId,
            id: myOldEvent?.id,
            isVisited: true,
            conclusion: conclusion
        }
        updateDoc(doc(db, 'newEvents', 'lawEvents'), {
            events: arrayRemove(myOldEvent)
        })
        updateDoc(doc(db, 'newEvents', 'lawEvents'), {
            events: arrayUnion(newEventItem)
        }).then(getMyEvents)

    }, [getMyEvents, myEventId, isVisited, conclusion])

}

export function useUpdateIsViitedEventsFalse(
    myEventId: string,
    isVisited: boolean,
    conclusion: string,
) {
    const getMyEvents = useGetNewEvents()
    const events = useAppSelector(state => state.newEvents.events)
    const myOldEvent = events.find((event) => event.id === myEventId)

    return useCallback(() => {

        const newEventItem = {
            title: myOldEvent?.title,
            start: myOldEvent?.start,
            end: myOldEvent?.end,
            lawyer: myOldEvent?.lawyer,
            disputId: myOldEvent?.disputId,
            id: myOldEvent?.id,
            isVisited: false,
            conclusion: 'Мы не ходили в заседание'
        }
        updateDoc(doc(db, 'newEvents', 'lawEvents'), {
            events: arrayRemove(myOldEvent)
        })
        updateDoc(doc(db, 'newEvents', 'lawEvents'), {
            events: arrayUnion(newEventItem)
        }).then(getMyEvents)

    }, [getMyEvents, myEventId, isVisited, conclusion])

}