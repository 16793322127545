import React, {FC} from 'react';
import { ChatBarProps } from './interface';
import classes from './chatbar.module.scss'
import { useAppSelector } from '../../../store/reduxHooks';
import { ChatItem } from '../ChatItem';

export const ChatBar : FC<ChatBarProps> = () => {
  return (
    <div className={classes.root}>
        <div className={classes.header}>
            All Chats
        </div>

    </div>
  )
}

