import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { useCallback } from 'react';
import { useAppDispatch } from '../store/reduxHooks';
import { useAuth } from './useAuth';
import { getClients } from '../store/slices/clientSlice';
import { getCases } from '../store/slices/caseSlice';
import { getDisputes } from '../store/slices/disputesSlice';
import { getEvents } from '../store/slices/eventSlice';
import { getNewEvents } from '../store/slices/newEventsSlice';
import { getNewClients } from '../store/slices/newClientsSlice';
import { getNewCases } from '../store/slices/newCasesSlice';
import { getNewDisputes } from '../store/slices/newDisputsDlice';
import { getNewContacts } from '../store/slices/newContactsSlice';
import { getNewClientTasks } from '../store/slices/newClientTaskSlice';
import { getNewCaseTasks } from '../store/slices/newCaseTaskSlice';
import { getNewDisputTasks } from '../store/slices/newDiputTaskSlice';
import { getNewBillingItems } from '../store/slices/newBillingItemSlice';
import { getNewAbonentCases } from '../store/newSlices/newAbonentCasesSlice';
import { getNewKadastrCases } from '../store/newSlices/newKadastrCasesSlice';
import { getNewOtcenkaCases } from '../store/newSlices/newOtcenkaCasesSlice';
import { getNewArbitrCases } from '../store/newSlices/newSudArbitrCasesSlice';
import { getNewBancrotCases } from '../store/newSlices/newSudBancrotCasesSlice';
import { getNewSimpleCases } from '../store/newSlices/newSudSimpleCasesSlice';
import { getNewAdminTasks } from '../store/newSlices/newAdminTasksSlice';
import { getNewDocuments } from '../store/slices/newDocumetSlice';
import { getNewBills } from '../store/slices/newBillSlice';
import { getNewProofs } from '../store/slices/newProofSlice';

export function useGetClients() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
      getDocs(collection(db, 'clients'))
      .then((res) =>
        dispatch(getClients(res.docs.map((el) => ({ ...el.data(), id: el.id })))),
      );
  }, [dispatch, isAuth]);
}

export function useGetCases() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
    getDocs(collection(db, 'cases'))
    .then((res) =>
    dispatch(getCases(res.docs.map((el) => ({ ...el.data(), id: el.id }))))
    )
  }, [dispatch, isAuth])
  };

export function useGetDisputs() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
    getDocs(collection(db, 'disputes')).then((res) =>
      dispatch(getDisputes(res.docs.map((el) => ({ ...el.data(), id: el.id }))))
    )
  }, [dispatch, isAuth])
};

export function useGetEvents() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(() => {
    isAuth &&
    getDocs(collection(db, 'events')).then((res) =>
      dispatch(getEvents(res.docs.map((el) => ({ ...el.data(), id: el.id }))))
    )
  }, [dispatch, isAuth])
};

export function useGetNewClients() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    const docClients = doc(db, "newClients", "clients");
    const docClientSnap = await getDoc(docClients);
    return isAuth &&
      dispatch(getNewClients(docClientSnap.data()))
  }, [dispatch, isAuth]);
}

export function useGetNewCases() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback( async() => {
    const docCases = doc(db, "newCases", "cases");
    const docCaseSnap = await getDoc(docCases);
    return isAuth &&
    dispatch(getNewCases(docCaseSnap.data()))
  }, [dispatch, isAuth])
  };

export function useGetNewDisputs() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docDisputes = doc(db, "newDisputes", "disputes");
      const docDisputSnap = await getDoc(docDisputes);
      return isAuth &&
      dispatch(getNewDisputes(docDisputSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewEvents() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docLaw = doc(db, "newEvents", "lawEvents");
      const docLawSnap = await getDoc(docLaw); 
      // const docMeetings = doc(db, "newEvents", "meetingEvents");
      // const docMeetingsSnap = await getDoc(docMeetings);
        return isAuth &&
        dispatch(getNewEvents(docLawSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewOtherEvents() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docLaw = doc(db, "newEvents", "otherEvents");
      const docLawSnap = await getDoc(docLaw); 
        return isAuth &&
        dispatch(getNewEvents(docLawSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewContacts() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docContacts = doc(db, "newContacts", "clientContacts");
      const docContactSnap = await getDoc(docContacts);
      return isAuth &&
      dispatch(getNewContacts(docContactSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewClientTasks() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docClientTasks = doc(db, "newTasks", "clientsTasks");
      const docClientTaskSnap = await getDoc(docClientTasks);
      return isAuth &&
      dispatch(getNewClientTasks(docClientTaskSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewCaseTasks() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docCaseTasks = doc(db, "newTasks", "caseTasks");
      const docCaseTaskSnap = await getDoc(docCaseTasks);
      return isAuth &&
      dispatch(getNewCaseTasks(docCaseTaskSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewDisputTasks() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docDiputTasks = doc(db, "newTasks", "disputTasks");
      const docDiputTaskSnap = await getDoc(docDiputTasks);
      return isAuth &&
      dispatch(getNewDisputTasks(docDiputTaskSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewAdminTasks() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    const docAdminTasks = doc(db, "newTasks", "adminTasks");
    const docAdminTaskSnap = await getDoc(docAdminTasks);
    return isAuth &&
    dispatch(getNewAdminTasks(docAdminTaskSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewBillingItems() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAuth();
    return useCallback(async () => {
      const docBillingItems = doc(db, "newBillingItems", "billingItems");
      const docBillingItemsSnap = await getDoc(docBillingItems);
      return isAuth &&
      dispatch(getNewBillingItems(docBillingItemsSnap.data()))
    }, [dispatch, isAuth])
};

export function useGetNewAbonentCase() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Абонентские
    const docAbonentCases = doc(db, "newCases", "abonentCases");
    const docAbonentCaseSnap = await getDoc(docAbonentCases);   
    return isAuth &&
    dispatch(getNewAbonentCases(docAbonentCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewKadastrCase() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Кадастровыe
    const docKadastrCases = doc(db, "newCases", "kadastrCases");
    const docKadastrCaseSnap = await getDoc(docKadastrCases);  
    return isAuth &&
    dispatch(getNewKadastrCases(docKadastrCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewOtcenkaCase() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Оценка
    const docOtcenkaCases = doc(db, "newCases", "otcenkaCases");
    const docOtcenkaCaseSnap = await getDoc(docOtcenkaCases);  
    return isAuth &&
    dispatch(getNewOtcenkaCases(docOtcenkaCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewArbitrCase() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Арбитраж
    const docArbitrCases = doc(db, "newCases", "sudArbitrCases");
    const docArbitrCaseSnap = await getDoc(docArbitrCases);  
    return isAuth &&
    dispatch(getNewArbitrCases(docArbitrCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewBancrotCase() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Банкротство
    const docBancrotCases = doc(db, "newCases", "sudBancrotCases");
    const docBancrotCaseSnap = await getDoc(docBancrotCases);  
    return isAuth &&
    dispatch(getNewBancrotCases(docBancrotCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewSimpleCase() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Общая Юрисдикция
    const docSimpleCases = doc(db, "newCases", "sudSimpleCases");
    const docSimpleCaseSnap = await getDoc(docSimpleCases);  
    return isAuth &&
    dispatch(getNewSimpleCases(docSimpleCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetAllCases() {
  const getAbonent = useGetNewAbonentCase()
  const getKadastr = useGetNewKadastrCase()
  const getOtcenka = useGetNewOtcenkaCase()
  const getArbitr = useGetNewArbitrCase()
  const getBancrot = useGetNewBancrotCase()
  const getSimple = useGetNewSimpleCase()

  return useCallback(() => {
    getAbonent()
    getKadastr()
    getOtcenka()
    getArbitr()
    getBancrot()
    getSimple()
  }, [getAbonent, getArbitr, getBancrot, getKadastr, getOtcenka, getSimple])
}

export function useGetNewDocuments() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Договоры
    const docSimpleCases = doc(db, "newDocs", "newDocs");
    const docSimpleCaseSnap = await getDoc(docSimpleCases);  
    return isAuth &&
    dispatch(getNewDocuments(docSimpleCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewBills() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Счета
    const docSimpleCases = doc(db, "newBillingItems", "bills");
    const docSimpleCaseSnap = await getDoc(docSimpleCases);  
    return isAuth &&
    dispatch(getNewBills(docSimpleCaseSnap.data()))
  }, [dispatch, isAuth])
};

export function useGetNewProofs() {
  const dispatch = useAppDispatch();
  const { isAuth } = useAuth();
  return useCallback(async () => {
    // Предмет доказывания
    const docProofs = doc(db, "newProofs", "newProofs");
    const docProofsSnap = await getDoc(docProofs)
    return isAuth &&
    dispatch(getNewProofs(docProofsSnap.data()))
  }, [dispatch, isAuth])
}
