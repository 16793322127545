import {FC, useState} from 'react';
import { TodoDepartmentItemProps } from './interface';
import classes from './todoclientitem.module.scss';
import {AiFillCheckCircle} from 'react-icons/ai';
import cx from 'classnames';
import { RiCloseFill } from 'react-icons/ri';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../store/reduxHooks';
import { MdInfo } from 'react-icons/md';


export const TodoDepartmentItem: FC<TodoDepartmentItemProps> = (props) => {
    const {data} = props;
    const {title, isCompleted, formattedDate, creator, completor, completeTime} = data;
    function takeStatus() {
        if (isCompleted === false) {
            return 'В работе'
        }
        else {
            return 'Завершено'
        }
    }
    const [openInfoMenu, setOpenInfoMenu] = useState(true)
    const handleOpenInfoClick = () => setOpenInfoMenu(value => !value)
    const status = takeStatus()
    const startDate = dayjs(formattedDate).format('HH:mm DD.MM.YYYY')
    const finishDate = dayjs(completeTime).format('HH:mm DD.MM.YYYY')
    const users = useAppSelector(state => state.users.users)
    const myUser = users.find((user) => user.id === creator)
    const completedUser = users.find((user) => user.id === completor)

    return (
        <div>
                        {/* Открыть меню */}
                        <div className={cx(classes.popUp, openInfoMenu === true && classes.popUpClose)} onClick={handleOpenInfoClick}>
                <div className={classes.infoMenuOpen} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenInfoClick}>
                        <RiCloseFill size={22} />
                    </button>
                    <div className={classes.titleMenu}>
                    Информационное меню
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlock}>
                            <div className={classes.infoTitle}>
                            {title}
                            </div>
                        
                        </div>
                        <div className={cx(classes.information, isCompleted === true && classes.informationCompleted)}>
                        {status}
                        </div>
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlock}>
                            <div>
                                Создано: {myUser?.label}
                            </div>
                            <div>
                                Время: {startDate}
                            </div>
                        </div>
                        <div className={classes.informationBlock}>
                            <div className={cx(classes.show, isCompleted === false && classes.hide)}>
                                <div>
                                    Завершено: {completedUser?.label}
                                </div>
                                <div>
                                    Время: {finishDate}
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
             </div>
            <div className={classes.todoItem}>
            <div className={classes.item}>
                <div className={cx(classes.icon, isCompleted && classes.iconCompleted)}>
                    <AiFillCheckCircle size={25} />
                </div>
                    <div className={classes.title}>
                        {title}
                    </div>
              


            </div>
            <div className={classes.lawyerTitle}>
                        {completedUser?.label}
                    </div>  
            <div className={classes.chatIcon}>
                        <MdInfo size={25} onClick={handleOpenInfoClick}/>
                    </div>
        </div>
        </div>
        
  )
}