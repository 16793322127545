import {FC, useState} from 'react'
import { EventPopUpProps } from './interface'
import classes from './eventpopup.module.scss'
import { useAppDispatch, useAppSelector } from '../../../store/reduxHooks'
import dayjs from 'dayjs'
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'
import cx from 'classnames'
import { TodoItem } from '../../Clientui/TodoItem/MainTodoItem';
import { useNavigate } from 'react-router'
import { returnInterface } from "../../../store/newSlices/calendarEventShowInterface";
import { MyInput } from '../../../components/MyInput'
import { useUpdateIsViitedEventsTrue, useUpdateIsViitedEventsFalse } from '../../../hooks/useNewEvents'

export const EventPopUp: FC <EventPopUpProps> = (props) => {
    const {eventPopUpForm} = props
    const checkMyEventId = () => {
        if(eventPopUpForm !== null) {
            return eventPopUpForm
        } else {return ''}
    }
    const myId = checkMyEventId()

    const [copied, setCopied] = useState(false)
    const copyTextToClipboard = () => {
        navigator.clipboard.writeText(`${myId}`)
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    }

    const eventId = useAppSelector(state => state.newCalendarChange.myId)

    const events = useAppSelector(state => state.newEvents.events)
    const myEvent = events.find((event) => event.id === eventId)

    const lawyers = useAppSelector(state => state.users.users)
    const myLawyer = lawyers.find((lawyer) => lawyer.id === myEvent?.lawyer)

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases) 
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]


    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const disputesIDs = disputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const checkDisputId = () => {
        if(myEvent) {
            let myDisputId = myEvent.disputId
            return myDisputId
        } else {
            let myDisputId = ''
            return myDisputId
        }
    }
    const myDisputId = checkDisputId()

    const myDisput = disputes.find((disput) => disput.id === myDisputId)
    const myCase = cases.find((cas) => cas.id === myDisputId)

    const checkCaseName = () => {
        if(disputesIDs.includes(myDisputId)) {
            let caseName = myDisput?.name
            return caseName
        } else {
            let caseName = myCase?.name
            return caseName
        }
    }
    const caseName = checkCaseName()
    const checkCaseId = () => {
        if(disputesIDs.includes(myDisputId)) {
            let caseName = myDisput?.id
            return caseName
        } else {
            let caseName = myCase?.id
            return caseName
        }
    }
    const caseId = checkCaseId()
    const checkFinalId = () => {
        if(caseId) {
            return caseId
        } else {return ''}
    }
    const finalId = checkFinalId()
    

    const myTyme = dayjs(myEvent?.start).format('DD MMMM')
    const myHours = dayjs(myEvent?.start).format('HH.mm')
    const finishTime = dayjs(myEvent?.end).format('HH.mm')

    const disputsTasks = useAppSelector(state => state.newDiputTasks.tasks)
    const casesTasks = useAppSelector(state => state.newCaseTasks.tasks)
    const allTasks = [...disputsTasks, ...casesTasks]
    const myTasks = allTasks.filter((task) => task.fatherId === myDisputId)

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleClick = () => {
        if(disputesIDs.includes(myDisputId))
            {
                navigate(`/disputes/${myDisputId}`)
            } else {navigate(`/cases/${myDisputId}`)}
            dispatch(returnInterface())
    }

    const [isVisited, setIsVisited] = useState(false)

    const handleTrueIsVisitedClick = () => setIsVisited(true)
    const handleFalseIsVisitedClick = () => setIsVisited(false)
    const [conclusion, setConclusion] = useState<string>('');

    const checkId = () => {
        if(eventId !== null) {
            let myEventId = eventId
            return myEventId
        } else {
            let myEventId = ''
            return myEventId
        }
    }
    const myEventId = checkId()

    const updateEventTrue = useUpdateIsViitedEventsTrue(myEventId, isVisited, conclusion)
    const updateEventFalse = useUpdateIsViitedEventsFalse(myEventId, isVisited, conclusion)


    const handleCompleteIsVisitedClick = () => {
        if(isVisited === true) {
            updateEventTrue()
            setIsVisited(false)
            setConclusion('')
        } else{updateEventFalse()}
    }

    const x = myEvent?.start

    const EventFinihTime = dayjs(x).valueOf()
    const now = dayjs().valueOf()


  return (
    <div className={classes.root}>
        {/* <button className={classes.button} onClick={copyTextToClipboard}>
            {copied ? 'Готово!' : 'Копировать'}
        </button> */}
        <div className={classes.title}>
            {myEvent?.title}
        </div>
        <div className={classes.title}>
            Дата
        </div>
        <div className={classes.data}>
            <div className={classes.timeRoot}>
                <div className={classes.timeTitle}>
                {myTyme}
                </div>
                <AiOutlineCalendar size={22} />            
            </div>
            <div className={classes.header}>
                начало
            </div>            
            <div className={classes.timeRoot}>
                <div className={classes.timeTitle}>
                {myHours}
                </div>
                <AiOutlineClockCircle size={22} />            
            </div>
            <div className={classes.header}>
                конец
            </div>
            <div className={classes.timeRoot}>
                <div className={classes.timeTitle}>
                {finishTime}
                </div>
                <AiOutlineClockCircle size={22} />            
            </div>
        </div>        
        <div className={classes.eventInfo}>
            <div className={classes.lawyerRoot}>
                <div>
                    Ответственный:
                </div>
                <div className={cx(classes.specsSud, 
                    myLawyer?.specs === 'IT-отдел' && classes.specsIt,
                    myLawyer?.specs === 'CEO' && classes.specsCeo,
                    myLawyer?.specs === 'Ассистент CEO' && classes.specsCeo,
                    myLawyer?.specs === 'Отдел консалтинга' && classes.specsAbonent,
                    myLawyer?.specs === 'Отдел оценки' && classes.specsOcenka,
                    myLawyer?.specs === 'Кадастровый отдел' && classes.specsKadastr)}
                >
                    {myLawyer?.label}
                </div>
            </div>
            <div className={classes.lawyerRoot}>
                <div className={classes.caseRoot}>
                    Дело:
                </div>
                <div className={classes.caseRoot} onClick={handleClick}>
                    {caseName}
                </div>
            </div>
        </div>
        {myEvent?.conclusion !== '' ? <div className={classes.isVisitedRoot}>
            {myEvent?.isVisited ? 
                <span>{myEvent?.conclusion}</span> : 
                <span>Мы не ходили в заседание</span>
            } 
        </div> : 
        <div className={classes.isVisitedRoot}>
            {EventFinihTime < now ? (
                <div className={classes.willViitedRoot}>
                Мы ходили в заседание?
                <div className={classes.buttonBox}>
                    <button className={cx(classes.outlinedButton, isVisited === true && classes.outlinedButtonActive)} onClick={handleTrueIsVisitedClick}>
                        Да
                    </button>
                    <button className={cx(classes.outlinedButton, isVisited === false && classes.outlinedButtonActive)} onClick={handleFalseIsVisitedClick}>
                        Нет
                    </button>
                </div>
                {isVisited === true ? 
                <div className={classes.middleResult}>
                    <MyInput 
                    inputName={''} 
                    value={conclusion} 
                    handleInput={setConclusion} 
                    placeholder={'Введите заключение по заседанию'} 
                    type={'text'} />
                </div> : null}
                    <button className={classes.bigOutlinedButton} onClick={handleCompleteIsVisitedClick}>
                        Подтвердить
                    </button>
            </div>
            ) : null}

        </div>}
        <div className={classes.tasksRoot}>
            {myTasks.map((task) => (
                <TodoItem clientId={finalId} data={task} key={task.id} />  
            ))}
        </div>
    </div>
  )
}
