import { FC, useState } from 'react'
import { AddTodoComponentProps } from './interface'
import classes from './addtodocomponent.module.scss'
import { BsPlusCircleFill } from 'react-icons/bs'
import { RiCloseFill } from 'react-icons/ri'
import cx from 'classnames'
import { useFindMyUser } from '../../hooks/useUsers'
import dayjs from 'dayjs';
import { useHandleAddNewTasks } from '../../hooks/useNewTasks'
import DatePicker from 'react-datepicker'

export const AddTodoComponent: FC<AddTodoComponentProps> = (props) => {
    const {id} = props;
    const [todo, setTodo] = useState<string>([] as any);

    const timeNow = dayjs().startOf('day').valueOf() + 43200000
    const [startDate, setStartDate] = useState<any>(timeNow);
    
    const myUser = useFindMyUser()
    // const creator = `${myUser?.name} ${myUser?.surName}`
    const formattedDate = dayjs().valueOf()


    const [open, setOpen] = useState(true);
    const handleOpenAddTodoMenuClick = () => setOpen(value => !value);

    const handleKeyPress = (e: any) => {
        if(e.key === 'Enter'){
          handleClick()
        }
    }

    const mustFinish = startDate.valueOf()


    const addTask = useHandleAddNewTasks(id, todo, formattedDate, myUser, mustFinish)
    const handleClick = () => {
        if (todo.length) {
            if(timeNow !== startDate) {
                addTask()
                setTodo('')
                handleOpenAddTodoMenuClick()
                setStartDate(timeNow)
                }
                else{alert('Выберите дату, когда необходимо завершить задачу')}
        }
        else {alert('Заполните поле')}
    }



  return (
    <div className={classes.root} onClick={handleOpenAddTodoMenuClick}>
        <BsPlusCircleFill size={25} />
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleOpenAddTodoMenuClick}>
                <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenAddTodoMenuClick}>
                        <RiCloseFill size={22} />
                    </button>
                    <div className={classes.inputBox}>
                        <input
                        value={todo}
                        className={classes.input}
                        placeholder='Добавить задачу'
                        onChange={(e) => setTodo(e.target.value)}
                        onKeyDown={handleKeyPress}
                        />
                        <DatePicker 
                        className={classes.datePicker}
                        locale='ru'
                        dateFormat='dd.MM.yyyy'
                        selected={startDate} 
                        timeIntervals={10}
                        onChange={(date) => setStartDate(date)} 
                        />
                        
                    </div>
                    <button className={classes.button} onClick={handleClick}>Отправить</button>
                </div>
        </div>
    </div>
  )
}
