import { useCallback } from "react";
import { v4 as uuid } from 'uuid';
import { useGetNewBillingItems } from "./useGetDocs";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { BillingItem } from "../api/interface";

export function useHandleAddBillingItemFromTasks(
    biilingItemName: string,
    biilingItemHours: number,
    fatherId: string,
    completeUser: string,
    docId: string | undefined,
    docPrice: number | undefined
) {
    const getMyDocs = useGetNewBillingItems()
    const UniqeId = uuid()
    return useCallback(() => {
        const newBillingItem = {
            id: UniqeId,
            name: biilingItemName,
            hours: biilingItemHours,
            fatherId: fatherId,
            userId: completeUser,
            docId: docId,
            hourCost: docPrice
        }
        updateDoc(doc(db, "newBillingItems", "billingItems"), {
            billingItems: arrayUnion(newBillingItem)
        }).then(getMyDocs)
    }, [UniqeId, biilingItemName, biilingItemHours, fatherId, completeUser, docId, docPrice, getMyDocs])
}

export function useHandleDeleteBillingItemFromTasks(
    data: BillingItem
) {
    const getMyDocs = useGetNewBillingItems()
    return useCallback(() => {
        updateDoc(doc(db, "newBillingItems", "billingItems"), {
            billingItems: arrayRemove(data)
        }).then(getMyDocs)
    }, [data, getMyDocs])
}

export function useHandleChangeBillingItem(
    data: BillingItem,
    updateHours: number
) {
    const getMyDocs = useGetNewBillingItems()
    const oldItem = data
    return useCallback(() => {
        const newItem = {
            id: data.id,
            name: data.name,
            hours: updateHours,
            fatherId:  data.fatherId,
            userId:  data.userId,
            docId:  data.docId,
            hourCost:  data.hourCost        
        }
        updateDoc(doc(db, "newBillingItems", "billingItems"), {
            billingItems: arrayRemove(oldItem)
        })
        updateDoc(doc(db, "newBillingItems", "billingItems"), {
            billingItems: arrayUnion(newItem)
        }).then(getMyDocs)
    }, [data, getMyDocs, oldItem, updateHours])
    
}