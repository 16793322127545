import {FC, useEffect, useState} from 'react'
import { DateProps } from './interface'
import classes from'./date.module.scss'
import dayjs from 'dayjs';

export const Date:FC <DateProps> = (props) => {
    // const time = dayjs().format('dddd DD.MM.YYYY HH:MM:ss')

  const [time, setTime] = useState(dayjs().format('dddd DD.MM.YYYY HH:mm'))
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dayjs().format('dddd DD.MM.YYYY HH:mm'))
          }, 1000);
    return () => clearInterval(interval);
  }, [])
  return (
    <div className={classes.date}>{time}</div> 
  )
}
