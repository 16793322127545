import {FC} from 'react'
import { RiCloseFill } from 'react-icons/ri'
import { useFindMyUser } from '../../hooks/useUsers'
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks'
import { CalendarForAdmin } from '../../ui/AdminUi/CalendarForAdmin'
import { EventPopUp } from '../../ui/CalendarUi/EventPopUp'
import { CaseClientRoot } from '../../ui/CaseUi/CasesClientRoot'
import { NewDashboardProps } from './interface'
import classes from './newdashboard.module.scss'
import cx from 'classnames'
import { returnInterface } from '../../store/newSlices/calendarEventShowInterface'
import { TodoDashboardItem } from '../../ui/MyDashboardUi/TodoDashboardItem'

export const NewDashboard:FC <NewDashboardProps> = () => {

  const clients = useAppSelector(state => state.newClients.clients);
  const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
  const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
  const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
  const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
  const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
  const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
  const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]
  const disputes = useAppSelector(state => state.newDisputes.disputes);
  const myUser = useFindMyUser()
  const otherCases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases,...sudSimpleCases]
  const myCasesNew = otherCases.filter((cas) => cas.lawyers.includes(myUser))
  const myDisputes = disputes.filter((disput) => disput.lawyers.includes(myUser))
  const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])
  const myCasesNewIds = myCasesNew.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])

  const myCases = cases.filter((cas) => myDisputesIds.includes(cas.id))
  const myClientsIds = myCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])
  const newMyClientsIds = [...myClientsIds, ...myCasesNewIds]
  const myClients = clients.filter((client) => newMyClientsIds.includes(client.id))

  const eventList = useAppSelector(state => state.newEvents.events)
  const myEventsList = eventList.filter((event) => event.lawyer === myUser)

  const eventPopUpForm = useAppSelector(state => state.newCalendarChange.myId)
  const dispatch = useAppDispatch()

  const handleCheck = () => {
    dispatch(returnInterface())
  }
  const myCasesIds = myCasesNew.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
  const IDS =  myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

  const disputesTasks = useAppSelector(state => state.newDiputTasks.tasks)
  const casesTasks = useAppSelector(state => state.newCaseTasks.tasks)
  const clientsTasks = useAppSelector(state => state.newClientTasks.tasks)

  const allTasks = [...disputesTasks, ...casesTasks, ...clientsTasks]
  const allIds = [...IDS, ...myDisputesIds, ...myCasesNewIds, ...newMyClientsIds, ...myCasesIds]

  const myArbitrCases = sudArbitrCases.filter((cas) => cas.lawyers.includes(myUser))
  const myBancrotCases = sudBancrotCases.filter((cas) => myDisputesIds.includes(cas.id))
  const arbitrCases = [...myArbitrCases, ...myBancrotCases]

  const filteredArbitrCases = arbitrCases.filter((cas) => cas.number.length > 5)

  const unCompletedTasks = allTasks.filter((task) => task.isCompleted === false && task.creator === myUser)

  const handleOpenKadCardClick = (number: string) => {
    if (number.includes('№')) {
        alert('Дайте мне немного времени, и я сделаю интеграцию с судами общей юрисдикции:)')
    }
    else {
        window.open(`https://kad.arbitr.ru/Card?number=${number}`)
    }
}
  return (
    <div className={classes.root}>
      {/* PopUp info */}
      <div className={cx(classes.popUp, eventPopUpForm === null && classes.popUpClose)}>
        <div className={classes.moreEventInfoRoot} onClick={e => e.stopPropagation()}>
          <div className={classes.buttonRootForm}>
            <button className={classes.icon} onClick={handleCheck}>
              <RiCloseFill size={22} />
            </button>    
          </div>
          <div>
            <EventPopUp eventPopUpForm={eventPopUpForm}  />
          </div>
        </div>
      </div>
      <div className={classes.clientsRoot}>
              {myClients.map((client) => (
                  <CaseClientRoot clientId={client.id} data={client} key={client.id} />
              ))}
      </div>
      <div className={classes.blockRoot}>
          <div className={classes.calendar}>
            <CalendarForAdmin myEventsList={myEventsList} />
          </div>
          <div className={classes.mainRoot}>
            <div className={classes.semiRoot}>
              <div className={classes.titleSemi}>
                Задачи
              </div>
              {unCompletedTasks.map((task) => (<TodoDashboardItem data={task} key={task.id} />))}
            </div>
            <div className={classes.semiRoot}>
              <div className={classes.titleSemi}>
                Арбитражные дела
              </div>
                {filteredArbitrCases.map((cas) => 
                (<div key={cas.id}>
                  <span className={classes.linkToKad} onClick={() => handleOpenKadCardClick(cas.number)}>
                    {cas.name}
                  </span>
                </div>))}
            </div>
          </div>
      </div>
    </div>
  )
}
