import { FC } from 'react';
import { DeletedCasesProps } from './interface';
import classes from './archivedcases.module.scss'
import { useAppSelector } from '../../store/reduxHooks';
import { Case } from '../../api/interface';
import { useParams, useNavigate} from 'react-router';
import { DeletedCaseScreen } from '../../ui/DeletedCaseUi/DeletedCaseScreen';
import { BiArchiveIn } from 'react-icons/bi';
import { BsFolder2Open } from 'react-icons/bs';
import { DeletedCaseClientRoot } from '../../components/DeletedCasesClientRoot';

export const DeletedCases: FC<DeletedCasesProps> = (props) => {
    const clients = useAppSelector(state => state.newClients.clients);
    const cases = useAppSelector(state => state.newCases.cases);
    const deletedCases = cases.filter((cas) => cas.isDeleted === true)
    const { id } = useParams();
    const oneCase = deletedCases.find((cas) => cas.id === id)

    const navigate = useNavigate()
    const handleGotoActiveClick = () => {
      navigate('/cases');
    }
    const handleGotoArchiveClick = () => {
      navigate('/archivedcases');
    }

  return (
    <div className={classes.root}>
      <div className={classes.leftList}>
        <div className={classes.list}>
        {clients.map((cas) => (
                      <DeletedCaseClientRoot clientId ={cas.id} data={cas} key={cas.id} />
                  ))}
        </div>
        <div className={classes.archiveAndDeletedClient}>
              <div className={classes.archDel} onClick={handleGotoActiveClick}>
              <BsFolder2Open size={35} />
              </div>
              <div className={classes.archDel} onClick={handleGotoArchiveClick}>
              <BiArchiveIn size={35} />
              </div>
            </div>
        </div>
        <div className={classes.screen}>
          {oneCase ? <DeletedCaseScreen data={oneCase} {...oneCase} /> : null}
        </div>
    </div>
  )
}
