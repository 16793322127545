import { FC, useState } from 'react'
import { FormProps } from './interface'
import classes from './form.module.scss'

export const Form:FC <FormProps> = (props) => {
    const {title, handleClick} = props
    const [login, setLogin] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const email = `${login}@siblegal-crm.ru`
    const handleKeyPress = (e: any) => {
        if(e.key === 'Enter'){
            handleClick(email, password)
        }
    }
  return (
    <div className={classes.root}>
        
        <div className={classes.formRoot}>
            {/* <div className={classes.title}>
                С наступающим Новым годом!
            </div> */}

            <div>
                <input 
                    type="email" 
                    value={login} 
                    onChange={(e) => setLogin(e.target.value)} 
                    placeholder='login'
                    className={classes.input}
                />
            </div>
            <div>
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='password' 
                    className={classes.input}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <div className={classes.buttonDiv}>
                <button className={classes.button} onClick={() => handleClick(email, password)}>
                    {title}
                </button>            
            </div>

        </div>
    </div>
  )
}
