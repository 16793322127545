import {FC, useState} from 'react'
import { BillTableItemProps } from './interface'
import classes from './billtableitem.module.scss'
import { useAppSelector } from '../../../store/reduxHooks'
import { useHandleAddActForBill, useHandleConfirmBill, useHandlePayedBill, useHandleSendActForBill, useHandleSignActForBill } from '../../../hooks/useNewBills'
import cx from 'classnames'
import { RiCloseFill } from 'react-icons/ri'
import { MyInput } from '../../../components/MyInput'
import dayjs from 'dayjs';

export const BillTableItem: FC<BillTableItemProps> = (props) => {
    const {data} = props
    const {number, clientId, docId, base, summ, act, isPayed, isActed, isConfirmed, date} = data
    const clients = useAppSelector(state => state.newClients.clients)
    const myClient = clients.find((client) => client.id === clientId)
    const documents = useAppSelector(state => state.newDocuments.docs)
    const myDocument = documents.find((doc) => doc.id === docId)
    const dateTime = dayjs(date).format('DD.MM.YY')

    const payedBill = useHandlePayedBill(data)
    const handeIsPayedClick = () => {
        if(isPayed) {
            alert('Счет уже оплачен')
        } else {payedBill()}
    }
    const [show, setShow] = useState(false)

    const handleOpenBillMenu = () => setShow(value => !value)
    const handleIsActedClick = () => {
        if(isActed) {
            alert('Акт уже добавлен')
        } else {handleOpenBillMenu()}
    }
    const [actName, setActName] = useState<string>('')
    const actDate = dayjs().valueOf()

    const addActForBill = useHandleAddActForBill(data, actName, actDate)
    const handleAddActClick = () => {
        if(actName !== '') {
            addActForBill()
            handleOpenBillMenu()
        } else {
            alert('Добавьте название акта')
        }
    }

    const actTime = dayjs(act.date).format('DD.MM.YY')

    const actSendForBill = useHandleSendActForBill(data)
    const handleSendActClick = () => {
        if(act.isSended !== true) {
            actSendForBill()
        } else {alert('Акт уже отправлен')}
    }
    const actSignForBill = useHandleSignActForBill(data)
    const handleSignActClick = () => {
        if(act.isSigned !== true) {
            actSignForBill()
        } else {alert('Акт уже подписан')}
    }
    const confirmBill = useHandleConfirmBill(data)
    const handleConfirmBillClick = () => {
        if(act.isSended !== true && act.isSigned !== true) {
            alert('Отправьте и подпишите Акт')
        } else {
            confirmBill()
        }
    }
  return (
    <div className={classes.root}>
        {/* Добавить акт */}
        <div className={cx(classes.popUp, show === false && classes.popUpClose)}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenBillMenu}>
                    <RiCloseFill size={22} />
                </button>     
                <div className={classes.actTitle}>
                    Акт к счету {number}
                </div>  
                <MyInput inputName={"Название акта"} value={actName} handleInput={setActName} placeholder={'Введите название акта'} type={"text"} />
                <button className={classes.addButton} onClick={handleAddActClick}>
                  Добавить
                </button>
            </div>      
        </div>
        <div className={classes.billRoot}>
            <div className={classes.infoBlock}>
                <div>
                    {number}
                </div>
                <div className={classes.dateBlock}>
                    {dateTime}
                </div>
            </div>
            <div className={classes.infoBlock}>
                {myClient?.name}
            </div>
            <div className={classes.infoBlock}>
                <div>
                    {myDocument?.name}
                </div>
                <div className={classes.docNameBlock}>
                    {myDocument?.number}
                </div>
            </div>
            <div className={classes.infoBlock}>
                {base}
            </div>
            <div className={classes.infoSummBlock}>
                {summ}
            </div>

            <div className={classes.isPayedBlock}>
                <button 
                    className={cx(classes.confirmButton, isPayed === true && classes.confirmButtonIsPayed)} 
                    onClick={handeIsPayedClick}
                >
                  {isPayed ? 'Оплачен' : 'Не оплачен'}
                </button>
            </div>
            <div className={classes.isActedBlock}>
                <button 
                    className={cx(classes.confirmButton, isActed === true && classes.confirmButtonIsPayed)} 
                    onClick={handleIsActedClick}
                >
                  {isActed ? 'Готово' : 'Добавить'}
                </button>
            </div>
        </div>
        <div className={cx(classes.actRootHide, isActed === true && classes.actRoot)}>
            <div className={classes.actTitle}>
                <div className={classes.actInfoBlock}>
                    Акт от {actTime}
                </div>
                <div className={classes.actBlock}>
                    {act.name}
                </div>
                <div className={classes.actButton}>
                <button 
                    className={cx(classes.confirmButton, act.isSended === true && classes.confirmButtonIsPayed)} 
                    onClick={handleSendActClick}
                >
                  {act.isSended ? 'Отправлен' : 'Отправить'}
                </button>
                </div>
                <div className={classes.actButton}>
                <button 
                    className={cx(classes.confirmButton, act.isSigned === true && classes.confirmButtonIsPayed)} 
                    onClick={handleSignActClick}
                >
                  {act.isSigned ? 'Подписан' : 'Подписать'}
                </button>                
                </div>
                <div className={classes.actButton}>
                <button 
                    className={cx(classes.confirmButton, isConfirmed === true && classes.confirmButtonIsPayed)} 
                    onClick={handleConfirmBillClick}
                >
                  {isConfirmed ? 'Закрыт' : 'Закрыть'}
                </button>
                </div>
            </div>
        </div>
    </div>
  )
}
