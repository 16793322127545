import {FC, useState, useEffect} from 'react';
import { NavBarProps } from './interface';
import classes from './navbar.module.scss';
import {BiBell, BiUser} from 'react-icons/bi';
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { removeUser } from '../../store/slices/userAuthSlice';
import { useNavigate } from 'react-router';
import cx from 'classnames'
import  {MissionRoot} from '../Mission';
import { useFindMyUser } from '../../hooks/useUsers';
import { Date } from '../../ui/NavBarUi/Date';
import { SearchRoot } from '../../ui/NavBarUi/SearchRoot';
import { useAuth } from '../../hooks/useAuth';

export const NavBar: FC<NavBarProps> = (props) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.authuser)
    const myId = user.id
    const allUsers = useAppSelector(state => state.users.users)
    const myUser = allUsers.find((user) => user.id === myId)
    
    const text = 'Выйти';

    const handleLogOutClick = () => {
      dispatch(removeUser())
    }
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(`/users/${myId}`);
  }

  const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
  const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
  const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
  const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
  const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
  const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
  const disputes = useAppSelector(state => state.newDisputes.disputes)
  const activeDiputes = disputes.filter((disput) => disput.isActive === true)


  const adminTasks = useAppSelector(state => state.newAdminTasks.tasks)
  const myAdminTasks = adminTasks.filter((i) => i.lawyer === myId && i.status === 'not')

  const myUserSearch = useFindMyUser()

  const myDisputes = disputes.filter((disput) => disput.lawyers.includes(myUserSearch))
  const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])
  const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudSimpleCases]

  const myCases = sudBancrotCases.filter((cas) => myDisputesIds.includes(cas.id))
  const myNewCases = cases.filter((cas) => cas.lawyers.includes(myUserSearch))
  const allMyCases = [...myCases, ...myNewCases]
  const myClientsIds = allMyCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])

  const myAdminClientsTasks = adminTasks.filter((i) => myClientsIds.includes(i.fatherId) && i.status === 'not')
  const allAdminTasks = [...myAdminTasks, ...myAdminClientsTasks]

  const [showTasks, setShowTasks] = useState(false)
  const handleOpenAdminTasksClick = () => setShowTasks(value => ! value)

  const checkTasksLength = () => {
    if(allAdminTasks.length < 10) {
      return allAdminTasks.length
    }
    if(allAdminTasks.length > 10) {
      return '9+'
    }
  }
  const tasksLength = checkTasksLength()

  return (
    <div className={classes.header}>
      {/* <SearchRoot /> */}
        <div className={classes.sidebar}>
          <Date />
          <div onClick={handleClick} className={classes.userRoot}>
              <BiUser 
              size={32} 
              className={classes.buttonU} />
              <div className={classes.username}>
              {myUser?.name} {myUser?.surName}
              </div>
          </div>
          <div className={classes.taskBellRoot} >
            <div className={classes.bellRoot} onClick={handleOpenAdminTasksClick}>
              {allAdminTasks.length ? (
                <div className={classes.notification}>
                  {tasksLength}
                </div>            
              ) : null}

              <BiBell className={classes.buttonBell} size={32} />

            </div>
            <div className={cx(classes.hideRoot, showTasks && classes.rootTasks)}>
              <div className={classes.searchRoot}>
                {allAdminTasks.length ? (
                  <>
                    {allAdminTasks.map(item => 
                      <MissionRoot data={item} key={item.id} />
                    )}
                  </>) : (
                  <div className={classes.emptyRoot}>
                      Поручений нет :)
                  </div>
                )}

              </div>
            </div>
          </div>
          <button className={classes.button} onClick={handleLogOutClick}>{text}</button>
        </div>
    </div>
  )
}
