import { FC, useEffect } from 'react'
import { ErrorPageProps } from './interface'
import { useNavigate} from 'react-router';
import { useAuth } from '../../hooks/useAuth';

export const ErrorPage:FC <ErrorPageProps> = () => {
  const {isAuth} = useAuth()

  useEffect(() => {
    if(!isAuth) {
      window.location.assign('https://legal-crm.ru/')
    }
  })
  return (
    <div>
        
    </div>
  )
}
