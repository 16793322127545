import { createSlice } from '@reduxjs/toolkit';
import { User } from "../../api/interface";

interface UsersState {
    users: User[]
}

const initialState: UsersState = {
    users: [
        {
            id: 'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
            name: 'Иван',
            surName: 'Абрамов',
            fatherName: 'Александрович',
            value: 'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
            label: 'Иван Абрамов',
            you: true,
            specs: 'IT-отдел',
        },
    
        {
            id: 'XkIjsIdw69ghLudgDDztks3g23S2',
            name: 'Георгий',
            surName: 'Мороз',
            fatherName: 'Святославович',
            value: 'XkIjsIdw69ghLudgDDztks3g23S2',
            label: 'Георгий Мороз',
            you: false,
            specs: 'Судебный отдел',
        },
    
        {
            id: '1zNyYjM7AKgkkIYvBI3d2tB4kJ43',
            name: 'Святослав',
            surName: 'Мороз',
            fatherName: 'Георгиевич',
            value: '1zNyYjM7AKgkkIYvBI3d2tB4kJ43',
            label: 'Святослав Мороз',
            you: false,
            specs: 'CEO',
        },
        
    
        {
            id: '7nfPePKd9Zhde2DiUjhHFiEYNT03',
            name: 'Елена',
            surName: 'Калиниченко',
            fatherName: 'Геннадьевна',
            value: '7nfPePKd9Zhde2DiUjhHFiEYNT03',
            label: 'Елена Калиниченко',
            you: false,
            specs: 'Отдел консалтинга',
        },
    
        {
            id: 'NklLF4Pu7qe0iFQ1CHycYaGrzbF2',
            name: 'Елена',
            surName: 'Батухтина',
            fatherName: 'Олеговна', 
            value: 'NklLF4Pu7qe0iFQ1CHycYaGrzbF2',
            label: 'Елена Батухтина',
            you: false,
            specs: 'Отдел консалтинга',
        },
    
        {
            id: 'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
            name: 'Дарья',
            surName: 'Печерская',
            fatherName: 'Алексеевна',
            value: 'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
            label: 'Дарья Печерская',
            you: false,
            specs: 'Ассистент CEO',
        },

        {
            id: '9',
            name: 'Виталий',
            surName: 'Вольский',
            fatherName: 'Владимирович',
            value: '9',
            label: 'Виталий Вольский',
            you: false,
            specs: 'Отдел оценки',
        },

        {
            id: '90',
            name: 'Дмитрий',
            surName: 'Ламерт',
            fatherName: 'Андреевич',
            value: '90',
            label: 'Дмитрий Ламерт',
            you: false,
            specs: 'Отдел оценки',
        },

        {
            id: '10',
            name: 'Сергей',
            surName: 'Терехов',
            fatherName: 'Викторович',
            value: '10',
            label: 'Сергей Терехов',
            you: false,
            specs: 'Кадастровый отдел',
        },

        {
            id: '11',
            name: 'Алексей',
            surName: 'Кузнецов',
            fatherName: 'Леонидович',
            value: '11',
            label: 'Алексей Кузнецов',
            you: false,
            specs: 'Кадастровый отдел',
        },

        {
            id: 'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
            name: 'Анна',
            surName: 'Тихонова',
            fatherName: 'Александровна',
            value: 'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
            label: 'Анна Тихонова',
            you: false,
            specs: 'Отдел консалтинга',
        },

        {
            id: 'KQydQPbF73YFmJeUB6L5thnsUnq2',
            name: 'Тимофей',
            surName: 'Ветошкин',
            fatherName: 'Александрович',
            value: 'KQydQPbF73YFmJeUB6L5thnsUnq2',
            label: 'Тимофей Ветошкин',
            you: false,
            specs: 'Судебный отдел',
        },
        {
            id: 'kwsPA22rmJQ3y6398tuqe6N0bX62',
            name: 'Ярослава',
            surName: 'Кудашова',
            fatherName: 'Романовна',
            value: 'kwsPA22rmJQ3y6398tuqe6N0bX62',
            label: 'Ярослава Кудашова',
            you: false,
            specs: 'Судебный отдел',
        },
        {
            id: 'Jp2dUsoQqKQ7gpRccwj9693G4Rt2',
            name: 'Александр',
            surName: 'Барабаш',
            fatherName: 'Михайлович',
            value: 'Jp2dUsoQqKQ7gpRccwj9693G4Rt2',
            label: 'Александр Барабаш',
            you: false,
            specs: 'Судебный отдел',
        },

        {
            id: 'PvrclNQbE5a47lpzMZBpXIeXSgD3',
            name: 'Станислав',
            surName: 'Омельчук',
            fatherName: 'Сергеевич',
            value: 'PvrclNQbE5a47lpzMZBpXIeXSgD3',
            label: 'Станислав Омельчук',
            you: false,
            specs: 'Отдел консалтинга',
        },

        {
            id: 'pqGwnLn8NscjvFsiY8J89iAxWp72',
            name: 'Галина',
            surName: 'Бородуля',
            fatherName: '',
            value: 'pqGwnLn8NscjvFsiY8J89iAxWp72',
            label: 'Галина Бородуля',
            you: false,
            specs: 'Бухгалтер',
        },

        {
            id: 'IDKfM9JfNrQViSr7IDz9UHQxPwb2',
            name: 'Наталия',
            surName: 'Саютина',
            fatherName: 'Анатольевна',
            value: 'IDKfM9JfNrQViSr7IDz9UHQxPwb2',
            label: 'Наталия Саютина',
            you: false,
            specs: 'Судебный отдел',
        },

        {
            id: 'efZib0JY1XaiDFJejD4fZBw84Md2',
            name: 'Рената',
            surName: 'Байжуменова',
            fatherName: 'Ринатовна',
            value: 'efZib0JY1XaiDFJejD4fZBw84Md2',
            label: 'Рената Байжуменова',
            you: false,
            specs: 'Отдел консалтинга',
        },

        {
            id: 'j5vVl8gsoHXJC90hFoNHnPRYLl83',
            name: 'Валерия',
            surName: 'Вишняк',
            fatherName: 'Константиновна',
            value: 'j5vVl8gsoHXJC90hFoNHnPRYLl83',
            label: 'Валерия Вишняк',
            you: false,
            specs: 'Судебный отдел',
        },

        {
            id: 'Hq3HraqWpZVSoais4O7iAJpG1mC3',
            name: 'Евгений',
            surName: 'Захаренков',
            fatherName: 'валентинович',
            value: 'Hq3HraqWpZVSoais4O7iAJpG1mC3',
            label: 'Евгений Захаренков',
            you: false,
            specs: 'Отдел консалтинга',
        },

        
    ]
}

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers:{}
})

export default userSlice.reducer