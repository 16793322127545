import {FC} from 'react'
import { TaskMissonProps } from './interface'
import classes from './taskmission.module.scss'
import dayjs from 'dayjs';
import cx from 'classnames'
import { useAppSelector } from '../../../store/reduxHooks';

export const TaskMisson:FC<TaskMissonProps> = (props) => {
    const {data} = props
    const {name, status, timeCreate, fatherId, lawyer, author} = data
    const returnStatusName = () => {
        if(status === 'not') {
            let newStatus = 'Не приступали' 
            return newStatus
        }
        if(status === 'inwork') {
            let newStatus = 'В работе' 
            return newStatus
        }
        else {
            let newStatus = 'Завершено' 
            return newStatus
        }
    }
    const newStatus = returnStatusName()

    const currentDate = dayjs().valueOf()
    const mustFinishDate = +timeCreate + 604800000
    const separateDate = mustFinishDate - currentDate

    const checkTime = () => {
        if(separateDate > 259200000) {
            let color = 'green'
            return color
        } 
        if(separateDate < 259200000 && separateDate > 86400000) {
            let color = 'yellow'
            return color
        }
        if(separateDate < 86400000 && separateDate > 3600000) {
            let color = 'red'
            return color
        }
        if(separateDate < 3600000 ) {
            let color = 'superRed'
            return color
        }
        else {
            let color = 'none'
            return color
        }
    }
    const timeColor = checkTime()

    const users = useAppSelector(state => state.users.users)
    const currentUser = users.find((i) => i.id === lawyer)
    const authorUser = users.find((i) => i.id === author)

    const clients = useAppSelector(state => state.newClients.clients)
    const currentClient = clients.find((i) => i.id === fatherId)

    const startDate = dayjs(timeCreate).format('DD.MM')
    const finishDate = dayjs(mustFinishDate).format('DD.MM')
  return (
    <div className={classes.root}>
        <div className={classes.info}>
            <div className={classes.baseInfo}>
                <div className={classes.title}>
                    {name}
                </div>
                <div className={classes.statusRoot}>
                    {newStatus}
                </div>
                <div>
                    Автор: {authorUser?.label}
                </div>
            </div>
            <div className={cx(classes.hideMoreInfo, status !== 'not' && classes.moreInfo)}>
                <div className={classes.clientTitle}>
                    {currentClient?.name}
                </div>
                <div className={classes.lawyer}>
                    {currentUser?.label}
                </div>
            </div>
        </div>

        <div className={classes.info}>
            <div className={classes.baseInfo}>
                <div>
                    Создано: {startDate}
                </div>
            </div>
            <div className={cx(classes.hideMoreInfo, status !== 'not' && classes.moreInfo)}>
                <div>
                    Срок: {finishDate}
                </div>
            </div>
        </div>
        {newStatus !== 'Завершено' ? (
            <div className={cx(classes.colorRoot, timeColor === 'yellow' && classes.yellow, timeColor === 'red' && classes.red, timeColor === 'superRed' && classes.superRed, timeColor === 'none' && classes.none)}></div>
        ) : null}
        
    </div>
  )
}
