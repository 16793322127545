import {FC, useState} from 'react';
import { UserCardForDisputProps } from './interface';
import classes from './usercard.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../store/reduxHooks';
import cx from 'classnames'
import { RiInformationLine } from 'react-icons/ri';
import { TbTrashFilled } from 'react-icons/tb';
import { useHandleRemoveUserToNewDisputes } from '../../../hooks/useNewDisputes';

export const UserCardForDisput: FC<UserCardForDisputProps> = (props) => {
    const {data, userId, myDisput} = props;
    const { name, surName, fatherName, specs} = data;

    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/users/${userId}`);
    }
    const currentPath = window.location.pathname;  


    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudSimpleCases]

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const activeDisputes = disputes.filter ((disput) => disput.isCompleted === false)
    const filteredDisputes = activeDisputes.filter((disput) => disput.lawyers.includes(userId) && disput.isActive === true)
    const myCases = cases.filter((cas) => cas.lawyers.includes(userId) && cas.isActive === true)
    const disputLength = filteredDisputes.length
    const casesLength = myCases.length
    const allLength = disputLength + casesLength
    const [show, setShow] = useState(false)

    const myLawyers = myDisput.lawyers
    const newLawyers = myLawyers.filter((user) => user !== userId)

    const removeUser = useHandleRemoveUserToNewDisputes(myDisput, newLawyers)

    const handleRemoveUserFromCase = () => {
        if(currentPath.includes('/disputes')) {
            removeUser()
        }
    }


  return (
    <div className={classes.root} 
        onMouseEnter={() => setShow(true)}  
        onMouseLeave={() => setShow(false)}
    >
        <div className={classes.avatar}></div>
        <div className={classes.content}>
            <div className={classes.text}>
                <div className={classes.user}>
                        {surName} {name} {fatherName} 
                </div>
                    <div className={cx(classes.specsSud, 
                        specs === 'IT-отдел' && classes.specsIt,
                        specs === 'CEO' && classes.specsCeo,
                        specs === 'Ассистент CEO' && classes.specsCeo,
                        specs === 'Отдел консалтинга' && classes.specsAbonent,
                        specs === 'Отдел оценки' && classes.specsOcenka,
                        specs === 'Кадастровый отдел' && classes.specsKadastr)}
                    >
                        {specs}
                    </div>
                    <div className={classes.case}>
                        дел в работе: {allLength}
                    </div>
            </div>
            <div className={cx(classes.buttonsHide, show === true && classes.buttons)}>
                <div className={classes.icon}>
                <RiInformationLine size={22} onClick={handleClick} />
                </div>
                <div className={classes.icon}>
                <TbTrashFilled size={22} onClick={handleRemoveUserFromCase} />
                </div>            
            </div>
        </div>
    </div>
  )
}
