import {FC, useState} from 'react';
import { DocumentComponentProps } from './interface';
import classes from './documentcomponent.module.scss'
import { TiDocument } from 'react-icons/ti';
import { MdInfo } from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';
import cx from 'classnames'
import { useAppSelector } from '../../../store/reduxHooks';

export const DocumentComponent:FC <DocumentComponentProps> = (props) => {
    const {data} = props
    const {name, number, type, lawyerPrice, courtPrice, summ, id} = data
    const [open, setOpen] = useState(false)
    const handleOpenMoreInfoClick = () => setOpen(value => !value)
    const currentPath = window.location.pathname;  

    const checkType = () => {
        if(type === 'abonent') {
            let myType = 'Абонентское сопровождение'
            return myType
        } else {
            let myType = 'Биллинговый договор'
            return myType
        }
    }
    const myType = checkType()

    const billingItems = useAppSelector(state => state.newBillingItems.billingItems)
    const myBillingItems = billingItems.filter((item) => item.docId === id)
    const totalPriceItems = myBillingItems.map(item => {
        let option = 0
        option = item.hourCost * item.hours
        return option
    })
    const totalPriceReduce = totalPriceItems.reduce((acc, currentValue) => acc + currentValue, 0)

  return (
    <div className={classes.root}>
        {/* PopUp Information */}
        <div className={cx(classes.popUp, open === false && classes.popUpClose)} onClick={handleOpenMoreInfoClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenMoreInfoClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.mainInfo}>
                    <div className={classes.title}>
                        {name}
                    </div>
                    <div className={classes.smallTitle}>
                        № {number}
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.myBlock}>
                            Стоимость часа юриста: {lawyerPrice} <br />
                            Стоимость суд. заседания: {courtPrice} <br />
                            Сумма выставления счета: {summ} <br />                        
                        </div>
                        <div className={classes.myBlock}>
                            {myType}
                            <div>
                                <input type="range" min={0} max={summ} readOnly={true} value={totalPriceReduce}  className={classes.range} />
                            </div>
                            <div>
                                {totalPriceReduce}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className={classes.icon}>
            <TiDocument size={30} />
        </div>
        <div className={classes.mainContent}>
            <div className={classes.docsName}>
                {name}
            </div>
            <div className={classes.docsNumber}>
                {number}
            </div>
        </div>
        <div className={classes.moreIcons}>
            {!currentPath.includes('/documents') ? (<div className={classes.smallIcon} onClick={handleOpenMoreInfoClick}>
                <MdInfo size={25}  />
            </div>) : null}
        </div>
    </div>
  )
}
