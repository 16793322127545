import {createSlice} from '@reduxjs/toolkit';
import { SudCase } from '../../api/interface';

interface SudArbitrCasesState {
    cases: SudCase[]
}
const initialState: SudArbitrCasesState = {
    cases: []
}

export const newSudArbitrCasesSlice = createSlice({
    name: 'newSudArbitrSlice',
    initialState,
    reducers: {
        getNewArbitrCases(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewArbitrCases
} = newSudArbitrCasesSlice.actions
export default newSudArbitrCasesSlice.reducer