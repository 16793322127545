import {FC} from 'react';
import { MyInputProps } from './interface';
import classes from'./myinput.module.scss';

export const MyInput: FC<MyInputProps> = ({
    value,
    inputName,
    handleInput, 
    type,
    placeholder}) => {
  return (
    <div>
        <div className={classes.title}>
            {inputName}
        </div>
        <input className={classes.input}
            value={value}
            type={type}
            placeholder={placeholder}
            onChange={(e) => handleInput(e.target.value)}
        />
    </div>
  )
}