import {createSlice} from '@reduxjs/toolkit';
import { SudCase } from '../../api/interface';

interface SudSimpleCasesState {
    cases: SudCase[]
}
const initialState: SudSimpleCasesState = {
    cases: []
}

export const newSudSimpleCasesSlice = createSlice({
    name: 'newSudSimpleSlice',
    initialState,
    reducers: {
        getNewSimpleCases(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewSimpleCases
} = newSudSimpleCasesSlice.actions
export default newSudSimpleCasesSlice.reducer