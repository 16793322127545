import { useCallback } from 'react';
import { useAppSelector } from "../store/reduxHooks";
import { useGetNewAdminTasks, useGetNewCaseTasks, useGetNewClientTasks, useGetNewDisputTasks } from "./useGetDocs";
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { v4 as uuid } from 'uuid';
import { Mission, Task } from '../api/interface';
import dayjs from 'dayjs';


export function useHandleDeleteNewTasks (
    id: string,
    isCompleted: boolean,
    fatherId: string,
    title: string,
    formattedDate: number,
    creator: string,
    completor: string,
    completeTime: number,
    mustFinishDate: number
) {
        const clients = useAppSelector(state => state.newClients.clients)
        const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
        const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
        const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
        const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
        const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
        const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
        const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]
        const disputes = useAppSelector(state => state.newDisputes.disputes)
        const getMyDocs = useGetNewClientTasks();
        const getMyCases = useGetNewCaseTasks()
        const getMyDisputes = useGetNewDisputTasks()

        const clientIndex = clients.findIndex(
            (client) => client.id === fatherId
        )
        const caseIndex = cases.findIndex(
            (cas) => cas.id === fatherId
        )
        const disputeIndex = disputes.findIndex(
            (disput) => disput.id === fatherId
        )
        return useCallback(() => {
            const oldTodo = {
                id: id,
                isCompleted: isCompleted,
                title: title,
                formattedDate: formattedDate,
                creator: creator,
                completor: completor,
                completeTime: completeTime,
                fatherId: fatherId,
                mustFinishDate: mustFinishDate
            }
            if(clientIndex > -1) {
                updateDoc(doc(db, "newTasks", "clientsTasks"), {
                    tasks: arrayRemove(oldTodo)
                }).then(getMyDocs)
            }    
            if(caseIndex > -1) {
                updateDoc(doc(db, "newTasks", "caseTasks"), {
                    tasks: arrayRemove(oldTodo)
                }).then(getMyCases)
            } 
            if(disputeIndex > -1) {
                updateDoc(doc(db, "newTasks", "disputTasks"), {
                    tasks: arrayRemove(oldTodo)
                }).then(getMyDisputes)
            }     
    }, [id, isCompleted, title, formattedDate, creator, completor, completeTime, fatherId, mustFinishDate, clientIndex, caseIndex, disputeIndex, getMyDocs, getMyCases, getMyDisputes])
}

export function useHandleToggleNewTasks (
    id: string,
    isCompleted: boolean,
    fatherId: string,
    title: string,
    formattedDate: number,
    creator: string,
    completeUser: string,
    completedTimeForHook: number,
    mustFinishDate: number
) {
    const clients = useAppSelector(state => state.newClients.clients)
    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]
    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const getMyDocs = useGetNewClientTasks();
    const getMyCases = useGetNewCaseTasks()
    const getMyDisputes = useGetNewDisputTasks()

    const clientIndex = clients.findIndex(
        (client) => client.id === fatherId
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === fatherId
    )
    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === fatherId
    )

    return useCallback(() => {
        const oldTodo = {
            id: id,
            isCompleted: isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: '',
            completeTime: null,
            fatherId: fatherId,
            mustFinishDate: mustFinishDate
        }
        const newTodo = {
            id: id,
            isCompleted: !isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: completeUser,
            completeTime: completedTimeForHook,
            fatherId: fatherId,
            mustFinishDate: mustFinishDate
        }
        if(clientIndex > -1) {
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDocs)
        }    
        if(caseIndex > -1) {
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyCases)
        } 
        if(DisputeIndex > -1) {
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDisputes)
        }     
    }, [id, isCompleted, title, formattedDate, creator, fatherId, mustFinishDate, completeUser, completedTimeForHook, clientIndex, caseIndex, DisputeIndex, getMyDocs, getMyCases, getMyDisputes])
}


export function useHandleUnToggleNewTasks (
    id: string,
    isCompleted: boolean,
    fatherId: string,
    title: string,
    formattedDate: number,
    creator: string,
    completor: string,
    completeTime: number,
    mustFinishDate: number
) {
    const clients = useAppSelector(state => state.newClients.clients)
    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const getMyDocs = useGetNewClientTasks();
    const getMyCases = useGetNewCaseTasks()
    const getMyDisputes = useGetNewDisputTasks()

    const clientIndex = clients.findIndex(
        (client) => client.id === fatherId
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === fatherId
    )
    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === fatherId
    )

    return useCallback(() => {
        const oldTodo = {
            id: id,
            isCompleted: isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: completor,
            completeTime: completeTime,
            fatherId: fatherId,
            mustFinishDate: mustFinishDate
        }
        const newTodo = {
            id: id,
            isCompleted: !isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: '',
            completeTime: null,
            fatherId: fatherId,
            mustFinishDate: mustFinishDate
        }
        if(clientIndex > -1) {
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDocs)
        }    
        if(caseIndex > -1) {
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyCases)
        } 
        if(DisputeIndex > -1) {
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDisputes)
        }     
    }, [id, isCompleted, title, formattedDate, creator, completor, completeTime, fatherId, mustFinishDate, clientIndex, caseIndex, DisputeIndex, getMyDocs, getMyCases, getMyDisputes])
}


export function useHandleAddNewTasks (
    id: string,
    todo: string, 
    formattedDate: number,
    myUser: string,
    mustFinish: number
    ) {
    const clients = useAppSelector(state => state.newClients.clients)
    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)    
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const UniqeId = uuid()
    const getMyDocs = useGetNewClientTasks();
    const getMyCases = useGetNewCaseTasks()
    const getMyDisputes = useGetNewDisputTasks()
    
    const clientIndex = clients.findIndex(
        (client) => client.id === id
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === id
    )
    const disputeIndex = disputes.findIndex(
        (disput) => disput.id === id
    )

    return useCallback(() =>{
        const myTask = {
            id: UniqeId,
            title: todo,
            isCompleted: false,
            formattedDate: formattedDate,
            creator: myUser,
            completor: '',
            completeTime: null,
            fatherId: id,
            mustFinishDate: mustFinish 
        }
        if(clientIndex > -1) {
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayUnion(myTask)
            }).then(getMyDocs)
        }
        if(caseIndex > -1) {
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayUnion(myTask)
            }).then(getMyCases)
        }
        if(disputeIndex > -1) {
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayUnion(myTask)
            }).then(getMyDisputes)
        }
        
    }, [UniqeId, todo, formattedDate, myUser, id, mustFinish, clientIndex, caseIndex, disputeIndex, getMyDocs, getMyCases, getMyDisputes])
}


export function useHandleChangeNewTasks (
    fatherId: string,
    data: Task,
    newName: string
) {
    const clients = useAppSelector(state => state.newClients.clients)
    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const getMyDocs = useGetNewClientTasks();
    const getMyCases = useGetNewCaseTasks()
    const getMyDisputes = useGetNewDisputTasks()

    const clientIndex = clients.findIndex(
        (client) => client.id === fatherId
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === fatherId
    )
    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === fatherId
    )

    return useCallback(() => {
        const oldTodo = data
        const newTodo = {
            id: data.id,
            isCompleted: data.isCompleted,
            title: newName,
            formattedDate: data.formattedDate,
            creator: data.creator,
            completor: data.completor,
            completeTime: data.completeTime,
            fatherId: data.fatherId,
            mustFinishDate: data.mustFinishDate
        }
        
        if(clientIndex > -1) {
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDocs)
        }    
        if(caseIndex > -1) {
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyCases)
        } 
        if(DisputeIndex > -1) {
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDisputes)
        }     
    }, [data, newName, clientIndex, caseIndex, DisputeIndex, getMyDocs, getMyCases, getMyDisputes])
}

export function useHandleUpdateTimeForNewTasks (
    fatherId: string,
    data: Task,
    updateDate: number
) {
    const clients = useAppSelector(state => state.newClients.clients)
    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const getMyDocs = useGetNewClientTasks();
    const getMyCases = useGetNewCaseTasks()
    const getMyDisputes = useGetNewDisputTasks()

    const clientIndex = clients.findIndex(
        (client) => client.id === fatherId
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === fatherId
    )
    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === fatherId
    )

    return useCallback(() => {
        const oldTodo = data
        const newTodo = {
            id: data.id,
            isCompleted: data.isCompleted,
            title: data.title,
            formattedDate: data.formattedDate,
            creator: data.creator,
            completor: data.completor,
            completeTime: data.completeTime,
            fatherId: data.fatherId,
            mustFinishDate: updateDate
        }
        
        if(clientIndex > -1) {
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "clientsTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDocs)
        }    
        if(caseIndex > -1) {
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "caseTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyCases)
        } 
        if(DisputeIndex > -1) {
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, "newTasks", "disputTasks"), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDisputes)
        }     
    }, [data, updateDate, clientIndex, caseIndex, DisputeIndex, getMyDocs, getMyCases, getMyDisputes])
}

// Административные задачи

export function useHandleAddNewAdminForClientTasks (
    newAdminTaskName: string,
    myUser: string,
    clientId: string
) {

    const UniqeId = uuid()
    const myTime = dayjs().valueOf()

    const getMyDocs =useGetNewAdminTasks()

    return useCallback(() => {
        const newTask = {
            id: UniqeId,
            author: myUser,
            name: newAdminTaskName,
            important: true,
            lawyer: '',
            type: 'admin',
            fatherId: clientId,
            status: 'not',
            timeCreate: myTime,
            timeConfirm: '',
            timeComplete: ''
        }
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayUnion(newTask)
        }).then(getMyDocs)
    }, [UniqeId, clientId, getMyDocs, myTime, myUser, newAdminTaskName])
}

export function useHandleAddNewAdminTasks (
    userID: string,
    name: string,
    myUser: string,
    type: string
) {
    const checkImportant = () => {
        if(type === 'admin') {
            let important = true
            return important
        } else {
            let important = false
            return important
        }
        
    }
    const important = checkImportant()
    const UniqeId = uuid()
    const myTime = dayjs().valueOf()

    const getMyDocs = useGetNewAdminTasks()
    return useCallback(() => {
        const newTask = {
            id: UniqeId,
            author: myUser,
            name: name,
            important: important,
            lawyer: userID,
            type: type,
            fatherId: '',
            status: 'not',
            timeCreate: myTime,
            timeConfirm: '',
            timeComplete: ''
        }
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayUnion(newTask)
        }).then(getMyDocs)
    }, [UniqeId, getMyDocs, important, myUser, name, type, userID, myTime])
}

export function useHandleNewAdminTasksInWorkStart (
    data: Mission,
    myUser: string
) {
    const myTime = dayjs().valueOf()
    const getMyDocs = useGetNewAdminTasks()
    const getMyClients = useGetNewClientTasks();
    const newDate = data.timeCreate + 604800000

    return useCallback(() => {
        const oldAdminTask = data
        const newAdminTask = {
            id: data.id,
            author: data.author,
            name: data.name,
            important: data.important,
            lawyer: myUser,
            type: data.type,
            fatherId: data.fatherId,
            status: 'inwork',
            timeCreate: data.timeCreate,
            timeConfirm: myTime,
            timeComplete: ''        
        }
        const newTodo = {
            id: data.id,
            title: data.name,
            isCompleted: false,
            formattedDate: myTime,
            creator: myUser,
            completor: '',
            completeTime: null,
            fatherId: data.fatherId,
            mustFinishDate: newDate
            
        }
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayRemove(oldAdminTask)
        })
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayUnion(newAdminTask)
        })
        updateDoc(doc(db, "newTasks", "clientsTasks"), {
            tasks: arrayUnion(newTodo)
        }).then(getMyDocs).then(getMyClients)
    }, [data, getMyClients, getMyDocs, myTime, myUser, newDate])
}

export function useHandleNewAdminTasksWithNoClientInWorkStart (
    data: Mission,
    newClientOption: string
) {
    const myTime = dayjs().valueOf()
    const getMyDocs = useGetNewAdminTasks()
    const getMyClients = useGetNewClientTasks();
    const newDate = data.timeCreate + 604800000

    return useCallback(() => {
        const oldAdminTask = data
        const newAdminTask = {
            id: data.id,
            author: data.author,
            name: data.name,
            important: data.important,
            lawyer: data.lawyer,
            type: data.type,
            fatherId: newClientOption,
            status: 'inwork',
            timeCreate: data.timeCreate,
            timeConfirm: myTime,
            timeComplete: ''        
        }
        const newTodo = {
            id: data.id,
            title: data.name,
            isCompleted: false,
            formattedDate: myTime,
            creator: data.lawyer,
            completor: '',
            completeTime: null,
            fatherId: newClientOption,
            mustFinishDate: newDate  
        }
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayRemove(oldAdminTask)
        })
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayUnion(newAdminTask)
        })
        updateDoc(doc(db, "newTasks", "clientsTasks"), {
            tasks: arrayUnion(newTodo)
        }).then(getMyDocs).then(getMyClients)
    }, [data, getMyClients, getMyDocs, myTime, newClientOption, newDate])
}

export function useHandleToggleNewAdminTask (
    data: Mission | undefined
) {
    const myTime = dayjs().valueOf()
    const getMyDocs =useGetNewAdminTasks()

    return useCallback(() => {
        const oldTask = data
        const checkNewTask = () => {
            if(data !== undefined) {
                let newTask = {
                    id: data.id,
                    author: data.author,
                    name: data.name,
                    important: data.important,
                    lawyer: data.lawyer,
                    type: data.type,
                    fatherId: data.fatherId,
                    status: 'comleted',
                    timeCreate: data.timeCreate,
                    timeConfirm: data.timeConfirm,
                    timeComplete: myTime
                }
                return newTask
            }
        }
        const newTask = checkNewTask()
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayRemove(oldTask)
        })
        updateDoc(doc(db, "newTasks", "adminTasks"), {
            tasks: arrayUnion(newTask)
        }).then(getMyDocs)
    }, [data, getMyDocs, myTime])
}