import { Routes, Route, useNavigate} from 'react-router';
import {useEffect, useState} from "react";
import './App.css';
import { LeftNavBar } from './components/LeftNavBar';
import { BrowserRouter } from 'react-router-dom';
import { Calender } from './Pages/Calendar';
import { Cases } from './Pages/Cases';
import { NavBar } from './components/NavBar';
import { Bills } from './Pages/Bills';
import { Users } from './Pages/Users';
import { Chats } from './Pages/Chats';
import { Help } from './Pages/Help';
import { UserProfile } from './Pages/UserProfile';
import { Clients } from './Pages/Clients';
import { ArchivedCases } from './Pages/ArchivedCases';
import { DeletedCases } from './Pages/DeletedCases';
import { Login } from './components/AuthForm/Login';
import { useAuth } from './hooks/useAuth';
import { useGetAllCases, useGetNewAdminTasks, useGetNewBillingItems, useGetNewCaseTasks, useGetNewCases, useGetNewClientTasks, useGetNewClients, useGetNewContacts, useGetNewDisputTasks, useGetNewDisputs, useGetNewEvents, useGetNewDocuments, useGetNewBills, useGetNewProofs } from './hooks/useGetDocs';
import { Disputes } from './Pages/Disputes';
import dayjs from 'dayjs';
import { useFindMyUser } from './hooks/useUsers';
import { Admin } from './Pages/Admin';
import { Loader } from './components/Loader';
import { Documents } from './Pages/Documents';
import { NewDashboard } from './Pages/NewDashboard';
import { NewClients } from './Pages/NewClients';
import { ErrorPage } from './Pages/ErrorPage';

function App() {
  const myUser = useFindMyUser()
  const admins = [
    'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
    // 'XkIjsIdw69ghLudgDDztks3g23S2',
    // 'KQydQPbF73YFmJeUB6L5thnsUnq2',
    // 'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
    
    // 'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
    // '1zNyYjM7AKgkkIYvBI3d2tB4kJ43'
  ]

  // && admins.includes(myUser)


  const {isAuth} = useAuth()
  const getMyDocs = useGetNewClients();
  const getMyCases = useGetNewCases()
  const getMyDisputs = useGetNewDisputs()
  const getMyEvents = useGetNewEvents()
  const getMyContacts = useGetNewContacts()
  const getMyClientTasks = useGetNewClientTasks()
  const getMyCaseTasks = useGetNewCaseTasks()
  const getMyDisputTasks = useGetNewDisputTasks()
  const getMyBillingItems = useGetNewBillingItems()
  const getAllCases = useGetAllCases()
  const getAdminTasks = useGetNewAdminTasks()
  const getDocuments = useGetNewDocuments()
  const getBills = useGetNewBills()
  const getProofs = useGetNewProofs()

  const myTime = dayjs()
  const formattedDate = myTime.format('HH:mm.ss DD.MM');

  const interval = setInterval(() => {
    if (isAuth) {
      getMyDocs()
      getMyDisputs()
      getMyEvents()
      getMyContacts()
      getMyClientTasks()
      getMyCaseTasks()
      getMyDisputTasks()
      getMyBillingItems()
      getAllCases()
      getAdminTasks()
      getDocuments()
      getBills()
      getProofs()
      console.log(`update main page at ${formattedDate}`)
    }
  }, 600000)

  

  useEffect(() => { if (isAuth) {
      getMyDocs(); 
      getMyDisputs(); 
      getMyEvents()
      getMyContacts()
      getMyClientTasks()
      getMyCaseTasks()
      getMyDisputTasks()
      getMyBillingItems()
      getAllCases()
      getAdminTasks()
      getDocuments()
      getBills()
      getProofs()
      console.log(`update at ${formattedDate}`)
    }
    
  }, [formattedDate, getAdminTasks, getAllCases, getBills, getDocuments, getMyBillingItems, getMyCaseTasks, getMyCases, getMyClientTasks, getMyContacts, getMyDisputTasks, getMyDisputs, getMyDocs, getMyEvents, getProofs, isAuth]
  )
  const [openCode, setOpenCode] = useState(true)



  return (
    <BrowserRouter>
    {isAuth  ?  ( 
      <div className='App'>
        {/* {admins.includes(myUser) ? (<> */}
          <LeftNavBar />
          <div className='main'>
            <NavBar />
            <Routes>
              <Route path='/' element={<NewDashboard />}/>
              <Route path='/admin' element={<Admin />}/>
              <Route path='/calendar' element={<Calender />}/>
              <Route path='/cases' element={<Cases />}/>
              <Route path='/cases/:id' element={<Cases />}/>
              <Route path='/archivedcases' element={<ArchivedCases />}/>
              <Route path='/archivedcases/:id' element={<ArchivedCases />}/>
              <Route path='/deletedcases' element={<DeletedCases />}/>
              <Route path='/deletedcases/:id' element={<DeletedCases/>}/>
              <Route path='/bills' element={<Bills />}/>
              <Route path='/users' element={<Users />}/>
              <Route path='/users/:id' element={<Users />}/>
              <Route path='/chats' element={<Chats />}/>
              <Route path='/chats/:id' element={<Chats />}/>
              <Route path='/help' element={<Help />}/>
              <Route path='/userprofile' element={<UserProfile />}/>
              <Route path='/clients' element={<Clients />}/>
              <Route path='/clients/:id' element={<Clients />}/>
              <Route path='/newClients' element={<NewClients />}/>
              <Route path='/newClients/:id' element={<NewClients />}/>
              <Route path='/disputes' element={<Disputes />}/>
              <Route path='/disputes/:id' element={<Disputes />}/>
              <Route path='/documents' element={<Documents />}/>
              <Route path='/documents/:id' element={<Documents />}/>
              <Route path='*' element={<ErrorPage />}/>
            </Routes>
          </div>
        {/* </>) : (<Loader />)} */}
    </div>
    ) : (
      <div>
      <Login />
    </div>
    )}
    </BrowserRouter>
  );
}

export default App;
