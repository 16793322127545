import {FC, useState} from 'react';
import { CaseScreenProps } from './interface';
import classes from './casescreen.module.scss';
import { useAppSelector } from '../../../store/reduxHooks';
import { ButtonBox } from '../../ButtonBox';
import {MdCalendarMonth} from 'react-icons/md';
import { TodoItem } from '../../Clientui/TodoItem/MainTodoItem';
import cx from 'classnames';
import {RiCloseFill} from 'react-icons/ri';
import Select from 'react-select'
import { MoreInformationEditMenu } from '../../../components/MoreInformationEditMenu';
import { AddTodoComponent } from '../../../components/AddTodoComponent';
import { UserCard } from '../../UserUi/UserCard';
import { BsFolderPlus, BsPlusCircleFill } from 'react-icons/bs';
import { AddDisputeForm } from '../../../components/AddDisputeForm';
import { DisputeItem } from '../../DisputesUi/DisputeItem';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { EventsForCase } from '../EventsForCase';
import { useHandleAddLawyerToCase, useHandleDeleteSudNewCases } from '../../../hooks/useNewCases';
import { useNavigate } from 'react-router';
import { useFindMyUser } from '../../../hooks/useUsers';
import { ChangeCaseForm } from '../ChangeCaseForm';
import { BillingItem } from '../../../components/BillingItem';
import { AddEventComponent } from '../../DisputesUi/AddEventComponent';
import { EventForDisput } from '../../DisputesUi/EventForDisput';
import { UserCardForCase } from '../../UserUi/UserCardForCases';
import { CompleteCaseMenu } from '../CompleteCaseMenu';
import { CompletedEventsMenu } from '../../../components/CompletedEventsMenu';


export const CaseScreen: FC<CaseScreenProps> = (props) => {
    const { data } = props;
    const { id, name, clientId, annotation, plan, teg, lawyers, number, caseType} = data;
    
    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const filteredDisputes = disputes.filter((disput) => disput.caseId === id)
    const activeDisputes = filteredDisputes.filter((disput) => disput.isActive === true)
    
    require('dayjs/locale/ru')
    dayjs.locale('ru')
    const localizer = dayjsLocalizer(dayjs)

    
    let filteredCaseUsers: string[] = [];
    lawyers.forEach((el) => {
    if (!filteredCaseUsers.includes(el)) {   
        filteredCaseUsers.push(el);
    }
    });


    const users = useAppSelector(state => state.users.users);
    
    const magicUser = users.filter((user) => filteredCaseUsers.includes(user.id));

    const tasks = useAppSelector(state => state.newCaseTasks.tasks)
    const completedTasks = tasks.filter(i => i.isCompleted === true && i.fatherId === id);
    const unCompletedTasks = tasks.filter(i => i.isCompleted === false && i.fatherId === id);

    const [open, setopen] = useState(true);
    const handleOpenCloseClick = () => setopen(value => !value)

    const [archiveMenuOpen, setArchiveMenuOpen] = useState(true)
    const handleOpenArchiveMenuClick = () => setArchiveMenuOpen(value => !value)

    const [deleteMenuOpen, setDeleteMenuOpen] = useState(true);
    const handleOpenDeleteMenuClick = () => setDeleteMenuOpen(value => !value)

    const [informationMenuOpen, setInformationMenuOpen] = useState(true);
    const handleOpenInfoMenuClick = () => {setInformationMenuOpen(value => !value);}
    console.log(informationMenuOpen)

    const [addUserOpen, setAddUserOpen] = useState(true);
    const handleAddUserClick = () => setAddUserOpen(value => !value)

    const [changeMenuOpen, setChangeMenuOpen] = useState(true);
    const handleOpenChangeMenuClick = () => setChangeMenuOpen(value => !value)

    const [userOptionChoice, setUserOptionChoice] = useState<any>([] as any)
    const addUserValue = userOptionChoice.value

    
    const [completeCaseMenuOpen, setCompleteCaseMenuOpen] = useState(true)
    const handleCompleteCaseMenuOpenClick = () => setCompleteCaseMenuOpen(value => !value)

    const [calendarMenuOpen, setCalendarMenuOpen] = useState(true);
    const handleOpenCalendarClick = () => setCalendarMenuOpen(value => !value)

    const [lastEventsMenuOpen, setLastEventsMenuOpen] = useState(true);
    const handeOpenLastEventsClick = () => setLastEventsMenuOpen(value => !value)


    const myDisputesIds = activeDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const myEventsList = useAppSelector(state => state.newEvents.events)
    const caseEvents = myEventsList.filter((event => event.disputId === id))

    const customEventList = myEventsList.filter((event) => myDisputesIds.includes(event.disputId))
    const finalEventList = [...caseEvents, ...customEventList]
    const sortCustomEventList = finalEventList.sort((a, b) => a.start > b.start ? 1 : -1);

    const [inputValue, setInputValue] = useState([] as any)
    // const handleCompleteCaseClick = () => {
    //     if(inputValue.length > 50) {
    //         dispatch(completeCase({inputValue, id, clientId}))
    //         setInputValue('')
    //         handleCompleteCaseMenuOpenClick()
    //     } else {alert('Надо бы побольше')}
    // }

    const [disputOpen, setDisputOpen] = useState(true);
    const handleCheck = () => setDisputOpen(value => !value);

    const handleOpenTaskInfoClick = () => {return true}

    const handleOpenKadCardClick = () => {
        if (number.includes('№')) {
            alert('Дайте мне немного времени, и я сделаю интеграцию с судами общей юрисдикции:)')
        }
        else {
            window.open(`https://kad.arbitr.ru/Card?number=${number}`)
        }
    }
    const navigate = useNavigate()
    // const archiveCase = useHandleArchiveNewCases(data)
    // const archiveCaseClick = () => {
    //     archiveCase()
    //     handleOpenArchiveMenuClick()
    //     navigate('/cases')
    // }

    const deleteCase = useHandleDeleteSudNewCases(data)
    const deleteCaseClick = () => {
        deleteCase()
        handleOpenDeleteMenuClick()
        navigate('/cases')
    }
    const addUsersArray = users.filter((item) => !lawyers.includes(item.id))

    const fixOptions = [
        { value: "abonent", label: "Абонентское обслуживание" },
        { value: "sudBankrot", label: "Банкротство" },
        { value: "sudSimple", label: "Общая юрисдикция" },
        { value: "sudArbitr", label: "Арбитражное судопроизводство" },
        { value: "otcenka", label: "Оценка" },
        { value: "kadastr", label: "Кадастровые работы" },
      ];


      const [tagtoCase, setTagtoCase] = useState<any>([] as any)
      const formattedDate = dayjs().valueOf()
      const myCaseTag = tagtoCase.value
      const myUser = useFindMyUser()
    //   const fixData = useHandleFixCase(data, myCaseTag, myUser, formattedDate)
    //   const fixDataHandleClick = () => {
    //     fixData()
    //   }
    const billingItems = useAppSelector(state => state.newBillingItems.billingItems)
    const myBillingItems = billingItems.filter((item) => item.fatherId === id)
    const totalPriceItems = myBillingItems.map(item => {
        let option = 0
        option = item.hourCost * item.hours
        return option
    })
    const totalPriceReduce = totalPriceItems.reduce((acc, currentValue) => acc + currentValue, 0)

    const addUser = useHandleAddLawyerToCase(id, addUserValue)
    const handleAddLawyerToCaseClick = () => {
        addUser()
        handleAddUserClick()
    }

    const clients = useAppSelector(state => state.newClients.clients)
    const myClient = clients.find((client) => client.id === clientId)

    const handleJumpToClientClick = () => {
        navigate(`/clients/${clientId}`)
    }

  return (
    <div className={classes.root}>
         <div className={classes.header}>
            <div className={classes.task} onClick={handleJumpToClientClick}>
                {myClient?.name}
            </div>
            <div className={classes.clientRoot}>
                <div className={classes.client}>
                    {name}
                    <div  className={classes.titleforKad}>Номер дела: 
                        <span className={classes.linkToKad} onClick={handleOpenKadCardClick }>
                         {number}
                        </span>
                        <div>
                            {teg}
                        </div>
                    </div>
                </div>
                <ButtonBox 
                    handleOpenTaskInfoClick ={handleOpenTaskInfoClick}
                    handleCompleteCaseClick={handleCompleteCaseMenuOpenClick}
                    handleOpenInfoMenuClick={handleOpenInfoMenuClick}
                    handleOpenChangeMenuClick={handleOpenChangeMenuClick}
                    handleArchiveCaseClick={handleOpenArchiveMenuClick}
                    handleOpenDeleteMenuClick={handleOpenDeleteMenuClick} 
                    handleOpenCalendarClick={handleOpenCalendarClick}
                    handeOpenLastEventsClick={handeOpenLastEventsClick}
                    fatherId={id}
                />
            </div>
        </div>


        <div className={cx(classes.popUp, disputOpen === true && classes.popUpClose)} onClick={handleCheck}>
            <div className={classes.disputeForm} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCheck}>
                    <RiCloseFill size={22} />
                </button>
                <AddDisputeForm clientId={clientId} caseId = {id} clientName={name} handleClose={handleCheck} />
            </div>
        </div>

        {/* <div className={cx(classes.popUp, caseType && classes.popUpClose)} >
            <div className={classes.addFixForm} onClick={e => e.stopPropagation()}>
                <div className={classes.title}>
                {name}
                </div>
                Укажите тип дела
                <Select
                    isClearable={false}
                    className={classes.select}
                    classNamePrefix="select"
                    options={fixOptions}
                    onChange={(chioce) => setTagtoCase(chioce)} 
                    defaultValue={{label: '', value: ''}}
                    />
                    <button className={classes.buttonComplete} onClick={fixDataHandleClick}>
                        Добавить
                    </button>
            </div>
        </div> */}

        {/* Добавить ответственного */}
        <div className={cx(classes.popUp, addUserOpen === true && classes.popUpClose)} onClick={handleAddUserClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
            <button className={classes.closeIcon} onClick={handleAddUserClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.popUpInput}>
                    <Select
                        defaultValue={{label: 'Добавить пользователя', value: ''}}
                        name="colors"
                        options={addUsersArray}
                        className={classes.select}
                        classNamePrefix="select"
                        onChange={(chioce) => setUserOptionChoice(chioce)}
                    />
                    <button onClick={handleAddLawyerToCaseClick} className={classes.button}>Добавить</button>
                </div>
            </div>
        </div>


        {/* Завершить кейс */}
        <div className={cx(classes.popUp, completeCaseMenuOpen === true && classes.popUpClose)}>
            <div className={classes.completedMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCompleteCaseMenuOpenClick}>
                    <RiCloseFill size={22} />
                </button>
                <CompleteCaseMenu handleCloseWindow={handleCompleteCaseMenuOpenClick} data={data} caseId={id} />
            </div>
        </div>

        {/* Прошлые заседания */}
        <div className={cx(classes.popUp, lastEventsMenuOpen === true && classes.popUpClose)}>
            <div className={classes.lastEventsMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handeOpenLastEventsClick}>
                    <RiCloseFill size={22} />
                </button>
                <CompletedEventsMenu />
            </div>
        </div>

        {/* Открыть календарь */}
        <div className={cx(classes.popUp, calendarMenuOpen === true && classes.popUpClose)}>
            <div className={cx(classes.calendarMenu, customEventList.length === 0 && classes.clandarSmallMenu)} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenCalendarClick}>
                    <RiCloseFill size={22} />
                </button>
                {customEventList.length ? (<Calendar
                localizer={localizer}
                events={customEventList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 'calc(100% - 50px)'}}
                views={{
                    month: true, 
                }}
                messages={{
                    month: 'Месяц',
                    work_week: 'Рабочая неделя',
                    date: 'Дата',
                    time: 'Время',
                    event: 'Событие',
                    previous: 'Назад',
                    next: 'Вперед',
                    today: 'Сегодня',
                    noEventsInRange: 'В указанный период заседания отсутствуют'
                }}
                />) : (
                    <div className={classes.alertMenu}>
                    <div className={classes.alertText}>
                        <FaRegCalendarTimes size={50} />
                        <div className={classes.alertTitle}>
                            Судебных заседаний на ближайший месяц нет                           
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>

        {/* Доп Информация о кейсе */}
        <div className={cx(classes.popUp, informationMenuOpen === true && classes.popUpClose)} >
            <div className={classes.infoMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenInfoMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                {/* <MoreInformationEditMenu 
                handleOpenInfoMenuClick={handleOpenInfoMenuClick} 
                annotation={annotation} 
                plan={plan}
                fatherID={id} 
                data={data}/> */}
            </div>
        </div>

        {/* Архивировать кейс */}
        <div className={cx(classes.popUp, archiveMenuOpen === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenArchiveMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите перенести в архив дело "{name}" у клиента {}?
                <div className={classes.deleteButtons}>
                    <button className={classes.buttonComplete}  >Да</button> <button className={classes.buttonComplete} onClick={handleOpenArchiveMenuClick}>Нет</button>
                </div>
            </div>
        </div>

        {/* Удалить Кейс */}
        <div className={cx(classes.popUp, deleteMenuOpen === true && classes.popUpClose)} onClick={handleOpenDeleteMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenDeleteMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите удалить дело "{name}" у клиента {}?
                <div className={classes.deleteButtons}>
                    <button className={classes.buttonComplete} onClick={deleteCaseClick} >Да</button> <button className={classes.buttonComplete} onClick={handleOpenDeleteMenuClick}>Нет</button>
                </div>
            </div>
        </div>

        {/* Изменить Кейс */}
        <div className={cx(classes.popUp, changeMenuOpen === true && classes.popUpClose)}>
            <div className={classes.changeCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenChangeMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Изменить "{name}"
                {/* <ChangeCaseForm 
                      data={data}
                      handleClose={handleOpenChangeMenuClick}
                /> */}
            </div>
        </div>

        <div className={classes.section}>
            <div className={classes.users}>
                <div className={classes.userBox}>
                    {magicUser.map((user) => (
                        <UserCardForCase  data={user} userId={user.id} myDisput={data} fatherId={id} />
                        ))}
                        <div className={classes.addLawyer} onClick={handleAddUserClick}>
                            <BsPlusCircleFill size={40}  />
                        </div>
                    </div>
            </div>
            <div className={classes.calendar}>
                {sortCustomEventList.map((event) => (
                    <EventForDisput data={event} key={event.id} />
                ))}
                <AddEventComponent disputId={id} />
            </div>
        </div> 
        

        <div className={classes.inputFlex}>
            <div className={classes.inputBlock}>
                {/* <div className={classes.addCase} onClick={handleCheck}>
                    <BsFolderPlus size={30} />
                </div>         */}
                <div className={classes.inputBox}>
                    <AddTodoComponent id={id} />
                </div> 
            </div>
            <div className={classes.inputBlock}>
    
            </div>        
        </div>

        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleOpenCloseClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenCloseClick}>
                    <RiCloseFill size={22} />
                </button>
            </div>
        </div>
        <div className={classes.content}>
            <div className={classes.todos}>
            {unCompletedTasks.length ? (<div>
                    Задачи
                    {unCompletedTasks.map((task) => (
                        <TodoItem clientId={clientId} data={task} key={task.id} />  
                    ))}
                </div>) : null}

                {completedTasks.length ? (<div>
                    Выполненные задачи
                    {completedTasks.reverse().map((task) => (
                        <TodoItem clientId={clientId} data={task} key={task.id} />  
                    ))}
                </div>) : null}
            </div>
            <div className={classes.todos}>
            Биллинг
                <div>{myBillingItems.map((item) => (
                    <BillingItem data={item} key={item.id} />
                ))}</div>
            </div>
        </div>

    </div>
  )
}
