import {createSlice} from '@reduxjs/toolkit';
import { Client } from '../../api/interface';

interface EventsState {
    clients: Client[]
}
const initialState: EventsState = {
    clients: []
}

export const newClientsSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewClients(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewClients
} = newClientsSlice.actions
export default newClientsSlice.reducer