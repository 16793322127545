import {FC, useState, useEffect} from "react";
import { UsersProps } from "./interface";
import { UserCard } from "../../ui/UserUi/UserCard";
import classes from './users.module.scss'
import { useParams } from 'react-router-dom';
import { UserScreen } from "../../ui/UserUi/UserScreen";
import { useAppSelector } from "../../store/reduxHooks";
import { RiInformationLine } from "react-icons/ri";
import cx from 'classnames'
import {CompletedTasksForDepartmentScreen} from "../../ui/UserUi/CompletedTasksForDepartmentScreen";
import { useAuth } from "../../hooks/useAuth";

export const Users: FC<UsersProps> = (props) => {
  const {isAuth} = useAuth()

  useEffect(() => {
    if(!isAuth) {
      window.location.assign('/')
    }
  })

    const { id } = useParams();
    const users = useAppSelector(state => state.users.users);
    const user = users.find((user) => user.id === id);
    
    const adminUsers = users.filter((user) => user.specs !== 'Отдел оценки' && user.specs !== 'Кадастровый отдел' && user.specs !== 'Отдел консалтинга' && user.specs !== 'Судебный отдел')
    const sudUsers = users.filter((user) => user.specs === 'Судебный отдел')
    const abonentUsers = users.filter((user) => user.specs === 'Отдел консалтинга')
    const kadastrUsers = users.filter((user) => user.specs === 'Кадастровый отдел')
    const otcenkaUsers = users.filter((user) => user.specs === 'Отдел оценки')
    const departmentUsers = [
      {
        title: 'Административный отдел',
        data: adminUsers
      },
      {
        title: 'Судебный отдел',
        data: sudUsers
      },
      {
        title: 'Отдел консалтинга',
        data: abonentUsers
      },
      {
        title: 'Кадастровый отдел',
        data: kadastrUsers
      },
      {
        title: 'Отдел оценки',
        data: otcenkaUsers
      },
    ]

    const [showCompletedTasks, setShowCompletedTasks] = useState(true)
    const [activeDepartment, setActiveDepartment] = useState('')
    const handleCloseInfoMenu = () => setShowCompletedTasks(value => !value)
    const handleOpenInfoMenu = (title: string) => {
      setShowCompletedTasks(value => !value)
      setActiveDepartment(title)
    }
    
  return (
  <div className={classes.root}>
      {/* Завершенные задачи */}
      <div className={cx(classes.popUp, showCompletedTasks === true && classes.popUpClose)} >
          <div className={classes.taskMenu} onClick={e => e.stopPropagation()}>
            <CompletedTasksForDepartmentScreen department={activeDepartment}   handleOpenTaskInfoClick={handleCloseInfoMenu} />
          </div>
        </div>
    <div className={classes.users}>
      {departmentUsers.map((item) => (
        <div key={item.title} className={cx(classes.admin, 
        item.title === 'Судебный отдел' && classes.sud,  
        item.title === 'Отдел консалтинга' && classes.abonent,
        item.title === 'Кадастровый отдел' && classes.kadastr,
        item.title === 'Отдел оценки' && classes.otcenka,
        )}>

        <div className={classes.titleRoot}>
          <div className={classes.title}>
            {item.title}
          </div>
          <div className={cx(classes.mgcButtonAdmin, 
        item.title === 'Судебный отдел' && classes.mgcButtonSud,  
        item.title === 'Отдел консалтинга' && classes.mgcButtonAbonent,
        item.title === 'Кадастровый отдел' && classes.mgcButtonKadastr,
        item.title === 'Отдел оценки' && classes.mgcButtonOtcenka,
        )}>
            <RiInformationLine size={25} onClick={() => handleOpenInfoMenu(item.title)} />
          </div>
        </div>
        {item.data.map((user) => (
            <UserCard data={user} userId={user.id} key={user.id} />
          ))}      
        </div>
      ))}
    </div>
      
    <div className={classes.screen}>
      {user ? <UserScreen data={user} {...user} /> : null}
    </div>
  </div>
    
  )
}
