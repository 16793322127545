import {FC} from "react";
import { ChatsProps } from "./interface";
import { ChatBar } from "../../ui/ChatUi/ChatBar";
import { ChatScreen } from "../../ui/ChatUi/ChatScreen";
import classes from './chats.module.scss'
import { useParams } from "react-router";
import { useAppSelector } from "../../store/reduxHooks";

export const Chats: FC<ChatsProps> = (props) => {
  const {id} = useParams();

  return (
    <div className={classes.root}>
      <ChatBar />
      
      
    </div>
  )
}
