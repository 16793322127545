import {FC,  useState, useEffect} from 'react';
import { ChangeDisputFormProps } from './interface';
import classes from './changecaseform.module.scss'
import { MyInput } from '../../../components/MyInput';
import { useHandleUpdateNewDisputes } from '../../../hooks/useNewDisputes';


export const ChangeDisputForm: FC <ChangeDisputFormProps> = (props) => {
    const {handleClose,  data} = props;
    const {id, name, paymentsystem, billSum, courtPrice, lawyerPrice, compensationSystem} = data

    const [caseName, setCaseName] = useState<string>(name)
    const [clientPaymentSystem, setClientPaymentSystem] = useState<string>(paymentsystem)
    const [clientBillSum, setClientBillSum] = useState<number>(billSum)
    const [clientCourtPrice, setClientCourtPrice] = useState<number>(courtPrice)
    const [clientLawyerPrice, setClientLawyerPrice] = useState<number>(lawyerPrice)
    const [clientCompensationSystem, setClientCompensationSystem] = useState<string>(compensationSystem)

    const updateDisput = useHandleUpdateNewDisputes(data, caseName, clientPaymentSystem, clientBillSum, clientCourtPrice, clientLawyerPrice, clientCompensationSystem)
    useEffect(() => {
        setCaseName(name)
        setClientPaymentSystem(paymentsystem)
        setClientBillSum(billSum)
        setClientCourtPrice(courtPrice)
        setClientLawyerPrice(lawyerPrice)
        setClientCompensationSystem(compensationSystem)
      }, [handleClose])
      
    const handleClick = () => {
        if ( caseName.length > 0  &&
            clientPaymentSystem.length > 0 &&
            clientBillSum > 0 &&
            clientCourtPrice > 0 &&
            clientLawyerPrice > 0 &&
            clientCompensationSystem.length > 0
            ) {
                updateDisput()
                handleClose()
            } else {alert('Заполните все поля')}
    }

  return (
    <div className={classes.root}>
        <div className={classes.content}>
            {data ? (
                <>
                    <MyInput 
                        value={caseName}
                        type={'text'}
                        handleInput={setCaseName}
                        inputName={'Название дела'}
                        placeholder={name} 
                    />
                    <MyInput 
                        value={clientPaymentSystem}
                        type={'text'}
                        handleInput={setClientPaymentSystem}
                        inputName={'Система оплаты'}
                        placeholder={paymentsystem} 
                    />
                    <MyInput 
                        value={clientBillSum}
                        type={'number'}
                        handleInput={setClientBillSum}
                        inputName={'Сумма выставления счета'}
                        placeholder={billSum.toString()} 
                    />
                    <MyInput
                        value={clientCourtPrice}
                        type={'number'}
                        handleInput={setClientCourtPrice}
                        inputName={'Стоимость судебного заседания'}
                        placeholder={courtPrice} 
                    />
                    <MyInput 
                        value={clientLawyerPrice}
                        type={'number'}
                        handleInput={setClientLawyerPrice}
                        inputName={'Стоимость часа работы юриста'}
                        placeholder={lawyerPrice.toString()} 
                    />
                    <MyInput 
                        value={clientCompensationSystem}
                        type={'text'}
                        handleInput={setClientCompensationSystem}
                        inputName={'Способ компенсации накладных расходов'}
                        placeholder={compensationSystem} 
                    />
                </>
            ) : null}
 
                    <button className={classes.button} onClick={handleClick}>Изменить</button>
        </div>
    </div>
  )
}
