import {FC, useState} from 'react'
import { MissonProps } from './interface'
import classes from './mission.module.scss'
import { title } from 'process'
import { FaFire } from 'react-icons/fa'
import cx from 'classnames'
import { RiCloseFill } from 'react-icons/ri'
import { useAppSelector } from '../../store/reduxHooks'
import Select from 'react-select'
import { useFindMyUser } from '../../hooks/useUsers'
import { useHandleNewAdminTasksInWorkStart, useHandleNewAdminTasksWithNoClientInWorkStart } from '../../hooks/useNewTasks'

export const MissionRoot: FC<MissonProps> = (props) => {
    const {data} = props
    const { author, name, important, type, fatherId, lawyer, id} = data
    const allUsers = useAppSelector(state => state.users.users)
    const authorUser = allUsers.find(i => i.id === author)
    const slaveUser = allUsers.find(i => i.id === lawyer)

    const clients = useAppSelector(state => state.newClients.clients)
    const activeClients = clients.filter(i => i.isActive === true)
    const myClient = clients.find(i => i.id === fatherId)
    const myUser = useFindMyUser()

    const [openInfoClientMenu, setOpenInfoClientMenu] = useState(false)
    const [openInfoMenu, setOpenInfoMenu] = useState(false)


    const openInfoMenuClick = () => {
        if(fatherId.length) {
            setOpenInfoClientMenu(value => !value)
        } else {
            setOpenInfoMenu(value => !value)
        }
    }

    const [user, setUser] = useState<any>({value:'', label:''})
    const usersOptions = activeClients.map(item => {
        const user = {
          value: '',
          label: ''
        }
        user.value = item.id
        user.label = item.name
        return user
      })

      const createNewItemWithClient = useHandleNewAdminTasksInWorkStart(data, myUser)

      const handleConfirmTaskWithClientClick = () => {
        createNewItemWithClient()
        setOpenInfoClientMenu(false)
      }

      const newClientOption = user.value

      const createNewItemWithoutClient = useHandleNewAdminTasksWithNoClientInWorkStart(data, newClientOption)

      const handleConfirmTaskNoClientClick = () => {
        if(user.value !== ''){
            createNewItemWithoutClient()
            setOpenInfoMenu(false)
        }else {alert('Добавьте клиента')}
      }

  return (
    <div className={classes.root} onClick={openInfoMenuClick}>
        <div className={title}>
            {name}
        </div>
        <div className={classes.icons}>
            <div className={cx(classes.fireIcon, important === true && classes.fireIconImportant)}>
                <FaFire size={22} />
            </div>
        </div>

          {/* Открыть ИнфоМеню если клиент есть */}
          <div className={cx(classes.popUp, openInfoClientMenu === false && classes.popUpClose)} onClick={() => setOpenInfoClientMenu(value => !value)}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={() => setOpenInfoClientMenu(value => !value)}>
                  <RiCloseFill size={22} />
                </button>
                <div className={classes.title}>
                    {name}
                </div>
                <div>
                    Постановщик задачи: {authorUser?.label} <br />
                    Клиент: {myClient?.name}
                </div>
                <button className={classes.inputButton} onClick={handleConfirmTaskWithClientClick}>
                    Принять
                </button> 
            </div>
        </div>

        {/* Открыть ИнфоМеню если клиента нет */}
        <div className={cx(classes.popUp, openInfoMenu === false && classes.popUpClose)} onClick={() => setOpenInfoMenu(value => !value)}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={() => setOpenInfoMenu(value => !value)}>
                  <RiCloseFill size={22} />
                </button>
                <div className={classes.title}>
                    {name}
                </div>    
                <div>
                    Постановщик задачи: {authorUser?.label} <br />
                    Исполнитель: {slaveUser?.label}
                </div>   
                <Select
                            isClearable={false}
                            classNamePrefix="select"
                            options={usersOptions}
                            onChange={(chioce) => setUser(chioce)} 
                            defaultValue={user}
                        />
                <button className={classes.inputButton} onClick={handleConfirmTaskNoClientClick}>
                    Принять
                </button>         
            </div>
        </div>
    </div>
  ) 
}
