import {FC} from 'react'
import { DocumentClientRootProps } from './interface'
import classes from './documentclientroot.module.scss'
import { useNavigate, useParams } from 'react-router';
import { RiInformationLine } from 'react-icons/ri';
import cx from 'classnames'
import { useAppSelector } from '../../../store/reduxHooks';
import { DocumentComponent } from '../../Clientui/DocumentComponent';

export const DocumentClientRoot:FC <DocumentClientRootProps> = (props) => {
    const {data, clientId} = props
    const {name} = data
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/clients/${clientId}`);
    }
    const {id} = useParams()
    const allDocs = useAppSelector(state => state.newDocuments.docs)
    const myDocs = allDocs.filter((doc) => doc.clientId === clientId)
    const foundedDoc = myDocs.find((doc) => doc.id === id)
    

  return (
    <div className={cx(classes.root, foundedDoc  && classes.selectedRoot, !myDocs.length && classes.emptyRoot)}>
        <div className={classes.title}>
            {name}
            <div className={cx(classes.icon, foundedDoc  && classes.selectedIcon)}>
                <RiInformationLine onClick={handleClick} size={25} />
            </div>
        </div>
        <div className={classes.list}>
            {myDocs.map((doc) => (
                <DocumentComponent data={doc} key={doc.id} />  
            ))}
        </div>
    </div>
  )
}
