import {FC} from 'react'
import { DisputAdminItemProps } from './interface'
import classes from './caseadminitem.module.scss'
import { useAppSelector } from '../../../store/reduxHooks'
import dayjs from 'dayjs';
import { TodoDashboardItem } from '../../MyDashboardUi/TodoDashboardItem'
import { useNavigate } from 'react-router'

export const DisputAdminItem: FC<DisputAdminItemProps> = (props) => {
    const {caseName, caseId} = props

    const casesTasks = useAppSelector(state => state.newDiputTasks.tasks)

    const myTasks = casesTasks.filter((task) => task.fatherId === caseId)

    const todayMidnight = dayjs().startOf('day').valueOf()

    const twoDaysAgo = todayMidnight - 604800000
    
    const foundedTasks = myTasks.filter((task) => task.completeTime > twoDaysAgo && task.isCompleted === true)
  
    const navigate = useNavigate()

  return (
    <div className={classes.root}>
        <div className={classes.header}>

            <div className={classes.title} onClick={() => navigate(`/disputes/${caseId}`)}>
                <div className={classes.clientTitle}>
                    {caseName}
                </div>
            </div>

        </div>
        <div className={classes.body}>
            {foundedTasks.length ? (
                <>
                {foundedTasks.map((item) =>
                <TodoDashboardItem key={item.id} data={item}/>
                )}
                </>
            ) : (
                <div className={classes.notWork}>
                    За последнюю неделю работа не велась
                </div>
            )}
        </div>
    </div>
  )
}
