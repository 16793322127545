import {FC, useEffect, useState} from 'react'
import { CompletedTasksForUserScreenProps } from './interface'
import classes from './completedtasksforuserscreen.module.scss'
import { RiCloseFill } from 'react-icons/ri'
import { useAppSelector } from '../../../store/reduxHooks'
import {DisputCompletedTasksItem} from '../DisputCompletedTasksItem'
import dayjs from 'dayjs'
import cx from 'classnames'
import { GiSadCrab } from 'react-icons/gi'
import { TodoDashboardItem } from '../../MyDashboardUi/TodoDashboardItem'
import { CaseCompletedTasksItem } from '../CaseCompletedTasksItem'


export const CompletedTasksForUserScreen: FC<CompletedTasksForUserScreenProps> = (props) => {
    const {lawyerId, handleOpenTaskInfoClick} = props
    const disputes = useAppSelector((state) => state.newDisputes.disputes)
    const activeDisputes = disputes.filter((disput) => disput.isActive === true)
    const disputTasks = useAppSelector(state => state.newDiputTasks.tasks)
    const caseTasks = useAppSelector(state => state.newCaseTasks.tasks)
    const clientTasks = useAppSelector(state => state.newClientTasks.tasks)

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)

    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudSimpleCases, ...sudBancrotCases]

  
    const myTasks = [...disputTasks, ...caseTasks, ...clientTasks]
    const myComletedTasks = myTasks.filter((task) => task.isCompleted === true && task.completor === lawyerId)
    
    const today = dayjs().endOf('day').valueOf()
    const monthAgo = today - 2629800000
    const weekAgo = today - 604800000
    const dayAgo = today - 86400000

    const filteredWeekTasks = myComletedTasks.filter((task) => task.completeTime > weekAgo)
    const filteredDayTasks = myComletedTasks.filter((task) => task.completeTime > dayAgo)
    const filteredMonthTasks = myComletedTasks.filter((task) => task.completeTime > monthAgo)

    useEffect(() => {
        setFilter(filteredWeekTasks)
        setSelect('week')
      }, [handleOpenTaskInfoClick])
  
      const [filter, setFilter] = useState(filteredWeekTasks)
      const [select, setSelect] = useState('week')
  
      const setDayClick = () => {
        setFilter(filteredDayTasks)
        setSelect('day')
      }
      const setWeekClick = () => {
        setFilter(filteredWeekTasks)
        setSelect('week')
      }
      const setMonthClick = () => {
        setFilter(filteredMonthTasks)
        setSelect('month')
      }

  return (
    <div className={classes.root}>
        <button className={classes.closeIcon} onClick={handleOpenTaskInfoClick}>
            <RiCloseFill size={22} />
        </button>
        <div className={classes.buttonBox}>
        <button className={cx(classes.button, select === 'day' && classes.buttonActive)} onClick={setDayClick}>Сегодня</button>
        <button className={cx(classes.button, select === 'week' && classes.buttonActive)} onClick={setWeekClick}>Неделя</button>
        <button className={cx(classes.button, select === 'month' && classes.buttonActive)} onClick={setMonthClick}>Месяц</button>
      </div>
        {filter.length ? (
            <div className={classes.disputRoot}>
              Споры
            {activeDisputes.map((disput) => (
                <DisputCompletedTasksItem data={disput} key={disput.id} filteredTasks={filter} lawyerId={lawyerId} disputeId={disput.id} />
            ))}      
            Дела
            {cases.map((cas) => (
                <CaseCompletedTasksItem data={cas} key={cas.id} filteredTasks={filter} lawyerId={lawyerId} disputeId={cas.id} />
            ))}  


        </div>) : (
            <div className={classes.alertRoot}>
                <div className={classes.alertText}>
                <GiSadCrab size={50} />
                  <div>
                    Опачки!!! <br />
                    Кажется кое-кто не работает:)
                  </div>
                </div>
            </div>
        )}
        

        
    </div>
  )
}

