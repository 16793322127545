import {FC} from 'react'
import { CaseAdminItemProps } from './interface'
import classes from './caseadminitem.module.scss'
import { useAppSelector } from '../../../store/reduxHooks'
import cx from 'classnames'
import dayjs from 'dayjs';
import { TodoDashboardItem } from '../../MyDashboardUi/TodoDashboardItem'
import { DisputAdminItem } from '../DisputAdminItem'
import { useNavigate } from 'react-router'

export const CaseAdminItem: FC<CaseAdminItemProps> = (props) => {
    const {caseName, caseId, clientId, caseType} = props

    const clients = useAppSelector(state => state.newClients.clients);
    const myClient = clients.find((client) => client.id === clientId)
    const disputes =  useAppSelector(state => state.newDisputes.disputes)
    const checkDisputes = () => {
        if(caseType === 'sudBankrot') {
            const myDisputes = disputes.filter((disput) => disput.caseId === caseId && disput.isActive === true)
            return myDisputes
        } else {
            const myDisputes: never[] = []
            return myDisputes
        }
    }
    const myDisputes = checkDisputes()

    const setTeg = () => {
        if(caseType === 'abonent') {
            let teg = 'Абонентское сопровождение'
            return teg
        }
        if(caseType === 'sudArbitr') {
            let teg = 'Арбитражное судопроизводство'
            return teg
        }
        if(caseType === 'sudSimple') {
            let teg = 'Общая юрисдикция'
            return teg
        }
        if(caseType === 'sudBankrot') {
            let teg = 'Банкротство'
            return teg
        } else {
            let teg = 'Банкротство'
            return teg
        }
    }
    const teg = setTeg()

    const casesTasks = useAppSelector(state => state.newCaseTasks.tasks)

    const myTasks = casesTasks.filter((task) => task.fatherId === caseId)

    const todayMidnight = dayjs().startOf('day').valueOf()

    const twoDaysAgo = todayMidnight - 604800000
    
    const foundedTasks = myTasks.filter((task) => task.completeTime > twoDaysAgo && task.isCompleted === true)
  
    const navigate = useNavigate()

  return (
    <div className={classes.root}>
        <div className={classes.header}>

            <div className={classes.title}>
                <div className={classes.clientTitle} onClick={() => navigate(`/cases/${caseId}`)}>
                    {caseName}
                </div>
                <div className={cx(classes.tagMark, 
                        teg === 'Общая юрисдикция' && classes.tagMarkDelicti,
                        teg === 'Банкротство' && classes.tagMarkBancrot,
                        teg === 'Арбитражное судопроизводство' && classes.tagMarkRealEstate,
                        teg === 'Абонентское сопровождение' && classes.tagMarkGosSpori,
                        )}>
                        {teg}
                    </div>
            </div>

        </div>
        <div className={classes.body}>
            {foundedTasks.length ? (
                <>
                {foundedTasks.map((item) =>
                <TodoDashboardItem key={item.id} data={item}/>
                )}
                </>
            ) : (
                <div className={classes.notWork}>
                    За последнюю неделю работа не велась
                </div>
            )}
            {myDisputes.length ? 
            (<>
            <div className={classes.smallTitle}>
            Споры:
            </div>
            {myDisputes.map((item) => 
                <DisputAdminItem key={item.id} caseId={item.id} clientId={item.clientId} caseName={item.name} />
            )}
            </>) : null
            }
        </div>
    </div>
  )
}
