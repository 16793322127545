import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCqBPytj2gzde5YmEN4yn5d16iC2G7H81E",
//   authDomain: "prototypecrm-917a5.firebaseapp.com",
//   projectId: "prototypecrm-917a5",
//   storageBucket: "prototypecrm-917a5.appspot.com",
//   messagingSenderId: "863386846357",
//   appId: "1:863386846357:web:0d45232392cfbd3d50479c",
//   measurementId: "G-W2WZDD9ZTS"
// };

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
