import {FC, useState} from 'react';
import { UserScreenProps } from './interface';
import classes from './userscreen.module.scss';

import { useAppSelector } from "../../../store/reduxHooks";
import cx from 'classnames'
import { DisputeItem } from '../../DisputesUi/DisputeItem';
import { CaseItem } from '../../CaseUi/CaseItem';
import { BiTask } from 'react-icons/bi';
import {CompletedTasksForUserScreen} from '../CompletedTasksForUserScreen';
import { MdCalendarMonth } from 'react-icons/md';
import { RiCloseFill } from 'react-icons/ri';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { useFindMyUser } from '../../../hooks/useUsers';
import { AiOutlineSwitcher } from 'react-icons/ai';
import { TodoDashboardItem } from '../../MyDashboardUi/TodoDashboardItem';


export const UserScreen: FC<UserScreenProps> = (props) => {
    const {data} = props;
    const {name, surName, fatherName, specs, id} = data;

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)

    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudSimpleCases]
    const myNewCases = cases.filter((cas) => cas.lawyers.includes(id))

    const disputes = useAppSelector(state => state.newDisputes.disputes);
    const activeDisputes = disputes.filter((disput) => disput.isActive === true)
    const myDisputes = activeDisputes.filter((disput) => disput.lawyers.includes(id))
    const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])
    const myCases = sudBancrotCases.filter((cas) => myDisputesIds.includes(cas.id))
    const allMyCases = [...myNewCases, ...myCases]
    const myFilteredCases = allMyCases.filter((cas) => cas.isActive === true)

    const disputTasks = useAppSelector(state => state.newDiputTasks.tasks)
    const caseTasks = useAppSelector(state => state.newCaseTasks.tasks)
    const clientTasks = useAppSelector(state => state.newClientTasks.tasks)
  
    const uncompletedUserDisputTasks = disputTasks.filter((task) => task.isCompleted === false && task.creator === id)
    const uncompletedUserCaseTasks = caseTasks.filter((task) => task.isCompleted === false && task.creator === id)
    const uncompletedUserClientTasks = clientTasks.filter((task) => task.isCompleted === false && task.creator === id)

    const myUser = useFindMyUser()

    const [showCompletedTasks, setShowCompletedTasks] = useState(true)
    const handleOpenCompletedTaskClick = () => setShowCompletedTasks(value => !value)

    const [calendarMenuOpen, setCalendarMenuOpen] = useState(true);
    const handleOpenCalendarClick = () => setCalendarMenuOpen(value => !value)

    require('dayjs/locale/ru')
    dayjs.locale('ru')
    const localizer = dayjsLocalizer(dayjs)

    const myEventsList = useAppSelector(state => state.newEvents.events)

    const customEventList = myEventsList.filter((event) => event.lawyer === id)  

    const myHandleClick = () =>  {
        return true  
    }


  return (
    <div className={classes.root}>
        
        {/* Завершенные задачи */}
        <div className={cx(classes.popUp, showCompletedTasks === true && classes.popUpClose)} >
            <div className={classes.taskMenu} onClick={e => e.stopPropagation()}>
                <CompletedTasksForUserScreen handleOpenTaskInfoClick={handleOpenCompletedTaskClick} lawyerId={id} />
            </div>
        </div>

        {/* Открыть календарь */}
        <div className={cx(classes.popUp, calendarMenuOpen === true && classes.popUpClose)}>
            <div className={cx(classes.calendarMenu, customEventList.length === 0 && classes.clandarSmallMenu)} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenCalendarClick}>
                    <RiCloseFill size={22} />
                </button>
                {customEventList.length ? (
                    <Calendar
                    localizer={localizer}
                    events={customEventList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 'calc(100% - 50px)'}}
                    views={{
                        month: true, 
                    }}
                    messages={{
                        month: 'Месяц',
                        work_week: 'Рабочая неделя',
                        date: 'Дата',
                        time: 'Время',
                        event: 'Событие',
                        previous: 'Назад',
                        next: 'Вперед',
                        today: 'Сегодня',
                        noEventsInRange: 'В указанный период заседания отсутствуют'
                    }}
                    />) : (
                        <div className={classes.alertMenu}>
                            <div className={classes.alertText}>
                                <FaRegCalendarTimes size={50} />
                                <div className={classes.alertTitle}>
                                    Судебных заседаний на ближайший месяц нет                           
                                </div>
                            </div>

                        </div>
                    )               
                }
            </div>
        </div>

        <div className={classes.header}>
            <div className={classes.avatar}></div>
            <div>
                <div className={classes.name}>{surName} {name} {fatherName}</div>
                <div className={cx(classes.specsSud, 
                    specs === 'IT-отдел' && classes.specsIt,
                    specs === 'CEO' && classes.specsCeo,
                    specs === 'Ассистент CEO' && classes.specsCeo,
                    specs === 'Бухгалтер' && classes.specsCeo,
                    specs === 'Отдел консалтинга' && classes.specsAbonent,
                    specs === 'Оценщик' && classes.specsOcenka,
                    specs === 'Кадастровый отдел' && classes.specsKadastr)}
                >
                    {specs}
                </div>
                <div className={classes.buttonBox}>
                    <div className={classes.circleButton}>
                        <BiTask onClick={handleOpenCompletedTaskClick} size={22} title='Завершенные задачи'/>
                    </div>
                    <div className={classes.circleButton}>
                        <MdCalendarMonth size={22} title='Календарь' onClick={handleOpenCalendarClick}/>
                    </div>
                    {myUser === 'FGNTMtdZJuZ5TinR9sJHrf5nPED2' && id === 'FGNTMtdZJuZ5TinR9sJHrf5nPED2' ? (
                        <div className={classes.circleButton}>
                        <AiOutlineSwitcher size={22} onClick={myHandleClick} title='Закрыть доступ'/>
                        </div>                    
                    ) : null}
                </div>
            </div>        
        </div>
        <div className={classes.content}>
            <div className={classes.cases}>
  
                             
                    <div className={classes.title}>Дела</div> 
                        <div className={cx(classes.scrollList, !myDisputes.length && classes.scrollListFull)}>
                            {myFilteredCases.map((cas) => (
                            <CaseItem data={cas} key={cas.id} caseId={cas.id} />  
                        ))}
                        </div>

                        {myDisputes.length ? (
                            <>
                            <div className={classes.title}>Обособленные споры</div>    
                            <div className={classes.scrollList}>
                                {myDisputes.map((cas) => (
                                    <DisputeItem disputeId={cas.id} data={cas} key={cas.id} />  
                                ))} 
                            </div>
                            </>
                        ) : null}
 
            </div>
            
            <div className={classes.todos}>
                <div className={classes.title}>Задачи</div>  
                <div className={classes.todosRoot}>
                    {uncompletedUserDisputTasks.length ? (<>
                        <div className={classes.smallTitle}>Споры</div>   
                            <div className={classes.todosScrollBar}>
                                {uncompletedUserDisputTasks.map((todo) => (
                                    <TodoDashboardItem data={todo} key={todo.id} />
                                ))}
                            </div>

                    </>) : null}
                    {uncompletedUserCaseTasks.length ? (<>
                        <div className={classes.smallTitle}>Дела</div>   
                            <div className={classes.todosScrollBar}>
                                {uncompletedUserCaseTasks.map((todo) => (
                                    <TodoDashboardItem data={todo} key={todo.id} />
                                ))}                        
                            </div>

                    </>) : null}
                    {uncompletedUserClientTasks.length ? (<>
                        <div className={classes.smallTitle}>Клиенты</div>
                            <div className={classes.todosScrollBar}>
                                {uncompletedUserClientTasks.map((todo) => (
                                    <TodoDashboardItem data={todo} key={todo.id} />
                                ))}                        
                            </div>
                    </>) : null}
                </div>
                
            </div>
        </div>
    </div>
  )
}
