import  {FC, useState, useEffect} from "react";
import { CalendarProps } from "./interface";
import classes from './calendar.module.scss';
import { Calendar, dayjsLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import dayjs from 'dayjs';
import { useFindMyUser } from "../../hooks/useUsers";
import Select from 'react-select'
import { useAppDispatch, useAppSelector } from "../../store/reduxHooks";
import { RiCloseFill } from "react-icons/ri";
import cx from 'classnames'
import AddEventForm from "../../components/AddEventForm";
import { BigCalendarEventComponent } from "../../components/BigCalendarEventComponent";
import { returnInterface } from "../../store/newSlices/calendarEventShowInterface";
import { EventPopUp } from "../../ui/CalendarUi/EventPopUp";
import { useAuth } from "../../hooks/useAuth";

require('dayjs/locale/ru')
dayjs.locale('ru')
const localizer = dayjsLocalizer(dayjs)

export const Calender: FC<CalendarProps> = (props) => {

  const {isAuth} = useAuth()

  useEffect(() => {
    if(!isAuth) {
      window.location.assign('https://legal-crm.ru/')
    }
  })

  const myUser = useFindMyUser()

  const disputes = useAppSelector(state => state.newDisputes.disputes)
  const activeDisputes = disputes.filter((disput) => disput.isActive === true)

  const clientsOptions = activeDisputes.map(item => {
    const disput = {
      value: '',
      label: ''
    }
    disput.value = item.id
    disput.label = item.name
    return disput
  })
  
  const users = useAppSelector(state => state.users.users)
  const usersOptions = users.map(item => {
    const user = {
      value: '',
      label: ''
    }
    user.value = item.id
    user.label = item.label
    return user
  })

  const myEventsList = useAppSelector(state => state.newEvents.events)
  const newMyEventList = myEventsList.map(item => {
    const event = {
      id: '',
      title: '',
      start: new Date(),
      end: new Date(),
      lawyer: '',
      disputId: '',
      isVisited: false,
      conclusion: ''    
    }
    event.id = item.id
    event.title = item.title
    event.start = new Date(`${item.start}`)
    event.end = new Date(`${item.end}`)
    event.lawyer = item.lawyer
    event.disputId = item.disputId
    event.isVisited = item.isVisited
    event.conclusion = item.conclusion
    return event
  })

  let [customEventList, setCustomEventList] = useState(newMyEventList) 
  const myUserEventList = newMyEventList.filter((events) => events.lawyer === myUser)
  const [client, setClient] = useState<any>([] as any)
  const [user, setUser] = useState<any>([] as any)


  const myEventsClick = () => {
    setCustomEventList(myUserEventList)
    return customEventList
  }

  const allEventsClick = () => {
    setCustomEventList(newMyEventList)
    return customEventList
  }

  const filterEventListClick = () => {
    if(client.value !== undefined && user.value !== undefined) {
      const filteredEventList = myEventsList.filter(event => event.disputId === client.value && event.lawyer === user.value)
      setCustomEventList(filteredEventList)   
      return customEventList
    } 
    if (client.value !== undefined && user.value === undefined) {
      const filteredEventList = myEventsList.filter(event => event.disputId === client.value)
      setCustomEventList(filteredEventList)   
      return customEventList
    }
    if (client.value === undefined && user.value !== undefined) {
      const filteredEventList = myEventsList.filter(event => event.lawyer === user.value)
      setCustomEventList(filteredEventList)   
      return customEventList
    }
    else {
      const filteredEventList = myEventsList
      setCustomEventList(filteredEventList)
      return customEventList
    }
  }

  const [openEventMenu, setOpenEventMenu] = useState(true)
  const handleOpenAddEventMenu = () => setOpenEventMenu(value => !value)
  

  const dispatch = useAppDispatch()
  const eventPopUpForm = useAppSelector(state => state.newCalendarChange.myId)


  const handleCheck = () => {
    dispatch(returnInterface())
  }


  return (
    <div className={classes.magicCalendar}>

      {/* PopUp info */}
      <div className={cx(classes.popUp, eventPopUpForm === null && classes.popUpClose)}>
        <div className={classes.moreEventInfoRoot} onClick={e => e.stopPropagation()}>
          <div className={classes.buttonRootForm}>
            <button className={classes.icon} onClick={handleCheck}>
                <RiCloseFill size={22} />
            </button>    
          </div>
          <div>
          <EventPopUp eventPopUpForm={eventPopUpForm}  />
          </div>
        </div>
      </div>
      
      {/* Добавить заседание */}
      <div className={cx(classes.popUp, openEventMenu === true && classes.popUpClose)}>
        <div className={classes.form} onClick={e => e.stopPropagation()}>
            <button className={classes.closeIcon} onClick={handleOpenAddEventMenu}>
                <RiCloseFill size={22} />
            </button>          
            <AddEventForm myUser={myUser} handleClick={handleOpenAddEventMenu} />
        </div>
      </div>

      <div className={classes.buttonRoot}>
        <div className={classes.buttonBox}>
            <button className={classes.button} onClick={myEventsClick}>
              Мои заседания
            </button>
              <Select
              isClearable={false}
              className={classes.select}
              classNamePrefix="select"
              options={clientsOptions}
              onChange={(chioce) => setClient(chioce)} 
              defaultValue={{label: 'Спор', value: ''}}
              />
              <Select
              isClearable={false}
              className={classes.select}
              classNamePrefix="select"
              options={usersOptions}
              onChange={(chioce) => setUser(chioce)} 
              defaultValue={{label: 'Сотрудник', value: ''}}
              />
              <button className={classes.button} onClick={filterEventListClick}>
                Выбрать
              </button>
              <button className={classes.button} onClick={allEventsClick}>
                Сброс
            </button>        
          </div>
          <button className={classes.addButton} onClick={handleOpenAddEventMenu}>
            Добавить заседание
          </button>
      </div>
      <Calendar
      localizer={localizer}
      events={customEventList}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 'calc(100vh - 120px)'}}
      views={{
        month: true, 
        agenda: true,
        day: true,
      }}
      components={{
        eventWrapper: BigCalendarEventComponent,
      }}
      messages={{
        month: 'Месяц',
        work_week: 'Рабочая неделя',
        day: 'День',
        agenda: 'Cводка',
        date: 'Дата',
        time: 'Время',
        event: 'Заседание',
        previous: 'Назад',
        next: 'Вперед',
        today: 'Сегодня',
        noEventsInRange: 'В указанный период заседания отсутствуют'
      }}
    />
    </div>
  )
}
