import {createSlice} from '@reduxjs/toolkit';
import { AbonentCase } from '../../api/interface';

interface KadastrCasesState {
    cases: AbonentCase[]
}
const initialState: KadastrCasesState = {
    cases: []
}

export const newKadastrCasesSlice = createSlice({
    name: 'newKadastrCases',
    initialState,
    reducers: {
        getNewKadastrCases(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewKadastrCases
} = newKadastrCasesSlice.actions
export default newKadastrCasesSlice.reducer