import { useCallback } from "react"
import { useGetNewAbonentCase, useGetNewArbitrCase, useGetNewBancrotCase, useGetNewCases, useGetNewKadastrCase, useGetNewOtcenkaCase, useGetNewSimpleCase } from "./useGetDocs"
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore"
import { db } from "../firebase.config"
import { v4 as uuid } from 'uuid';
import { AbonentCase, Case, SudBancrotCase, SudCase } from "../api/interface";
import dayjs from 'dayjs';
import { useAppSelector } from "../store/reduxHooks";

export function useHandleAddNewCases(
    clientId: string,
    сaseName: string,
    myCaseTag: string,
    caseNumber: string,
    customCaseType: string,
    myUser: string
    ) {

    const UniqeId = uuid()
    const getAbonent = useGetNewAbonentCase()
    const getKadastr = useGetNewKadastrCase()
    const getOtcenka = useGetNewOtcenkaCase()
    const getArbitr = useGetNewArbitrCase()
    const getBancrot = useGetNewBancrotCase()
    const getSimple = useGetNewSimpleCase()
    
    const formattedDate = dayjs().valueOf()

    return useCallback(() =>{
        if(customCaseType === 'otcenka') {
            const newCase = {
                id: UniqeId,
                name: сaseName,
                isActive: true,
                isCompleted: false,
                isArchived: false,
                isDeleted: false,
                annotation: 'Здесь мы рассказываем о деле',
                plan: 'Здесь мы рассказываем как будем действовать',
                conclusion: '',
                clientId: clientId,
                teg: myCaseTag,
                caseType: customCaseType,
                createTime: formattedDate,
                lawyers: [myUser]
            }   
            updateDoc(doc(db, "newCases", "otcenkaCases"),{
                cases: arrayUnion(newCase)
            }).then(getOtcenka)     
        }
        if(customCaseType === 'kadastr') {
            const newCase = {
                id: UniqeId,
                name: сaseName,
                isActive: true,
                isCompleted: false,
                isArchived: false,
                isDeleted: false,
                annotation: 'Здесь мы рассказываем о деле',
                plan: 'Здесь мы рассказываем как будем действовать',
                conclusion: '',
                clientId: clientId,
                teg: myCaseTag,
                caseType: customCaseType,
                createTime: formattedDate,
                lawyers: [myUser]
            }   
            updateDoc(doc(db, "newCases", "kadastrCases"),{
                cases: arrayUnion(newCase)
            }).then(getKadastr)     
        }
        if(customCaseType === 'abonent') {
            const newCase = {
                id: UniqeId,
                name: сaseName,
                isActive: true,
                isCompleted: false,
                isArchived: false,
                isDeleted: false,
                annotation: 'Здесь мы рассказываем о деле',
                plan: 'Здесь мы рассказываем как будем действовать',
                conclusion: '',
                clientId: clientId,
                teg: myCaseTag,
                caseType: customCaseType,
                createTime: formattedDate,
                lawyers: [myUser]
            }   
            updateDoc(doc(db, "newCases", "abonentCases"),{
                cases: arrayUnion(newCase)
            }).then(getAbonent)     
        }

        if(customCaseType === 'sudArbitr') {
            const newCase = {
                id: UniqeId,
                name: сaseName,
                isActive: true,
                isCompleted: false,
                isArchived: false,
                isDeleted: false,
                annotation: 'Здесь мы рассказываем о деле',
                plan: 'Здесь мы рассказываем как будем действовать',
                conclusion: '',
                clientId: clientId,
                teg: myCaseTag,
                caseType: customCaseType,
                createTime: formattedDate,
                lawyers: [myUser],
                number: caseNumber,
            }   
            updateDoc(doc(db, "newCases", "sudArbitrCases"),{
                cases: arrayUnion(newCase)
            }).then(getArbitr)     
        }

        if(customCaseType === 'sudSimple') {
            const newCase = {
                id: UniqeId,
                name: сaseName,
                isActive: true,
                isCompleted: false,
                isArchived: false,
                isDeleted: false,
                annotation: 'Здесь мы рассказываем о деле',
                plan: 'Здесь мы рассказываем как будем действовать',
                conclusion: '',
                clientId: clientId,
                teg: myCaseTag,
                caseType: customCaseType,
                createTime: formattedDate,
                lawyers: [myUser],
                number: caseNumber,
            }   
            updateDoc(doc(db, "newCases", "sudSimpleCases"),{
                cases: arrayUnion(newCase)
            }).then(getSimple)     
        }

        if(customCaseType === 'sudBancrot') {
            const newCase = {
                id: UniqeId,
                name: сaseName,
                isActive: true,
                isCompleted: false,
                isArchived: false,
                isDeleted: false,
                annotation: 'Здесь мы рассказываем о деле',
                plan: 'Здесь мы рассказываем как будем действовать',
                conclusion: '',
                clientId: clientId,
                teg: 'Банкротство',
                caseType: customCaseType,
                createTime: formattedDate,
                number: caseNumber,
            }   
            updateDoc(doc(db, "newCases", "sudBancrotCases"),{
                cases: arrayUnion(newCase)
            }).then(getBancrot)     
        }


    }, [customCaseType, UniqeId, сaseName, clientId, myCaseTag, formattedDate, myUser, getOtcenka, getKadastr, getAbonent, caseNumber, getArbitr, getSimple, getBancrot])
};

export function useHandleArchiveNewCases(
    data: Case
) {
    const getMyDocs = useGetNewCases();
    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: false,
            isArchived: true,
            isDeleted: false,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            teg: data.teg,
            number: data.number,
            caseType: data.caseType,
            fixer: data.fixer,
            createTime: data.createTime        
        }
        updateDoc(doc(db, "newCases", "cases"), {
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "cases"), {
            cases: arrayUnion(newCase)
        }).then(getMyDocs)
    }, [data, getMyDocs])
};

export function useHandleDeleteAbonentNewCases(
    data: AbonentCase
) {
    const getAbonent = useGetNewAbonentCase()
    const getKadastr = useGetNewKadastrCase()
    const getOtcenka = useGetNewOtcenkaCase()

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const abonentCasesIds = abonentCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const kadastrCasesIds = kadastrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const otcenkaCasesIds = otcenkaCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])


    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: false,
            isArchived: false,
            isDeleted: true,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            lawyers: data.lawyers
        }
        if(abonentCasesIds.includes(data.id)) {
            updateDoc(doc(db, "newCases", "abonentCases"), {
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "abonentCases"), {
                cases: arrayUnion(newCase)
            }).then(getAbonent)
        }
        if(kadastrCasesIds.includes(data.id)) {
            updateDoc(doc(db, "newCases", "kadastrCases"), {
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "kadastrCases"), {
                cases: arrayUnion(newCase)
            }).then(getKadastr)
        }
        if(otcenkaCasesIds.includes(data.id)) {
            updateDoc(doc(db, "newCases", "otcenkaCases"), {
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "otcenkaCases"), {
                cases: arrayUnion(newCase)
            }).then(getOtcenka)
        }

    }, [abonentCasesIds, data, getAbonent, getKadastr, getOtcenka, kadastrCasesIds, otcenkaCasesIds])
}

export function useHandleDeleteSudNewCases(
    data: SudCase
) {
    const getArbitr = useGetNewArbitrCase()
    const getSimple = useGetNewSimpleCase()

    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const sudSimpleCasesIds = sudSimpleCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudArbitrCasesIds = sudArbitrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: false,
            isArchived: false,
            isDeleted: true,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            lawyers: data.lawyers,
            number: data.number
        }
        if(sudSimpleCasesIds.includes(data.id)){
            updateDoc(doc(db, "newCases", "sudSimpleCases"), {
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "sudSimpleCases"), {
                cases: arrayUnion(newCase)
            }).then(getSimple)
        }
        if(sudArbitrCasesIds.includes(data.id)){
            updateDoc(doc(db, "newCases", "sudArbitrCases"), {
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "sudArbitrCases"), {
                cases: arrayUnion(newCase)
            }).then(getArbitr)
        }
    }, [data, getArbitr, getSimple, sudArbitrCasesIds, sudSimpleCasesIds])
}

export function useHandleDeleteSudBancrotNewCases(
    data: SudBancrotCase
) {
    const getBancrot = useGetNewBancrotCase()
    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: false,
            isArchived: false,
            isDeleted: true,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            number: data.number,
        }   
        updateDoc(doc(db, "newCases", "sudBancrotCases"), {
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "sudBancrotCases"), {
            cases: arrayUnion(newCase)
        }).then(getBancrot)

}, [data, getBancrot])
}

export function useHandleFixCase(
    data: Case,
    myCaseTag: string,
    myUser: string,
    formattedDate: number
) {
    const getMyDocs = useGetNewCases();
    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: data.isActive,
            isCompleted: data.isCompleted,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            teg: data.teg,
            number: data.number,
            caseType: myCaseTag,
            fixer: myUser,
            createTime: formattedDate
        }
        updateDoc(doc(db, "newCases", "cases"), {
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "cases"), {
            cases: arrayUnion(newCase)
        }).then(getMyDocs)
    }, [data, getMyDocs, myCaseTag, myUser, formattedDate])
}

export function useHandleChangeNewCase(
    data: Case,
    caseName: string,
    caseNumber: string
) {
    const getMyDocs = useGetNewCases();
    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: caseName,
            isActive: data.isActive,
            isCompleted: data.isCompleted,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            teg: data.teg,
            number: caseNumber,
            caseType: data.caseType,
            fixer: data.fixer,
            createTime: data.createTime
        }
        updateDoc(doc(db, "newCases", "cases"), {
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "cases"), {
            cases: arrayUnion(newCase)
        }).then(getMyDocs)
    }, [data, caseName, caseNumber, getMyDocs])
}

export function useHandleAddLawyerToCase(
    id: string,
    addUserValue: string
) {
    const getAbonent = useGetNewAbonentCase()
    const getKadastr = useGetNewKadastrCase()
    const getOtcenka = useGetNewOtcenkaCase()
    const getArbitr = useGetNewArbitrCase()
    const getSimple = useGetNewSimpleCase()

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const abonentCasesIds = abonentCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const simpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const simpleCasesIds = simpleCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const arbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const arbitrCasesIds = arbitrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    return useCallback(() =>{
        if(abonentCasesIds.includes(id)) {
            const oldCase = abonentCases.find((cas) => cas.id === id)
            const checkNewCase = () => {
                if(oldCase) {
                    const newCase = {
                        id: oldCase.id,
                        name: oldCase.name,
                        isActive: oldCase.isActive,
                        isCompleted: oldCase.isCompleted,
                        isArchived: oldCase.isArchived,
                        isDeleted: oldCase.isDeleted,
                        annotation: oldCase.annotation,
                        plan: oldCase.plan,
                        conclusion: oldCase.conclusion,
                        clientId: oldCase.clientId,
                        teg: oldCase.teg,
                        caseType: oldCase.caseType,
                        createTime: oldCase.createTime,
                        lawyers: [...oldCase.lawyers, addUserValue]
                    }  
                    return newCase          
                }            
            }
            const newCase = checkNewCase()
            updateDoc(doc(db, "newCases", "abonentCases"),{
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "abonentCases"),{
                cases: arrayUnion(newCase)
            }).then(getAbonent)     
        }

        if(simpleCasesIds.includes(id)) {
            const oldCase = simpleCases.find((cas) => cas.id === id)
            const checkNewCase = () => {
                if(oldCase) {
                    const newCase = {
                        id: oldCase.id,
                        name: oldCase.name,
                        isActive: oldCase.isActive,
                        isCompleted: oldCase.isCompleted,
                        isArchived: oldCase.isArchived,
                        isDeleted: oldCase.isDeleted,
                        annotation: oldCase.annotation,
                        plan: oldCase.plan,
                        conclusion: oldCase.conclusion,
                        clientId: oldCase.clientId,
                        teg: oldCase.teg,
                        caseType: oldCase.caseType,
                        createTime: oldCase.createTime,
                        lawyers: [...oldCase.lawyers, addUserValue],
                        number: oldCase.number
                    }  
                    return newCase          
                }            
            }
            const newCase = checkNewCase()
            updateDoc(doc(db, "newCases", "sudSimpleCases"),{
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "sudSimpleCases"),{
                cases: arrayUnion(newCase)
            }).then(getSimple)     
        }

        if(arbitrCasesIds.includes(id)) {
            const oldCase = arbitrCases.find((cas) => cas.id === id)
            const checkNewCase = () => {
                if(oldCase) {
                    const newCase = {
                        id: oldCase.id,
                        name: oldCase.name,
                        isActive: oldCase.isActive,
                        isCompleted: oldCase.isCompleted,
                        isArchived: oldCase.isArchived,
                        isDeleted: oldCase.isDeleted,
                        annotation: oldCase.annotation,
                        plan: oldCase.plan,
                        conclusion: oldCase.conclusion,
                        clientId: oldCase.clientId,
                        teg: oldCase.teg,
                        caseType: oldCase.caseType,
                        createTime: oldCase.createTime,
                        lawyers: [...oldCase.lawyers, addUserValue],
                        number: oldCase.number
                    }  
                    return newCase          
                }            
            }
            const newCase = checkNewCase()
            updateDoc(doc(db, "newCases", "sudArbitrCases"),{
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "sudArbitrCases"),{
                cases: arrayUnion(newCase)
            }).then(getArbitr)     
        }


    }, [abonentCasesIds, id, simpleCasesIds, arbitrCasesIds, abonentCases, getAbonent, addUserValue, simpleCases, getSimple, arbitrCases, getArbitr])
}

export function useHandleRemoveLawyerToCase(
    id: string,
    newLawyers: string[]
) {
    const getAbonent = useGetNewAbonentCase()
    const getKadastr = useGetNewKadastrCase()
    const getOtcenka = useGetNewOtcenkaCase()
    const getArbitr = useGetNewArbitrCase()
    const getSimple = useGetNewSimpleCase()

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const abonentCasesIds = abonentCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const simpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const simpleCasesIds = simpleCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const arbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const arbitrCasesIds = arbitrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    return useCallback(() =>{
        if(abonentCasesIds.includes(id)) {
            const oldCase = abonentCases.find((cas) => cas.id === id)
            const checkNewCase = () => {
                if(oldCase) {
                    const newCase = {
                        id: oldCase.id,
                        name: oldCase.name,
                        isActive: oldCase.isActive,
                        isCompleted: oldCase.isCompleted,
                        isArchived: oldCase.isArchived,
                        isDeleted: oldCase.isDeleted,
                        annotation: oldCase.annotation,
                        plan: oldCase.plan,
                        conclusion: oldCase.conclusion,
                        clientId: oldCase.clientId,
                        teg: oldCase.teg,
                        caseType: oldCase.caseType,
                        createTime: oldCase.createTime,
                        lawyers: newLawyers,
                    }  
                    return newCase          
                }            
            }
            const newCase = checkNewCase()
            updateDoc(doc(db, "newCases", "abonentCases"),{
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "abonentCases"),{
                cases: arrayUnion(newCase)
            }).then(getAbonent)     
        }

        if(simpleCasesIds.includes(id)) {
            const oldCase = simpleCases.find((cas) => cas.id === id)
            const checkNewCase = () => {
                if(oldCase) {
                    const newCase = {
                        id: oldCase.id,
                        name: oldCase.name,
                        isActive: oldCase.isActive,
                        isCompleted: oldCase.isCompleted,
                        isArchived: oldCase.isArchived,
                        isDeleted: oldCase.isDeleted,
                        annotation: oldCase.annotation,
                        plan: oldCase.plan,
                        conclusion: oldCase.conclusion,
                        clientId: oldCase.clientId,
                        teg: oldCase.teg,
                        caseType: oldCase.caseType,
                        createTime: oldCase.createTime,
                        lawyers: newLawyers,
                        number: oldCase.number
                    }  
                    return newCase          
                }            
            }
            const newCase = checkNewCase()
            updateDoc(doc(db, "newCases", "sudSimpleCases"),{
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "sudSimpleCases"),{
                cases: arrayUnion(newCase)
            }).then(getSimple)     
        }

        if(arbitrCasesIds.includes(id)) {
            const oldCase = arbitrCases.find((cas) => cas.id === id)
            const checkNewCase = () => {
                if(oldCase) {
                    const newCase = {
                        id: oldCase.id,
                        name: oldCase.name,
                        isActive: oldCase.isActive,
                        isCompleted: oldCase.isCompleted,
                        isArchived: oldCase.isArchived,
                        isDeleted: oldCase.isDeleted,
                        annotation: oldCase.annotation,
                        plan: oldCase.plan,
                        conclusion: oldCase.conclusion,
                        clientId: oldCase.clientId,
                        teg: oldCase.teg,
                        caseType: oldCase.caseType,
                        createTime: oldCase.createTime,
                        lawyers: newLawyers,
                        number: oldCase.number
                    }  
                    return newCase          
                }            
            }
            const newCase = checkNewCase()
            updateDoc(doc(db, "newCases", "sudArbitrCases"),{
                cases: arrayRemove(oldCase)
            })
            updateDoc(doc(db, "newCases", "sudArbitrCases"),{
                cases: arrayUnion(newCase)
            }).then(getArbitr)     
        }


    }, [abonentCasesIds, id, simpleCasesIds, arbitrCasesIds, abonentCases, getAbonent, newLawyers, simpleCases, getSimple, arbitrCases, getArbitr])
}

export function useHandleCompleteNewAbonentCase(
    data: any,
    inputValue: string
) {
    const getAbonent = useGetNewAbonentCase()

    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: true,
            isArchived: false,
            isDeleted: false,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: inputValue,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            lawyers: data.lawyers
        }
        updateDoc(doc(db, "newCases", "abonentCases"),{
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "abonentCases"),{
            cases: arrayUnion(newCase)
        }).then(getAbonent) 
    }, [data, getAbonent, inputValue])
}

export function useHandleCompleteNewKadastrCase(
    data: any,
    inputValue: string
) {
    const getKadastr = useGetNewKadastrCase()

    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: true,
            isArchived: false,
            isDeleted: false,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: inputValue,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            lawyers: data.lawyers
        }
        updateDoc(doc(db, "newCases", "kadastrCases"),{
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "kadastrCases"),{
            cases: arrayUnion(newCase)
        }).then(getKadastr) 
    }, [data, getKadastr, inputValue])
}

export function useHandleCompleteNewOtcenkaCase(
    data: any,
    inputValue: string
) {
    const getOtcenka = useGetNewOtcenkaCase()

    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: true,
            isArchived: false,
            isDeleted: false,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: inputValue,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            lawyers: data.lawyers
        }
        updateDoc(doc(db, "newCases", "otcenkaCases"),{
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "otcenkaCases"),{
            cases: arrayUnion(newCase)
        }).then(getOtcenka) 
    }, [data, getOtcenka, inputValue])
}

export function useHandleCompleteNewSudArbitrCase(
    data: any,
    inputValue: string
) {
    const getArbitr = useGetNewArbitrCase()

    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: true,
            isArchived: false,
            isDeleted: false,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: inputValue,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            lawyers: data.lawyers,
            number: data.number
        }
        updateDoc(doc(db, "newCases", "sudArbitrCases"),{
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "sudArbitrCases"),{
            cases: arrayUnion(newCase)
        }).then(getArbitr) 
    }, [data, getArbitr, inputValue])
}

export function useHandleCompleteNewSudSimpleCase(
    data: any,
    inputValue: string
) {
    const getSimple = useGetNewSimpleCase()

    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: true,
            isArchived: false,
            isDeleted: false,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: inputValue,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            lawyers: data.lawyers,
            number: data.number
        }
        updateDoc(doc(db, "newCases", "sudSimpleCases"),{
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "sudSimpleCases"),{
            cases: arrayUnion(newCase)
        }).then(getSimple) 
    }, [data, getSimple, inputValue])
}

export function useHandleCompleteNewSudBancrotCase(
    data: any,
    inputValue: string
) {
    const getBancrot = useGetNewBancrotCase()

    return useCallback(() => {
        const oldCase = data
        const newCase = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: true,
            isArchived: false,
            isDeleted: false,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: inputValue,
            clientId: data.clientId,
            teg: data.teg,
            caseType: data.caseType,
            createTime: data.createTime,
            number: data.number
        }
        updateDoc(doc(db, "newCases", "sudBancrotCases"),{
            cases: arrayRemove(oldCase)
        })
        updateDoc(doc(db, "newCases", "sudBancrotCases"),{
            cases: arrayUnion(newCase)
        }).then(getBancrot) 
    }, [data, getBancrot, inputValue])
}


