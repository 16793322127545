import {createSlice} from '@reduxjs/toolkit';
import { Mission } from '../../api/interface';

interface AdminTasksState {
    tasks: Mission[]
}
const initialState: AdminTasksState = {
    tasks: []
}

export const newAdminTasksSlice = createSlice({
    name: 'newAdminTasks',
    initialState,
    reducers: {
        getNewAdminTasks(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewAdminTasks
} = newAdminTasksSlice.actions
export default newAdminTasksSlice.reducer