import {FC, useState} from 'react';
import { ClientScreenProps } from './interface';
import classes from './clientscreen.module.scss';
import {FiPhone} from 'react-icons/fi'
import { CaseItem } from '../../CaseUi/CaseItem';
import { ContactCard } from '../../ContactCard';
import {RiCloseFill} from 'react-icons/ri';
import cx from 'classnames';
import { AddCaseForm } from '../../../components/AddCaseForm';
import {BsPlusCircleFill, BsFolderPlus} from 'react-icons/bs'
import AddContactForm from '../AddContactForm';
import { ButtonClientBox } from '../ButtonClientBox';
// import { ChangeClientForm } from '../ChangeClientForm';
import { useNavigate } from 'react-router';
// import { useHandleUpdateCases } from '../../../hooks/useCase';
import { useAppSelector } from '../../../store/reduxHooks';
import { AddTodoComponent } from '../../../components/AddTodoComponent';
import { useHandleArchiveNewClients, useHandleDeleteNewClients } from '../../../hooks/useNewClients';
import { AddDocumentComponent } from '../../../components/AddDocumentComponent';
import { DocumentComponent } from '../DocumentComponent';
import { TodoItem } from '../TodoItem/MainTodoItem';

export const ClientScreen: FC<ClientScreenProps> = (props) => {
    const {data} = props;
    const {name, phone, inn, ogrn, kpp, id} = data;
    const clientId = id;

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudSimpleCases, ...sudBancrotCases]

    const myCases = cases.filter((cas) => cas.clientId === clientId)
    const activeCases = myCases.filter((cas) => cas.isActive === true)

    const tasks = useAppSelector(state => state.newClientTasks.tasks)
    const completedTasks = tasks.filter(i => i.isCompleted === true && i.fatherId === id);
    const arrayForSort = [...completedTasks]
    const finalArray = arrayForSort.sort((a, b) => a.completeTime < b.completeTime ? 1 : -1)
  
    const uncompletedTasks = tasks.filter(i => i.isCompleted === false && i.fatherId === id);

    const navigate = useNavigate()

    const [open, setOpen] = useState(true);
    const handleCheck = () => setOpen(value => !value);

    const [contactsOpen, setContactsOpen] = useState(true);
    const handleContactClick = () => setContactsOpen(value => !value)

    const [openClientArchiveMenu, setOpenClientArchiveMenu] = useState(true)
    const handleOpenArchiveMenuClick = () => setOpenClientArchiveMenu(value => !value)

    const archivedClient = useHandleArchiveNewClients(data)
    const handleArchiveClientClick = () => {
        archivedClient()        
        handleOpenArchiveMenuClick()
        navigate('/clients')
    }

    const [openClientDeleteMenu, setOpenClientDeleteMenu] = useState(true)
    const handleOpenClientDeleteMenuClick = () => setOpenClientDeleteMenu(value => !value)
    
    const deleteClient = useHandleDeleteNewClients(data)
    const handleDeleteClientClick = () => {
        deleteClient()
        handleOpenClientDeleteMenuClick()
        navigate('/clients')
    }

    // const [openClientChangeMenu, setOpenClientChangeMenu] = useState(true)
    const handleOpenChangeClientMenuClick = () => alert('Функция находится в разработке')
    // const handleOpenChangeClientMenuClick = () => setOpenClientChangeMenu(value => !value)

    const allContacts = useAppSelector(state => state.newContacts.contacts)
    const contacts = allContacts.filter((contact) => contact.clientId === id)

    const allDocs = useAppSelector((state) => state.newDocuments.docs)
    const myDocs = allDocs.filter((docs) => docs.clientId === clientId)

    const magicSBISClick = () => {
        // window.open(`https://saby.ru/profile/page/company-list?search=${inn}`)
        window.open(`https://saby.ru/profile/${inn}`)
    }

    const magicNalogClick = () => {
        window.open(`https://bo.nalog.ru/search?query=${inn}&page=1`)
    }
    
  return (
    <div className={classes.root}>
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleCheck}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCheck}>
                    <RiCloseFill size={22} />
                </button>
                <AddCaseForm clientId={clientId} clientName={name} handleClose={handleCheck} />
            </div>
        </div>

        <div className={cx(classes.popUp, openClientArchiveMenu === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
            <div className={classes.clientInfoMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenArchiveMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                    Вы уверены что хотите перенести в архив клиента {name}?
                <div className={classes.deleteButtons}>
                    <button className={classes.buttonComplete} onClick={handleArchiveClientClick}>Да</button> <button className={classes.buttonComplete} onClick={handleOpenArchiveMenuClick}>Нет</button>
                </div>
                
            </div>
        </div>

        <div className={cx(classes.popUp, openClientDeleteMenu === true && classes.popUpClose)} onClick={handleOpenClientDeleteMenuClick}>
            <div className={classes.clientInfoMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenClientDeleteMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                    Вы уверены что хотите удалить клиента {name}?
                <div className={classes.deleteButtons}>
                    <button className={classes.buttonComplete} onClick={handleDeleteClientClick}>Да</button> <button className={classes.buttonComplete} onClick={handleOpenClientDeleteMenuClick}>Нет</button>
                </div>
            </div>
        </div>

        {/* <div className={cx(classes.popUp, openClientChangeMenu === true && classes.popUpClose)} onClick={handleOpenChangeClientMenuClick}>
            <div className={classes.changeClientForm} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenChangeClientMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                <ChangeClientForm data={data} id={id} handleClose={handleOpenChangeClientMenuClick} />
            </div>
        </div> */}

      <div className={cx(classes.popUp, contactsOpen === true && classes.popUpClose)} onClick={handleContactClick}>
        <div className={classes.contactForm} onClick={e => e.stopPropagation()}>
        <button className={classes.closeIcon} onClick={handleContactClick}>
                <RiCloseFill size={22} />
            </button>
           <AddContactForm clientId={id} handleClose={handleContactClick} />
        </div>
      </div>

        <div className={classes.header}>
            <div className={classes.data}>
                Реквизиты:
                <div className={classes.dataNumbers}>
                    ИНН: {inn} <br />
                    ОГРН/КПП: {ogrn}/{kpp}
                </div>
                <div className={classes.sbisBlock} onClick={magicSBISClick}>
                    Проверьте в СБИС
                </div>
                <div className={classes.sbisBlock} onClick={magicNalogClick}>
                    Проверьте в ФНС
                </div>
            </div>
            <div className={classes.client}>
                <div>
                    {name}
                </div>
                <div className={classes.phone}>
                    <FiPhone className={classes.icon} size={20} />
                    {phone}
                </div>
                <ButtonClientBox 
                handleOpenArchiveMenuClick={handleOpenArchiveMenuClick}
                handleOpenClientDeleteMenuClick={handleOpenClientDeleteMenuClick}
                handleOpenChangeClientMenuClick={handleOpenChangeClientMenuClick}
                />
            </div>
        </div>
        <div className={classes.information}>
            <div className={classes.contacts}>
                <div className={classes.title}>
                Контакты                
                </div>
                <div className={classes.contactsCard}>
                    {contacts.length > 0 ? (
                        <div className={classes.contactsArray}>
                            {contacts.map((contact) => (<ContactCard data={contact} key={contact.id} />))}
                        </div>
                    ) : null}
                    <div className={classes.addContact}>
                        <BsPlusCircleFill size={40} onClick={handleContactClick} />
                    </div>

                </div>
            </div>
            <div className={classes.calendar}>
                <div className={classes.title}>
                    Договоры                
                </div>
                <div className={classes.docsList}>
                    {myDocs.map((doc) => (<DocumentComponent data={doc} key={doc.id} />))}
                </div>
                <AddDocumentComponent clientId={clientId} />
            </div>
        </div>
        <div className={classes.buttons}>
            <div className={classes.inputBox}>
                <div className={classes.addCase} onClick={handleCheck}>
                    <BsFolderPlus size={30} />
                </div>            
            </div>
            <div className={classes.inputBox}>
                <AddTodoComponent id={id} />
            </div>
        </div>
        <div className={classes.rootContent}>
            
            <div className={classes.content}>
                <div className={classes.cases}>
                    <div className={classes.title}>
                        Дела
                    </div>
                    <div className={classes.caseList}>
                        {activeCases.map((cas) => (<CaseItem caseId={cas.id} data={cas} key={cas.id} />))}
                    </div>        
                </div>
                <div className={classes.todos}> 
                    {uncompletedTasks.length ? (
                        <div>
                            <div className={classes.title}>
                            Задачи
                            </div>
                            {uncompletedTasks.map((task) => (<TodoItem data={task} key={task.id} clientId={clientId} />))}
                        </div>
                    ) : null}

                    {completedTasks.length ? (
                        <div>
                            <div className={classes.title}>
                            Завершенные задачи
                            </div>
                            {finalArray.map((task) => (<TodoItem data={task} key={task.id} clientId={clientId} />))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    </div>
  )
}
