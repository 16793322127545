import {FC, useState} from 'react';
import { TodoItemProps } from './interface';
import classes from './todoclientitem.module.scss';
import {AiFillCheckCircle} from 'react-icons/ai';
import { TbClock, TbTrashFilled} from 'react-icons/tb';
import cx from 'classnames';
import {MdInfo} from 'react-icons/md'
import {RiCloseFill} from 'react-icons/ri';
import { useAppSelector } from '../../../../store/reduxHooks';
import { useFindMyUser } from '../../../../hooks/useUsers';
import dayjs from 'dayjs';
import { useHandleChangeNewTasks, useHandleDeleteNewTasks, useHandleToggleNewAdminTask, useHandleToggleNewTasks, useHandleUnToggleNewTasks, useHandleUpdateTimeForNewTasks } from '../../../../hooks/useNewTasks';
import { FaPenNib } from 'react-icons/fa';
import { AddBillingTodoItem } from '../AddBillingTodoItem';
import DatePicker from 'react-datepicker'

export const TodoItem: FC<TodoItemProps> = (props) => {
    const {data, clientId} = props;
    const {title, isCompleted, id, formattedDate, creator, completor, completeTime, fatherId, mustFinishDate} = data;

    const [toggleOpen, setToggleOpen] = useState(true);
    const handleOpenCloseToggleMenu = () => setToggleOpen(value => !value);

    const users = useAppSelector(state => state.users.users)
    const myUser = users.find((user) => user.id === creator)
    const completedUser = users.find((user) => user.id === completor)

    const completeUser = useFindMyUser()
    const completedTimeForHook = dayjs().valueOf()

    const [openDeleteMenu, setOpenDeleteMenu] = useState(true)
    const handleOpenCloseDeleteMenu = () => setOpenDeleteMenu(value => !value)

    const missions = useAppSelector(state => state.newAdminTasks.tasks)
    const chekId = missions.find((mission) => mission.id === id)


    const toggleTask = useHandleToggleNewTasks(id, isCompleted, fatherId, title, formattedDate, creator, completeUser, completedTimeForHook, mustFinishDate)
    const unToggleTask = useHandleUnToggleNewTasks(id, isCompleted, fatherId, title, formattedDate, creator, completor, completeTime, mustFinishDate)

    const toggleAdminTask = useHandleToggleNewAdminTask(chekId)

    const handleToggleTodoClick =() => {
        if(isCompleted === false) {
            if(chekId) {
                toggleTask()
                toggleAdminTask()
            } else {
                toggleTask()
            }
        } else {
            handleOpenCloseToggleMenu()
        }
    }
    const [billingOpen, setBillingOpen] = useState(true);
    const handleOpenCloseBillingMenu = () => setBillingOpen(value => !value);

    const handleUnToggleTodoClick = () => {
        unToggleTask()
        handleOpenCloseToggleMenu()
    }

    const deleteTask = useHandleDeleteNewTasks(id, isCompleted, fatherId, title, formattedDate, creator, completor, completeTime, mustFinishDate)

    const handleTodoDelete = () => {
        deleteTask()
        handleOpenCloseDeleteMenu()
    }

    function takeStatus() {
        if (isCompleted === false) {
            return 'В работе'
        }
        else {
            return 'Завершено'
        }
    }

    const status = takeStatus()

    const [openInfoMenu, setOpenInfoMenu] = useState(true)
    const handleOpenInfoClick = () => {
        setOpenInfoMenu(value => !value)
        setShow(true)
    }

    const startDate = dayjs(formattedDate).format('HH:mm DD.MM.YYYY')
    const finishDate = dayjs(completeTime).format('HH:mm DD.MM.YYYY')
    const mustFinishDateFormat = dayjs(mustFinishDate).format('HH:mm DD.MM.YYYY')
    
    const [show, setShow] = useState(false)
    const handleOpenChangeMenuClick = () => setShow(value => !value)

    const [newName, setNewName] = useState(title)
    const changeTodo = useHandleChangeNewTasks(fatherId, data, newName)

    const handleChangeTodo = () => {
        changeTodo()
        handleOpenInfoClick()
    }

    const timeNow = dayjs().valueOf()

    const checkFailedTodo = () => {
        if(mustFinishDate - 259200000 < timeNow) {
            return true
        } else {return false}
    }
    const failedTodo = checkFailedTodo()

    const checkTrueFailedTodo = () => {
        if(mustFinishDate - 86400000 < timeNow) {
            return true
        } else {return false}
    }
    const trueFailedTodo = checkTrueFailedTodo()

    const checkFuckedTodo = () => {
        if(mustFinishDate < timeNow) {
            return true
        } else {return false}
    }

    const fuckedTodo = checkFuckedTodo()

    const [openMoreTime, setOpenMoreTime] = useState(true)
    const handleOpenMoreTimeMenuClick = () => setOpenMoreTime(value => !value)

    const myLoggedUser = useFindMyUser()
    const admins = [
      'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
      'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
      '1zNyYjM7AKgkkIYvBI3d2tB4kJ43',
      'KQydQPbF73YFmJeUB6L5thnsUnq2',
      'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
      'XkIjsIdw69ghLudgDDztks3g23S2'
    ]

    const [newDate, setNewDate] = useState<any>(mustFinishDate);
    const updateDate = dayjs(newDate).valueOf()

    const updateTime = useHandleUpdateTimeForNewTasks(fatherId, data, updateDate)

    const handleUpdateTimeForTask = () => {
        if(updateDate <= mustFinishDate) {
            alert('Измените время')
        } 
        else {
            updateTime()
            setOpenMoreTime(true)
        }
    }

    return (
        <div>
            {/* Удаление задачи */}
            <div className={cx(classes.popUp, openDeleteMenu === true && classes.popUpClose)} onClick={handleOpenCloseDeleteMenu}>
                <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenCloseDeleteMenu}>
                        <RiCloseFill size={22} />
                    </button>
                    Вы уверены что хотите удалить задачу "{title}"?
                    <div className={classes.deleteButtons}>
                        <button className={classes.button} onClick={handleTodoDelete}>Да</button> <button className={classes.button} onClick={handleOpenCloseDeleteMenu}>Нет</button>
                    </div>
                </div>
             </div>

            {/* Открыть меню */}
            <div className={cx(classes.popUp, openInfoMenu === true && classes.popUpClose)} onClick={handleOpenInfoClick}>
                <div className={classes.infoMenuOpen} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenInfoClick}>
                        <RiCloseFill size={22} />
                    </button>
                    <div className={classes.titleMenu}>
                    Информационное меню
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlock}>
                            <div className={cx(classes.infoTitle, show === false && classes.infoTitleHide)}>
                                {title}
                            </div>
                            <div className={cx(classes.inputRootHide, show === false && classes.inputRootShow)}>
                                <input 
                                className={classes.input} 
                                value={newName} 
                                placeholder={newName}
                                type={'text'} 
                                onChange={(e) => setNewName(e.target.value)}/>
                                <button className={classes.button} onClick={handleChangeTodo}>Изменить</button>
                            </div>
                        </div>
                        <div className={classes.infoRightBlock}>
                            <div className={classes.chatIcon} onClick={handleOpenChangeMenuClick}>
                                <FaPenNib size={22} />
                            </div>
                            <div className={cx(classes.information, isCompleted === true && classes.informationCompleted)}>
                            {status}
                            </div>

                        </div>
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlockForTime}>
                            <div>
                                Создано: {myUser?.label}
                            </div>
                            <div>
                                Время: {startDate}
                            </div>
                        </div>
                        <div className={classes.informationBlockForTime}>
                            <div>
                                Срок выполнения:
                            </div>
                            <div>
                                {mustFinishDateFormat}
                            </div>
                        </div>
                        <div className={classes.informationBlockForTime}>
                            <div className={cx(classes.show, isCompleted === false && classes.hide)}>
                                <div>
                                    Завершено: {completedUser?.label}
                                </div>
                                <div>
                                    Время: {finishDate}
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>

            {/* Растоглить */}
            <div className={cx(classes.popUp, toggleOpen === true && classes.popUpClose)} onClick={handleOpenCloseToggleMenu}>
                <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenCloseToggleMenu}>
                        <RiCloseFill size={22} />
                    </button>
                    Вы уверены что хотите снять статус "Выполнено" у задачи "{title}"?
                    <div className={classes.deleteButtons}>
                        <button className={classes.button} onClick={handleUnToggleTodoClick}>Да</button> <button className={classes.button} onClick={handleOpenCloseToggleMenu}>Нет</button>
                    </div>
                </div>
             </div>

            {/* Забилить */}
            <div className={cx(classes.popUp, billingOpen === true && classes.popUpClose)} onClick={handleOpenCloseBillingMenu}>
                <AddBillingTodoItem handleOpenCloseBillingMenu={handleOpenCloseBillingMenu} clientId={clientId} data={data} />
            </div>

            {/* Продлить */}
            <div className={cx(classes.popUp, openMoreTime === true && classes.popUpClose)} onClick={handleOpenMoreTimeMenuClick}>
                <div className={classes.moreTimeMenu} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenMoreTimeMenuClick}>
                        <RiCloseFill size={22} />
                    </button>
                    Продлить задачу "{title}"
                    <div className={classes.moreTime}>
                        <DatePicker 
                            className={classes.datePicker}
                            locale='ru'
                            dateFormat='dd.MM.yyyy'
                            selected={newDate} 
                            onChange={(date) => setNewDate(date)} 
                        />
                        <button className={classes.button} onClick={handleUpdateTimeForTask}>Продлить</button>
                    </div>
                </div>            
            </div>

            <div className={
                cx(classes.todoItem, 
                failedTodo === true && isCompleted === false && classes.failedTodoItem, 
                trueFailedTodo=== true && isCompleted === false && classes.trueFailedTodoItem,
                fuckedTodo === true && isCompleted === false && classes.fuckedTodoItem)}>
                <div className={classes.MainTodoItem}>
                    <div className={classes.item}>

                        {fuckedTodo === true && !admins.includes(myLoggedUser) ? (<div></div>) :
                        fuckedTodo === true && admins.includes(myLoggedUser) ? (
                        <div className={cx(classes.icon, isCompleted && classes.iconCompleted)}>
                            <AiFillCheckCircle onClick={handleToggleTodoClick} size={25} />
                        </div>
                        ) : fuckedTodo === false ? (
                        <div className={cx(classes.icon, isCompleted && classes.iconCompleted)}>
                            <AiFillCheckCircle onClick={handleToggleTodoClick} size={25} />
                        </div>
                        ) : null}
                        {/* <div className={cx(classes.icon, isCompleted && classes.iconCompleted)}>
                            <TbPigMoney onClick={handleOpenCloseBillingMenu} size={25} />
                        </div> */}
                    </div>
                    <div className={cx(classes.title, isCompleted && classes.titleCompleted)}>
                        {title}
                    </div>
                    <div className={classes.iconBox}>
                        {admins.includes(myLoggedUser) ? (
                        <div className={classes.chatIcon}>
                            <TbClock size={25} onClick={handleOpenMoreTimeMenuClick} />
                        </div>
                        ) : null}
                        {/* {fuckedTodo === true ? ( */}
                        <div className={classes.chatIcon}>
                            <TbTrashFilled size={25} onClick={handleOpenCloseDeleteMenu}/>
                        </div>
                         {/* ) : null} */}
                        <div className={classes.chatIcon}>
                            <MdInfo size={25} onClick={handleOpenInfoClick}/>
                        </div>
                    </div>                
                </div>
                <div className={classes.timeInfoBlock}>
                    <div>
                        Создано: {startDate}
                    </div>
                    {!isCompleted ? (<div>
                        Срок: {mustFinishDateFormat}
                    </div>) : (<div>
                        Выполнено: {finishDate}
                    </div>)}
                </div>

            
            </div>
        </div> 
  )
}