import { configureStore  } from '@reduxjs/toolkit';
import userReducer from './slices/usersSlice';
import userAuthReducer from './slices/userAuthSlice';
import newEventsReducer from './slices/newEventsSlice'
import newClientsReducer from './slices/newClientsSlice'
import newCasesReducer from './slices/newCasesSlice'
import newDisputeReducer from './slices/newDisputsDlice'
import newContactsReducer from './slices/newContactsSlice'
import newClientTasksReducer from './slices/newClientTaskSlice'
import newCaseTasksReducer from './slices/newCaseTaskSlice'
import newDisputTasksReducer from './slices/newDiputTaskSlice'
import newBillingItemsReducer from './slices/newBillingItemSlice'

import newAbonentCasesReducer from './newSlices/newAbonentCasesSlice'
import newKadastrCasesReducer from './newSlices/newKadastrCasesSlice'
import newOtcenkaCasesReducer from './newSlices/newOtcenkaCasesSlice'
import newSudArbitrCasesReducer from './newSlices/newSudArbitrCasesSlice'
import newSudSimpleCasesReducer from './newSlices/newSudSimpleCasesSlice'
import newSudBancrotCasesReducer from './newSlices/newSudBancrotCasesSlice'
import newAdminTasksReducer from './newSlices/newAdminTasksSlice'
import newDocumentsReducer from './slices/newDocumetSlice'
import newBillsReducer from './slices/newBillSlice'
import newProofReduccer from './slices/newProofSlice'

import newCalendarChangeReducer from './newSlices/calendarEventShowInterface'

export const store = configureStore({
    reducer: {
        users: userReducer,
        authuser: userAuthReducer, 
        newDisputes: newDisputeReducer,
        newEvents: newEventsReducer,
        newClients: newClientsReducer, 
        newCases: newCasesReducer,
        newContacts: newContactsReducer,
        newClientTasks: newClientTasksReducer,
        newCaseTasks: newCaseTasksReducer,
        newDiputTasks: newDisputTasksReducer,
        newBillingItems: newBillingItemsReducer,
        newBills: newBillsReducer,  
        newProofs: newProofReduccer,

        newAbonentCases: newAbonentCasesReducer,
        newKadastrCases: newKadastrCasesReducer,
        newOtcenkaCases: newOtcenkaCasesReducer,
        newSudArbitrCases: newSudArbitrCasesReducer,
        newSudSimpleCases: newSudSimpleCasesReducer,
        newSudBancrotCases: newSudBancrotCasesReducer,
        newAdminTasks: newAdminTasksReducer,
        newDocuments: newDocumentsReducer,
        newCalendarChange: newCalendarChangeReducer
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch