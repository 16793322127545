import {FC, useEffect, useState} from 'react'
import { CompletedTasksForDepartmentScreenProps } from './interface'
import classes from './completedtasksforuserscreen.module.scss'
import { RiCloseFill } from 'react-icons/ri'
import { useAppSelector } from '../../../store/reduxHooks'
import dayjs from 'dayjs'
import cx from 'classnames'
import { GiSadCrab } from 'react-icons/gi'
import {DisputCompletedDepartmentTask} from '../DisputCompletedDepartmentTask'
import { CaseCompletedDepartmentTask } from '../CaseCompletedDepartmentTask'


export const CompletedTasksForDepartmentScreen: FC<CompletedTasksForDepartmentScreenProps> = (props) => {
    const { handleOpenTaskInfoClick, department} = props
    const users = useAppSelector((state) => state.users.users)
    const filteredUsers = users.filter((user) => user.specs === department)
    const findFilteredIds = () => {
      if(department === 'Административный отдел') {
        let filteredUsersId = ['FGNTMtdZJuZ5TinR9sJHrf5nPED2', '1zNyYjM7AKgkkIYvBI3d2tB4kJ43', 'kmHouolAUiZmtGX7q4r4pQWafLj2']
        return filteredUsersId
      } else {
        let filteredUsersId = filteredUsers.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
        return filteredUsersId
      }
    } 
    const filteredIds = findFilteredIds()
    const disputes = useAppSelector((state) => state.newDisputes.disputes)
    const disputTasks = useAppSelector((state) => state.newDiputTasks.tasks)
    const caseTasks = useAppSelector((state) => state.newCaseTasks.tasks)
    const tasks = [...disputTasks, ...caseTasks]

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]


    
    const myComletedTasks = tasks.filter((task) => task.isCompleted === true && filteredIds.includes(task.completor))

    const today = dayjs().endOf('day').valueOf()
    const monthAgo = today - 2629800000
    const weekAgo = today - 604800000
    const dayAgo = today - 86400000

    const filteredWeekTasks = myComletedTasks.filter((task) => task.completeTime > weekAgo)
    const filteredDayTasks = myComletedTasks.filter((task) => task.completeTime > dayAgo)
    const filteredMonthTasks = myComletedTasks.filter((task) => task.completeTime > monthAgo)

    useEffect(() => {
        setFilter(filteredWeekTasks)
        setSelect('week')
      }, [handleOpenTaskInfoClick])
  
      const [filter, setFilter] = useState(filteredWeekTasks)
      const [select, setSelect] = useState('week')
  
      const setDayClick = () => {
        setFilter(filteredDayTasks)
        setSelect('day')
      }
      const setWeekClick = () => {
        setFilter(filteredWeekTasks)
        setSelect('week')
      }
      const setMonthClick = () => {
        setFilter(filteredMonthTasks)
        setSelect('month')
      }

  return (
    <div className={classes.root}>
        <button className={classes.closeIcon} onClick={handleOpenTaskInfoClick}>
            <RiCloseFill size={22} />
        </button>
        <div className={classes.buttonBox}>
          <button className={cx(classes.button, select === 'day' && classes.buttonActive)} onClick={setDayClick}>Сегодня</button>
          <button className={cx(classes.button, select === 'week' && classes.buttonActive)} onClick={setWeekClick}>Неделя</button>
          <button className={cx(classes.button, select === 'month' && classes.buttonActive)} onClick={setMonthClick}>Месяц</button>
      </div>
      <div className={classes.title}>
          {department}
        </div>
      {filter.length ? (
            <div className={classes.disputRoot}>
              Споры
            {disputes.map((disput) => (
              <DisputCompletedDepartmentTask data={disput} key={disput.id} filteredIds={filteredIds} disputeId={disput.id}  filteredTasks={filter} />
            ))}  
            Дела   
            {cases.map((disput) => (
              <CaseCompletedDepartmentTask data={disput} key={disput.id} filteredIds={filteredIds} disputeId={disput.id}  filteredTasks={filter} />
            ))} 
              
        </div>) : (
            <div className={classes.alertRoot}>
                <div className={classes.alertText}>
                <GiSadCrab size={50} />
                  <div>
                    Опачки!!! <br />
                    Кажется кое-кто не работает:)
                  </div>
                </div>
            </div>
        )}
    </div>
  )
}

