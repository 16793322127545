import {FC, useState} from 'react';
import { BillingItemProps } from './interface';
import classes from './billingitemprops.module.scss';
import { BsDatabaseFillCheck } from 'react-icons/bs';
import { FaPenNib } from 'react-icons/fa';
import cx from 'classnames'
import { AiFillCheckCircle, AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { useHandleChangeBillingItem, useHandleDeleteBillingItemFromTasks } from '../../hooks/useNewBillingItem';
import { TbTrashFilled } from 'react-icons/tb';

export const BillingItem: FC<BillingItemProps> = (props) => {
    const {data} = props;
    const {name, hourCost, hours} = data;

    const [openChangeButton, setOpenChangeButton] = useState(false)
    const handleOpenChangeItemsClick = () => {
        if(hours!== updateHours) {
            setOpenChangeButton(value => !value)
            setUpdateHours(hours)
        }
        else {setOpenChangeButton(value => !value)}
    }

    const [updateHours, setUpdateHours] = useState(hours)
    const incrementClick = () => setUpdateHours(+updateHours + 1)
    const decrementClick = () => setUpdateHours(updateHours - 1)

    const updateBillingItem =  useHandleChangeBillingItem(data, updateHours)
    const updateHoursClick = () => {
        updateBillingItem()
        setOpenChangeButton(value => !value)
    }

    const deleteBillingItem = useHandleDeleteBillingItemFromTasks(data)
    const handleDeleteBillingItemClick = () => {
        deleteBillingItem()
    }
    
    return (
            <div className={classes.todoItem}>
                <div className={classes.item}>
                    <div className={classes.icon}>
                        <BsDatabaseFillCheck size={22} />
                    </div>
                    <div className={classes.title}>
                        <div className={classes.name}>{name}</div>
                        <div className={classes.miniTitle}>{updateHours} час</div>
                        <div className={cx(classes.hideButtons, openChangeButton === true && classes.buttons)}>
                            <div className={classes.myChangeIcon} onClick={decrementClick}>
                                <AiFillMinusCircle size={22} />
                            </div>
                            <div className={classes.myChangeIcon} onClick={incrementClick}>
                                <AiFillPlusCircle size={22} />
                            </div>
                            <div className={classes.myChangeIcon} onClick={updateHoursClick}>
                                <AiFillCheckCircle size={22} />
                            </div>

                        </div>
                        <div className={classes.miniTitle}>{hourCost * hours} ₽</div>
                    </div>
                    <div className={classes.changeIcon} onClick={handleOpenChangeItemsClick}>
                        <FaPenNib size={20} />
                    </div>
                    <div className={classes.changeIcon} onClick={handleDeleteBillingItemClick}>
                        <TbTrashFilled size={22} />
                    </div>
                </div>
            </div>
  )
}