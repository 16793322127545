import {FC, useState} from 'react'
import { EventForDisputProps } from './interface'
import classes from './eventfordiput.module.scss'
import { useAppSelector } from '../../../store/reduxHooks'
import dayjs from 'dayjs';
import cx from 'classnames'
import { useNavigate } from 'react-router';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { RiCloseFill } from 'react-icons/ri';
import { MyInput } from '../../../components/MyInput';
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';
import { useHandleChageEvents, useHandleDeleteEvents } from '../../../hooks/useNewEvents';
import { useFindMyUser } from '../../../hooks/useUsers';

export const EventForDisput: FC<EventForDisputProps> = (props) => {
    const {data} = props
    const {title, start, lawyer, disputId, id } = data

    const users = useAppSelector(state => state.users.users)
    const myUser = users.find((user) => user.id === lawyer)
    const mySignUser = useFindMyUser()

    const admins = [
        'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
        'KQydQPbF73YFmJeUB6L5thnsUnq2',
        'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
        'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
        '1zNyYjM7AKgkkIYvBI3d2tB4kJ43'
    ]

    const currentDate = dayjs().valueOf()
    const myTime = dayjs(start).valueOf()
    const formattedDate = dayjs(myTime).format('DD MMMM YYYY HH:mm ')
    const navigate = useNavigate()

    const [changeMenuOpen, setChangeMenuOpen] = useState(true)
    const handleOpenChangeMenuClick = () => setChangeMenuOpen(value => !value)

    const [deleteMenuOpen, setDeleteMenuOpen] = useState(true)
    const handleOpenDeleteMenuClick = () => setDeleteMenuOpen(value => !value)

    const [eventName, setEventName] = useState<string>(title)
    const [user, setUser] = useState<any>({label: `${myUser?.label}`, value: `${myUser?.id}`})
    const usersOptions = users.map(item => {
      const user = {
        value: '',
        label: ''
      }
      user.value = item.id
      user.label = item.label
      return user
    })

    const [startDate, setStartDate] = useState<any>(new Date(myTime));

    const newLawyer = user.value
    const updateTime = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss+07:00')
    const finishTimeMS = myTime + 3600000
    const finishTime = dayjs(finishTimeMS).format('YYYY-MM-DDTHH:mm:ss+07:00')

    const addEvent = useHandleChageEvents(eventName, updateTime, finishTime, newLawyer, disputId, id)
    const deleteEvent = useHandleDeleteEvents(id)
    const changeEventClick = () => {
        addEvent()
        handleOpenChangeMenuClick()
    }

    const deleteEventClick = () => {
        deleteEvent()
        handleOpenDeleteMenuClick()
    }
    
  return (
    <div className={cx(classes.root, currentDate > myTime + 3600000 && classes.emptyRoot)}>
        <div className={classes.titleRoot}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.userTitle}>
                <div onClick={() => navigate(`/users/${myUser?.id}`)} className={cx(classes.specsSud, 
                    myUser?.specs === 'IT-отдел' && classes.specsIt,
                    myUser?.specs === 'CEO' && classes.specsCeo,
                    myUser?.specs === 'Ассистент CEO' && classes.specsCeo,
                    myUser?.specs === 'Отдел консалтинга' && classes.specsAbonent,
                    myUser?.specs === 'Отдел оценки' && classes.specsOcenka,
                    myUser?.specs === 'Кадастровый отдел' && classes.specsKadastr)}
                >
                    {myUser?.label}
                </div>            
            </div>

        </div>
        <div className={classes.footer}>
            <div className={classes.time}>
                {formattedDate}
            </div>
            <div className={classes.buttonBox}>
                <button onClick={handleOpenDeleteMenuClick} className={classes.button}>
                <BsTrash size={20}/>
                </button> 
                <button onClick={handleOpenChangeMenuClick} className={classes.button}>
                <BsPencilSquare size={20}/>
                </button>              
            </div>            

        </div>

        {/* Удалить заседание */}
        <div className={cx(classes.popUp, deleteMenuOpen === true && classes.popUpClose)}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenDeleteMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.changeform}>
                    <div className={classes.title}>
                        Удалить заседание:
                        <br/>
                        "{title}"
                    </div> 
                <div className={classes.deleteButtons}>
                    <button onClick={deleteEventClick} className={classes.deleteButton}>Да</button> <button className={classes.deleteButton} onClick={handleOpenDeleteMenuClick}>Нет</button>
                </div>
                </div>
            </div>
        </div>


        {/* Изменить заседание */}
        <div className={cx(classes.popUp, changeMenuOpen === true && classes.popUpClose)}>
            <div className={classes.changeCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenChangeMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.changeform}>
                    <div className={classes.title}>
                        Изменить заседание:
                        <br/>
                        "{title}"
                    </div> 
                    <MyInput 
                        value={eventName}
                        type={'text'}
                        handleInput={setEventName}
                        inputName={'Заседание'}
                        placeholder={title} 
                    />
                    Ответственный
                    <Select
                        isClearable={false}
                        className={classes.select}
                        classNamePrefix="select"
                        options={usersOptions}
                        onChange={(chioce) => setUser(chioce)} 
                        defaultValue={{label: `${myUser?.label}`, value: `${myUser?.id}`}}
                    />
                    <ReactDatePicker 
                        className={classes.datepicker}
                        locale='ru'
                        dateFormat='dd.MM.yyyy'
                        selected={startDate} 
                        showTimeSelect
                        timeIntervals={10}       
                        minTime={setHours(setMinutes(new Date(), 0), 8)}
                        maxTime={setHours(setMinutes(new Date(), 30), 17)}             
                        onChange={(date) => setStartDate(date)} 
                    />
                    <div className={classes.buttonRoot}>
                        <button className={classes.addButton} onClick={changeEventClick}>
                            Изменить
                        </button>        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}