import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { db } from '../firebase.config';
import { useGetNewDocuments } from "./useGetDocs";

export function useHandleAddNewDocs(
    name: string,
    number: string,
    type: 'billing' | 'abonent',
    lawyerPrice: number,
    courtPrice: number,
    summ: number,
    clientId: string,
    ) {
    const UniqeId = uuid()
    const getMyDocs = useGetNewDocuments()
    
    return useCallback(() => {
        const myNewDoc = {
            id: UniqeId,
            name: name,
            number: number,
            type: type,
            lawyerPrice: lawyerPrice,
            courtPrice: courtPrice,
            summ: summ,
            clientId: clientId
        }
        updateDoc(doc(db, "newDocs", "newDocs"), {
            docs: arrayUnion(myNewDoc)
        }).then(getMyDocs)
        
      }, [UniqeId, clientId, courtPrice, getMyDocs, lawyerPrice, name, number, summ, type],
    )
    
}