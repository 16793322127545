import { FC } from 'react'
import { CompletedTasksLawyerRootProps } from './interface'
import classes from './completedtaskslawyerroot.module.scss'
import { TodoDashboardItem } from '../../MyDashboardUi/TodoDashboardItem'
import cx from 'classnames'

export const CompletedTasksLawyerRoot: FC<CompletedTasksLawyerRootProps> = (props) => {
    const {lawyer, tasks} = props
    const filteredTasks = tasks.filter((task) => task.completor === lawyer.id)

  return (
    <div className={cx(classes.root, filteredTasks.length === 0 && classes.emptyRoot)}> 
        <div className={classes.lawyerName}>
            <div>
            {lawyer.label} 

            </div>
            <div className={cx(classes.specsSud, 
                lawyer.specs === 'IT-отдел' && classes.specsIt,
                lawyer.specs === 'CEO' && classes.specsCeo,
                lawyer.specs === 'Абонентский отдел' && classes.specsAbonent,
                lawyer.specs === 'Оценщик' && classes.specsOcenka,
                lawyer.specs === 'Кадастровый отдел' && classes.specsKadastr)}
            >
                {lawyer.specs}
            </div>
            
        </div>
        <div className={classes.lawyerTasks}>
            {filteredTasks.map(
                (task) => (
                    <TodoDashboardItem data={task} key={task.id} />
                )
            )} 
        </div>
    </div>
  )
}

