import {FC} from 'react';
import { ClientItemProps } from './interface';
import classes from './clientitem.module.scss'
import { useNavigate, useParams } from 'react-router';
import {BsPersonFill} from 'react-icons/bs';
import {HiOutlineOfficeBuilding} from 'react-icons/hi'
import {MdWorkOutline} from 'react-icons/md'
import cx from 'classnames'
import { useAppSelector } from '../../../store/reduxHooks';
import dayjs from 'dayjs';

export const ClientItem: FC<ClientItemProps> = (props) => {
  const {data, clientId} = props;
  const { name, clientType } = data;
  const {id} = useParams()

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/clients/${clientId}`)
  }

  const caseTodos = useAppSelector(state => state.newCaseTasks.tasks)
  const clientTodos = useAppSelector(state => state.newClientTasks.tasks)
  const allTodos = [...caseTodos, ...clientTodos]

  const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
  const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
  const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
  const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
  const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
  const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
  const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]
  const myCases = cases.filter((cas) => cas.clientId === clientId)
  const casesIds = myCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
  const allIds = [...casesIds, clientId]

  const timeNow = dayjs().valueOf()

  const myUncompletedThreeDaysTodos = allTodos.filter(task => allIds.includes(task.fatherId)  && task.isCompleted === false && task.mustFinishDate - 259200000 < timeNow)
  const myUncompletedOneDayTodos = allTodos.filter(task => allIds.includes(task.fatherId) && task.isCompleted === false && task.mustFinishDate - 86400000 < timeNow)

  const checkActiveAndFailed = () => {
    if(clientId === id && myUncompletedThreeDaysTodos.length && !myUncompletedOneDayTodos.length) {
      let check = '3days'
      return check
    } 
    if(clientId === id && myUncompletedOneDayTodos.length) {
      let check = '1day'
      return check
    }
  }
  const check = checkActiveAndFailed()

console.log(allIds)

  return (
    <div className={cx(
      classes.clientItem, 
      clientId === id && classes.clientItemActive,
      myUncompletedThreeDaysTodos.length && classes.failedCaseItem,
      myUncompletedOneDayTodos.length && classes.trueFailedCaseItem,
      check === '3days' && classes.failedActiveCaseItem,
      check === '1day' && classes.trueFailedActiveCaseItem,
    )}
    onClick={handleClick}>
        {clientType === 'Физ. лицо' ? <BsPersonFill size={22} /> : null}
        {clientType === 'Юр. лицо' ? <HiOutlineOfficeBuilding size={22} /> : null}
        {clientType === 'ИП' ? <MdWorkOutline size={22} /> : null}
      <span className={classes.title}>
       {name}
      </span>
    </div>
  )
}
