import {FC} from 'react'
import { CalendarForAdminProps } from './interface'
import classes from './calendarforadmin.module.scss'
import dayjs from 'dayjs';
import cx from 'classnames'
import { BigCalendarEventComponent } from '../../../components/BigCalendarEventComponent';

export const CalendarForAdmin: FC<CalendarForAdminProps> = (props) => {
    const {myEventsList} = props
    const startofWeek = dayjs().startOf('week').valueOf()
    const monday = dayjs(startofWeek)
    const tuesday  = dayjs(startofWeek + 86400000 )
    const wednesday = dayjs(startofWeek + 86400000 * 2 )
    const thursday  = dayjs(startofWeek + 86400000 * 3 )
    const friday  = dayjs(startofWeek + 86400000 * 4 )
    const saturday  = dayjs(startofWeek + 86400000 * 5 )
    const today = dayjs().startOf('day')

    const checkWeekDay = () => {
        if(today.valueOf() === monday.valueOf()) {
            let weekDay = 'monday'
            return weekDay
        }
        if(today.valueOf() === tuesday.valueOf()) {
            let weekDay = 'tuesday'
            return weekDay
        }
        if(today.valueOf() === wednesday.valueOf()) {
            let weekDay = 'wednesday'
            return weekDay
        }
        if(today.valueOf() === thursday.valueOf()) {
            let weekDay = 'thursday'
            return weekDay
        }
        if(today.valueOf() === friday.valueOf()) {
            let weekDay = 'friday'
            return weekDay
        } else {
            let weekDay = 'weekend'
            return weekDay
        }
    }
    const weekDay = checkWeekDay()

    const mondayEvents = myEventsList.filter((event) => dayjs(event.start).valueOf() > monday.valueOf() && dayjs(event.start).valueOf() < tuesday.valueOf())
    const tuesdayEvents = myEventsList.filter((event) => dayjs(event.start).valueOf() > tuesday.valueOf() && dayjs(event.start).valueOf() < wednesday.valueOf())
    const wednesdayEvents = myEventsList.filter((event) => dayjs(event.start).valueOf() > wednesday.valueOf() && dayjs(event.start).valueOf() < thursday.valueOf())
    const thursdayEvents = myEventsList.filter((event) => dayjs(event.start).valueOf() > thursday.valueOf() && dayjs(event.start).valueOf() < friday.valueOf())
    const fridayEvents = myEventsList.filter((event) => dayjs(event.start).valueOf() > friday.valueOf() && dayjs(event.start).valueOf() < saturday.valueOf())

  return (
    <div className={classes.root}>
        <div className={cx(classes.dayRoot, weekDay === 'monday' && classes.dayRootActive)}>
            <div className={classes.dayTitle}>
                Понедельник {monday.format('DD.MM')}
            </div>
            <div className={classes.eventList}>
                {mondayEvents.map(item => 
                    <BigCalendarEventComponent key={item.id} event={item} />
                )}
            </div>
        </div>
        <div className={cx(classes.dayRoot, weekDay === 'tuesday' && classes.dayRootActive)}>
            <div className={classes.dayTitle}>
                Вторник {tuesday.format('DD.MM')}
            </div>   
            <div className={classes.eventList}>
                {tuesdayEvents.map(item => 
                    <BigCalendarEventComponent key={item.id} event={item} />
                )}
            </div>
     
        </div>
        <div className={cx(classes.dayRoot, weekDay === 'wednesday' && classes.dayRootActive)}>
            <div className={classes.dayTitle}>
                Среда {wednesday.format('DD.MM')}
            </div>
            <div className={classes.eventList}>
                {wednesdayEvents.map(item => 
                    <BigCalendarEventComponent key={item.id} event={item} />
                )}
            </div>

        </div>
        <div className={cx(classes.dayRoot, weekDay === 'thursday' && classes.dayRootActive)}>
            <div className={classes.dayTitle}>
                Четверг {thursday.format('DD.MM')}
            </div>
            <div className={classes.eventList}>
                {thursdayEvents.map(item => 
                    <BigCalendarEventComponent key={item.id} event={item} />
                )}
            </div>

        </div>
        <div className={cx(classes.dayRoot, weekDay === 'friday' && classes.dayRootActive)}>
            <div className={classes.dayTitle}>
                Пятница {friday.format('DD.MM')}
            </div>
            <div className={classes.eventList}>
                {fridayEvents.map(item => 
                    <BigCalendarEventComponent key={item.id} event={item} />
                )}
            </div>

        </div>
    </div>
  )
}
