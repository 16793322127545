import {FC} from 'react';
import { DeletedCaseItemProps } from './interface';
import classes from './archivedcaseitem.module.scss'
import { useNavigate, useParams } from 'react-router';
import cx from 'classnames';
import {BsFolder2Open} from 'react-icons/bs';

export const DeletedCaseItem:FC <DeletedCaseItemProps> = (props) => {
    const {data, caseId} = props;
    const {name} = data;
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(`/deletedcases/${caseId}`);
    }
    const {id} = useParams()
  return (
    <div className={cx(classes.caseItem, caseId === id && classes.caseItemActive)}
    onClick={handleClick}>
                <div className={classes.icon}>
                   <BsFolder2Open size={25} />
                </div>
                <div className={classes.caseBody}>
                    <div>
                        {name}
                    </div>
                </div>
                <div className={cx(classes.tagMark, 
        // teg === 'Взыскание задолженности' && classes.tagMarkDolgi,
        // teg === 'Деликты' && classes.tagMarkDelicti,
        // teg === 'Банкротство' && classes.tagMarkBancrot,
        // teg === 'Недвижимость' && classes.tagMarkRealEstate,
        // teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
        // teg === 'Корпоративные споры' && classes.tagMarkKorporati,
        // teg === 'Налоговые споры' && classes.tagMarkNalogi,
        // teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
        )}>
        </div>
            </div>
  )
}
