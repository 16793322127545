import {createSlice} from '@reduxjs/toolkit';
import { Event } from '../../api/interface';

interface EventsState {
    events: Event[]
}
const initialState: EventsState = {
    events: []
}

export const newEventsSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewEvents(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewEvents
} = newEventsSlice.actions
export default newEventsSlice.reducer