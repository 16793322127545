import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useCallback } from "react";

import { v4 as uuid } from 'uuid';
import { Bill } from "../api/interface";
import { db } from "../firebase.config";
import { useAppSelector } from "../store/reduxHooks";
import { useGetNewBills } from "./useGetDocs";

export function useHandleAddNewBill (
    number: string,
    docId: string,
    date: number,
    summ: number,
    base: string,
) {
    const getMyDocs = useGetNewBills()
    const UniqeId = uuid()
    const documents = useAppSelector(state => state.newDocuments.docs)
    const myDocument = documents.find((doc) => doc.id === docId)

    return useCallback(() => {
        const newBill = {
            id: UniqeId,
            number: number,
            clientId: myDocument?.clientId,
            docId: docId,
            date: date,
            summ: summ,
            base: base,
            isPayed: false,
            isActed: false,
            isConfirmed: false,
            act: {
                id: '',
                name: '',
                date: 0,
                isSended: false,
                isSigned: false
            }
        }
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayUnion(newBill)
        }).then(getMyDocs)
    }, [UniqeId, base, date, docId, getMyDocs, myDocument?.clientId, number, summ])
}

export function useHandlePayedBill (
    data: Bill
) {
    const getMyDocs = useGetNewBills()
    const bills = useAppSelector(state => state.newBills.bills)
    const myDocument = bills.find((bill) => bill === data)

    return useCallback(() => {
        const oldBill = myDocument
        const newBill = {
            id: data.id,
            number: data.number,
            clientId: data.clientId,
            docId: data.docId,
            date: data.date,
            summ: data.summ,
            base: data.base,
            isPayed: true,
            isActed: data.isActed,
            isConfirmed: data.isConfirmed,
            act: data.act
        }
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayRemove(oldBill)
        })
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayUnion(newBill)
        }).then(getMyDocs)
    }, [data, getMyDocs, myDocument])
}

export function useHandleAddActForBill (
    data: Bill,
    actName: string,
    actDate: number
) {
    const getMyDocs = useGetNewBills()
    const bills = useAppSelector(state => state.newBills.bills)
    const myDocument = bills.find((bill) => bill === data)
    const UniqeId = uuid()

    return useCallback(() => {
        const oldBill = myDocument
        const act = {
            id: UniqeId,
            name: actName,
            date: actDate,
            isSended: false,
            isSigned: false
        }
        const newBill = {
            id: data.id,
            number: data.number,
            clientId: data.clientId,
            docId: data.docId,
            date: data.date,
            summ: data.summ,
            base: data.base,
            isPayed: data.isPayed,
            isActed: true,
            isConfirmed: data.isConfirmed,
            act: act
        }
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayRemove(oldBill)
        })
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayUnion(newBill)
        }).then(getMyDocs)
    }, [UniqeId, actDate, actName, data, getMyDocs, myDocument])
}

export function useHandleSendActForBill (
    data: Bill,
) {
    const getMyDocs = useGetNewBills()
    const bills = useAppSelector(state => state.newBills.bills)
    const myDocument = bills.find((bill) => bill === data)

    return useCallback(() => {
        const oldBill = myDocument
        const act = {
            id: data.act.id,
            name: data.act.name,
            date: data.act.date,
            isSended: true,
            isSigned: false
        }
        const newBill = {
            id: data.id,
            number: data.number,
            clientId: data.clientId,
            docId: data.docId,
            date: data.date,
            summ: data.summ,
            base: data.base,
            isPayed: data.isPayed,
            isActed: true,
            isConfirmed: data.isConfirmed,
            act: act
        }
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayRemove(oldBill)
        })
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayUnion(newBill)
        }).then(getMyDocs)
    }, [data, getMyDocs, myDocument])
}

export function useHandleSignActForBill (
    data: Bill,
) {
    const getMyDocs = useGetNewBills()
    const bills = useAppSelector(state => state.newBills.bills)
    const myDocument = bills.find((bill) => bill === data)

    return useCallback(() => {
        const oldBill = myDocument
        const act = {
            id: data.act.id,
            name: data.act.name,
            date: data.act.date,
            isSended: data.act.isSended,
            isSigned: true
        }
        const newBill = {
            id: data.id,
            number: data.number,
            clientId: data.clientId,
            docId: data.docId,
            date: data.date,
            summ: data.summ,
            base: data.base,
            isPayed: data.isPayed,
            isActed: true,
            isConfirmed: data.isConfirmed,
            act: act
        }
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayRemove(oldBill)
        })
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayUnion(newBill)
        }).then(getMyDocs)
    }, [data, getMyDocs, myDocument])
}

export function useHandleConfirmBill (
    data: Bill,
) {
    const getMyDocs = useGetNewBills()
    const bills = useAppSelector(state => state.newBills.bills)
    const myDocument = bills.find((bill) => bill === data)

    return useCallback(() => {
        const oldBill = myDocument

        const newBill = {
            id: data.id,
            number: data.number,
            clientId: data.clientId,
            docId: data.docId,
            date: data.date,
            summ: data.summ,
            base: data.base,
            isPayed: data.isPayed,
            isActed: true,
            isConfirmed: true,
            act: data.act
        }
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayRemove(oldBill)
        })
        updateDoc(doc(db, "newBillingItems", "bills"), {
            bills: arrayUnion(newBill)
        }).then(getMyDocs)
    }, [data, getMyDocs, myDocument])
}