import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore"
import { useCallback } from "react"
import { db } from "../firebase.config"
import { useGetNewDisputs } from "./useGetDocs"
import { v4 as uuid } from 'uuid';
import { Disput } from "../api/interface";

export function useHandleAddNewDisputes (
    clientId: string,
    caseId: string,
    disputeName: string,
    myCaseTag: string,
    paymentSystem: string,
    billSum: number,
    courtPrice: number,
    lawyerPrice: number,
    compensationSystem: string,
    myUserId: string
    ) {

    const UniqeId = uuid()
    const getMyDisputes = useGetNewDisputs()

    return useCallback(() =>{
        const newDisput = {
            id: UniqeId,
            name: disputeName,
            isActive: true,
            isCompleted: false,
            isArchived: false,
            isDeleted: false,
            creator: myUserId,
            annotation: 'Здесь мы рассказываем о деле',
            plan: 'Здесь мы рассказываем как будем действовать',
            conclusion: '',
            clientId: clientId,
            caseId: caseId,
            teg: myCaseTag,
            paymentsystem: paymentSystem,
            billSum: billSum,
            courtPrice: courtPrice,
            lawyerPrice: lawyerPrice,
            compensationSystem: compensationSystem,
            lawyers: [myUserId],
            billingItems: [],
            bills: [],
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"),{
            disputes: arrayUnion(newDisput)
        }).then(getMyDisputes)
    }, [UniqeId, billSum, caseId, clientId, compensationSystem, courtPrice, disputeName, getMyDisputes, lawyerPrice, myCaseTag, myUserId, paymentSystem])
}

export function useHandleArchiveNewDisputes(
    data: Disput
) {

    const getMyDocs = useGetNewDisputs();

    return useCallback(() => {
        const oldDisput = data
        const newDisput = {
            id: data.id,
            name: data.name,
            isActive: !data.isActive,
            isCompleted: false,
            isArchived: !data.isArchived,
            isDeleted: false,
            creator: data.creator,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            caseId: data.caseId,
            teg: data.teg,
            paymentsystem: data.paymentsystem,
            billSum: data.billSum,
            courtPrice: data.courtPrice,
            lawyerPrice: data.lawyerPrice,
            compensationSystem: data.compensationSystem,
            lawyers: data.lawyers,
            billingItems: data.billingItems,
            bills: data.bills,
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayRemove(oldDisput)
        })
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayUnion(newDisput)
        }).then(getMyDocs)
    }, [data, getMyDocs])
};

export function useHandleDeleteNewDisputes(
    data: Disput
) {

    const getMyDocs = useGetNewDisputs();

    return useCallback(() => {
        const oldDisput = data
        const newDisput = {
            id: data.id,
            name: data.name,
            isActive: !data.isActive,
            isCompleted: false,
            isArchived: data.isArchived,
            isDeleted: !data.isDeleted,
            creator: data.creator,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            caseId: data.caseId,
            teg: data.teg,
            paymentsystem: data.paymentsystem,
            billSum: data.billSum,
            courtPrice: data.courtPrice,
            lawyerPrice: data.lawyerPrice,
            compensationSystem: data.compensationSystem,
            lawyers: data.lawyers,
            billingItems: data.billingItems,
            bills: data.bills,
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayRemove(oldDisput)
        })
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayUnion(newDisput)
        }).then(getMyDocs)
    }, [data, getMyDocs])
};

export function useHandleUpdateNewDisputes(
    data: Disput,
    caseName: string,
    clientPaymentSystem: string,
    clientBillSum: number,
    clientCourtPrice: number,
    clientLawyerPrice: number,
    clientCompensationSystem: string
) {

    const getMyDocs = useGetNewDisputs();

    return useCallback(() => {
        const oldDisput = data
        const newDisput = {
            id: data.id,
            name: caseName,
            isActive: data.isActive,
            isCompleted: data.isCompleted,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            creator: data.creator,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            caseId: data.caseId,
            teg: data.teg,
            paymentsystem: clientPaymentSystem,
            billSum: clientBillSum,
            courtPrice: clientCourtPrice,
            lawyerPrice: clientLawyerPrice,
            compensationSystem: clientCompensationSystem,
            lawyers: data.lawyers,
            billingItems: data.billingItems,
            bills: data.bills,
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayRemove(oldDisput)
        })
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayUnion(newDisput)
        }).then(getMyDocs)
    }, [caseName, clientBillSum, clientCompensationSystem, clientCourtPrice, clientLawyerPrice, clientPaymentSystem, data, getMyDocs])
};

export function useHandleCompleteNewDisputes(
    data: Disput,
    inputValue: string,
) {

    const getMyDocs = useGetNewDisputs();

    return useCallback(() => {
        const oldDisput = data
        const newDisput = {
            id: data.id,
            name: data.name,
            isActive: false,
            isCompleted: true,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            creator: data.creator,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: inputValue,
            clientId: data.clientId,
            caseId: data.caseId,
            teg: data.teg,
            paymentsystem: data.paymentsystem,
            billSum: data.billSum,
            courtPrice: data.courtPrice,
            lawyerPrice: data.lawyerPrice,
            compensationSystem: data.compensationSystem,
            lawyers: data.lawyers,
            billingItems: data.billingItems,
            bills: data.bills,
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayRemove(oldDisput)
        })
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayUnion(newDisput)
        }).then(getMyDocs)
    }, [data, getMyDocs, inputValue])
};

export function useHandleAddMoreInfoNewDisputes(
    data: Disput,
    inputAnnotationValue: string,
    inputPlanValue: string
) {

    const getMyDocs = useGetNewDisputs();

    return useCallback(() => {
        const oldDisput = data
        const newDisput = {
            id: data.id,
            name: data.name,
            isActive: data.isActive,
            isCompleted: data.isCompleted,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            creator: data.creator,
            annotation: inputAnnotationValue,
            plan: inputPlanValue,
            conclusion: data.conclusion,
            clientId: data.clientId,
            caseId: data.caseId,
            teg: data.teg,
            paymentsystem: data.paymentsystem,
            billSum: data.billSum,
            courtPrice: data.courtPrice,
            lawyerPrice: data.lawyerPrice,
            compensationSystem: data.compensationSystem,
            lawyers: data.lawyers,
            billingItems: data.billingItems,
            bills: data.bills,
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayRemove(oldDisput)
        })
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayUnion(newDisput)
        }).then(getMyDocs)
    }, [data, getMyDocs, inputAnnotationValue, inputPlanValue])
};

export function useHandleAddUserToNewDisputes(
    data: Disput,
    addUserValue: string
) {
    const getMyDocs = useGetNewDisputs();
    return useCallback(() => {
        const oldDisput = data
        const newDisput = {
            id: data.id,
            name: data.name,
            isActive: data.isActive,
            isCompleted: data.isCompleted,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            creator: data.creator,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            caseId: data.caseId,
            teg: data.teg,
            paymentsystem: data.paymentsystem,
            billSum: data.billSum,
            courtPrice: data.courtPrice,
            lawyerPrice: data.lawyerPrice,
            compensationSystem: data.compensationSystem,
            lawyers: [...data.lawyers, addUserValue],
            billingItems: data.billingItems,
            bills: data.bills,
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayRemove(oldDisput)
        })
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayUnion(newDisput)
        }).then(getMyDocs)
    }, [addUserValue, data, getMyDocs])
}

export function useHandleRemoveUserToNewDisputes(
    data: Disput,
    newLawyers: string[]
) {
    
    const getMyDocs = useGetNewDisputs();
    return useCallback(() => {
        const oldDisput = data
        const newDisput = {
            id: data.id,
            name: data.name,
            isActive: data.isActive,
            isCompleted: data.isCompleted,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            creator: data.creator,
            annotation: data.annotation,
            plan: data.plan,
            conclusion: data.conclusion,
            clientId: data.clientId,
            caseId: data.caseId,
            teg: data.teg,
            paymentsystem: data.paymentsystem,
            billSum: data.billSum,
            courtPrice: data.courtPrice,
            lawyerPrice: data.lawyerPrice,
            compensationSystem: data.compensationSystem,
            lawyers: newLawyers,
            billingItems: data.billingItems,
            bills: data.bills,
            missions: [],
        }
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayRemove(oldDisput)
        })
        updateDoc(doc(db, "newDisputes", "disputes"), {
            disputes: arrayUnion(newDisput)
        }).then(getMyDocs)
    }, [data, getMyDocs, newLawyers])
}