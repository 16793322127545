import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useGetNewClients } from "./useGetDocs";
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase.config';
import { Client } from '../api/interface';

export function useHandleAddNewClients(
    clientName: string, 
    clientPhone: string, 
    clientINN: string, 
    clientOGRN: string, 
    clientKPP: string,
    clientType: string
) 
{
    const UniqeId = uuid()
    const getMyDocs = useGetNewClients();

    return useCallback(() => {
        const newClient = {
            id: UniqeId,
            name: clientName,
            phone: clientPhone,
            inn: clientINN,
            ogrn: clientOGRN,
            kpp: clientKPP,
            clientType: clientType,
            isActive: true,
            isArchived: false,
            isDeleted: false,
            priority: 'soft'       
        }
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayUnion(newClient)
        }).then(getMyDocs)
    }, [UniqeId, clientINN, clientKPP, clientName, clientOGRN, clientPhone, clientType, getMyDocs])
}

export function useHandleArchiveNewClients(
    data: Client
) {
    const getMyDocs = useGetNewClients();
    return useCallback(() => {
        const oldClient = data
        const newClient = {
            id: data.id,
            name: data.name,
            phone: data.phone,
            inn: data.inn,
            ogrn: data.ogrn,
            kpp: data.kpp,
            clientType: data.clientType,
            isActive: false,
            isArchived: true,
            isDeleted: false,
            priority: data.priority
        }
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayRemove(oldClient)
        })
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayUnion(newClient)
        }).then(getMyDocs)
    }, [data, getMyDocs])
}

export function useHandleDeleteNewClients(
    data: Client
) {
    const getMyDocs = useGetNewClients();
    return useCallback(() => {
        const oldClient = data
        const newClient = {
            id: data.id,
            name: data.name,
            phone: data.phone,
            inn: data.inn,
            ogrn: data.ogrn,
            kpp: data.kpp,
            clientType: data.clientType,
            isActive: false,
            isArchived: false,
            isDeleted: true,
            priority: data.priority        
        }
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayRemove(oldClient)
        })
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayUnion(newClient)
        }).then(getMyDocs)
    }, [data, getMyDocs])
}

export function useHandleChangeProrityToSoftClients(
    data: Client,
) {
    const getMyDocs = useGetNewClients();
    return useCallback(() => {
        const oldClient = data
        const newClient = {
            id: data.id,
            name: data.name,
            phone: data.phone,
            inn: data.inn,
            ogrn: data.ogrn,
            kpp: data.kpp,
            clientType: data.clientType,
            isActive: data.isActive,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            priority: 'soft'        
        }
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayRemove(oldClient)
        })
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayUnion(newClient)
        }).then(getMyDocs)
    }, [data, getMyDocs])
}

export function useHandleChangeProrityToMediumClients(
    data: Client,
) {
    const getMyDocs = useGetNewClients();
    return useCallback(() => {
        const oldClient = data
        const newClient = {
            id: data.id,
            name: data.name,
            phone: data.phone,
            inn: data.inn,
            ogrn: data.ogrn,
            kpp: data.kpp,
            clientType: data.clientType,
            isActive: data.isActive,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            priority: 'medium'        
        }
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayRemove(oldClient)
        })
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayUnion(newClient)
        }).then(getMyDocs)
    }, [data, getMyDocs])
}

export function useHandleChangeProrityToHardClients(
    data: Client,
) {
    const getMyDocs = useGetNewClients();
    return useCallback(() => {
        const oldClient = data
        const newClient = {
            id: data.id,
            name: data.name,
            phone: data.phone,
            inn: data.inn,
            ogrn: data.ogrn,
            kpp: data.kpp,
            clientType: data.clientType,
            isActive: data.isActive,
            isArchived: data.isArchived,
            isDeleted: data.isDeleted,
            priority: 'hard'        
        }
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayRemove(oldClient)
        })
        updateDoc(doc(db, "newClients", "clients"), {
            clients: arrayUnion(newClient)
        }).then(getMyDocs)
    }, [data, getMyDocs])
}