import { useAppSelector } from "../store/reduxHooks"

    export function useMyUser() {
        const user = useAppSelector(state => state.authuser)
        const userId = user.id
        const users = useAppSelector(state => state.users.users)
        const myUser = users.find((user) => user.id === userId)
        return myUser
    }


    export function useFindMyUser() {
        const user = useAppSelector(state => state.authuser)
        let myUser = ''
        function f1() {
          var userI = user.id
          f2(userI)
        }
        function f2(userI: string | null) {
          if(userI != null)  myUser = userI
          return myUser
        }
        f1()
        return myUser
    }

