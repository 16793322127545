import {createSlice} from '@reduxjs/toolkit';
import { Case } from '../../api/interface';

interface CasesState {
    cases: Case[]
}
const initialState: CasesState = {
    cases: []
}

export const newCasesSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewCases(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewCases
} = newCasesSlice.actions
export default newCasesSlice.reducer