import { useCallback } from "react";
import { addDoc, arrayRemove, arrayUnion, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { useGetCases, useGetClients, useGetDisputs } from "./useGetDocs";
import { v4 as uuid } from 'uuid';
import { useAppSelector } from "../store/reduxHooks";


export function useHandleAddCases(
    clientId: string,
    сaseName: string,
    myCaseTag: string,
    caseNumber: string

    ) {
    const getMyCases = useGetCases()
    return useCallback(() =>{
        addDoc(collection(db, 'cases'),{
            name: сaseName,
            isActive: true,
            isCompleted: false,
            isArchived: false,
            isDeleted: false,
            annotation: 'Здесь мы рассказываем о деле',
            plan: 'Здесь мы рассказываем как будем действовать',
            conclusion: '',
            clientId: clientId,
            teg: myCaseTag,
            number: caseNumber,
            missions: [],
            tasks: [],
            disputes: [],
        }).then(getMyCases)
    }, [сaseName, clientId, myCaseTag, caseNumber, getMyCases])
};

export function useHandleAddDisputes (
    clientId: string,
    caseId: string,
    disputeName: string,
    myCaseTag: string,
    paymentSystem: string,
    billSum: number,
    courtPrice: number,
    lawyerPrice: number,
    compensationSystem: string,
    myUserId: string
    ) {
    const getMyDisputes = useGetDisputs()
    return useCallback(() =>{
        addDoc(collection(db, 'disputes'),{
            name: disputeName,
            isActive: true,
            isCompleted: false,
            isArchived: false,
            isDeleted: false,
            creator: myUserId,
            annotation: 'Здесь мы рассказываем о деле',
            plan: 'Здесь мы рассказываем как будем действовать',
            conclusion: '',
            clientId: clientId,
            caseId: caseId,
            teg: myCaseTag,
            paymentsystem: paymentSystem,
            billSum: billSum,
            courtPrice: courtPrice,
            lawyerPrice: lawyerPrice,
            compensationSystem: compensationSystem,
            lawyers: [myUserId],
            billingItems: [],
            bills: [],
            missions: [],
            tasks: []
        }).then(getMyDisputes)
    }, [billSum, caseId, clientId, compensationSystem, courtPrice, disputeName, getMyDisputes, lawyerPrice, myCaseTag, myUserId, paymentSystem])
}

export function useHandleAddBillingItem (
    id: string,
    totalBillPrice: number,
    billTitle: string,
    billCount: number
) {
    const UniqeId = uuid()
    const getMyDisputes = useGetDisputs()
    return useCallback(() => {
        const newBillingItem = {
            id: UniqeId,
            name: billTitle,
            price: totalBillPrice,
            hours: billCount,
        }
        updateDoc(doc(db, 'disputes', `${id}`), {
            billingItems: arrayUnion(newBillingItem)
        }).then(getMyDisputes)
    }, [UniqeId, billCount, billTitle, getMyDisputes, id, totalBillPrice])
}

export function useHandleAddCustomBillingItem (
    id: string,
    totalBillPrice: number,
    userAddChoice: string,
    billCount: number
) {
    const UniqeId = uuid()
    const getMyDisputes = useGetDisputs()
    return useCallback(() => {
        const newBillingItem = {
            id: UniqeId,
            name: userAddChoice,
            price: totalBillPrice,
            hours: billCount,
        }
        updateDoc(doc(db, 'disputes', `${id}`), {
            billingItems: arrayUnion(newBillingItem)
        }).then(getMyDisputes)
    }, [UniqeId, userAddChoice, totalBillPrice, billCount, id, getMyDisputes])
}

export function useHandleAddUserToDispute (
    id: string,
    addUserValue: string,
) {
    const getMyDisputes = useGetDisputs()
    return useCallback(() => {
        
        updateDoc(doc(db, 'disputes', `${id}`), {
            lawyers: arrayUnion(addUserValue)
        }).then(getMyDisputes)
    }, [addUserValue, getMyDisputes, id])
}

export function useHandleAddComments (
    fatherID: string,
    inputAnnotationValue: string,
    inputPlanValue: string
) {
    const getMyDisputes = useGetDisputs()
    return useCallback(() => {
        updateDoc(doc(db, 'disputes', `${fatherID}`), {
            annotation: inputAnnotationValue,
            plan: inputPlanValue,
        }).then(getMyDisputes)
    }, [fatherID, getMyDisputes, inputAnnotationValue, inputPlanValue])
}

export function useHandleCompleteDisput (
    id: string,
    inputValue: string,
    
) {
    const getMyDisputes = useGetDisputs()
    return useCallback(() => {
        updateDoc(doc(db, 'disputes', `${id}`), {
            conclusion: inputValue,
            isCompleted: true,
            isActive: false
        }).then(getMyDisputes)
    }, [getMyDisputes, id, inputValue])
}

export function useHandleArchiveDisput (
    id: string,
) {
    const getMyDisputes = useGetDisputs()
    return useCallback(() => {
        updateDoc(doc(db, 'disputes', `${id}`), {
            isArchived: true,
            isActive: false
        }).then(getMyDisputes)
    }, [getMyDisputes, id])
}

export function useHandleDeleteDisput (
    id: string,
) {
    const getMyDisputes = useGetDisputs()
    return useCallback(() => {
        updateDoc(doc(db, 'disputes', `${id}`), {
            isDeleted: true,
            isActive: false
        }).then(getMyDisputes)
    }, [getMyDisputes, id])
}

export function useHandleAddTasks (
    id: string,
    todo: string, 
    formattedDate: number,
    myUser: string
    ) {
    const clients = useAppSelector(state => state.newClients.clients)
    const cases = useAppSelector(state => state.newCases.cases)
    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const UniqeId = uuid()
    const getMyDocs = useGetClients();
    const getMyCases = useGetCases()
    const getMyDisputes = useGetDisputs()
    const clientIndex = clients.findIndex(
        (client) => client.id === id
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === id
    )
    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === id
    )

    return useCallback(() =>{
        const myTask = {
            id: UniqeId,
            title: todo,
            isCompleted: false,
            formattedDate: formattedDate,
            creator: myUser,
            completor: '',
            completeTime: null
        }
        if(clientIndex > -1) {
            updateDoc(doc(db, 'clients', `${id}`), {
                tasks: arrayUnion(myTask)
            }).then(getMyDocs)
        }
        if(caseIndex > -1) {
            updateDoc(doc(db, 'cases', `${id}`), {
                tasks: arrayUnion(myTask)
            }).then(getMyCases)
        }
        if(DisputeIndex > -1) {
            updateDoc(doc(db, 'disputes', `${id}`), {
                tasks: arrayUnion(myTask)
            }).then(getMyDisputes)
        }
        
    }, [DisputeIndex, UniqeId, caseIndex, clientIndex, formattedDate, getMyCases, getMyDisputes, getMyDocs, id, myUser, todo])
}

export function useHandleToggleTasks (
    id: string,
    isCompleted: boolean,
    fatherId: string,
    title: string,
    formattedDate: number,
    creator: string,
    completeUser: string,
    completedTimeForHook: number
) {
    const clients = useAppSelector(state => state.newClients.clients)
    const cases = useAppSelector(state => state.newCases.cases)
    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const getMyDocs = useGetClients();
    const getMyCases = useGetCases()
    const getMyDisputes = useGetDisputs()

    const clientIndex = clients.findIndex(
        (client) => client.id === fatherId
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === fatherId
    )
    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === fatherId
    )

    return useCallback(() => {
        const oldTodo = {
            id: id,
            isCompleted: isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: '',
            completeTime: null
        }
        const newTodo = {
            id: id,
            isCompleted: !isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: completeUser,
            completeTime: completedTimeForHook
        }
        if(clientIndex > -1) {
            updateDoc(doc(db, 'clients', `${fatherId}`), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, 'clients', `${fatherId}`), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDocs)
        }    
        if(caseIndex > -1) {
            updateDoc(doc(db, 'cases', `${fatherId}`), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, 'cases', `${fatherId}`), {
                tasks: arrayUnion(newTodo)
            }).then(getMyCases)
        } 
        if(DisputeIndex > -1) {
            updateDoc(doc(db, 'disputes', `${fatherId}`), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, 'disputes', `${fatherId}`), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDisputes)
        }     
    }, [DisputeIndex, caseIndex, clientIndex, completedTimeForHook, completeUser, creator, fatherId, formattedDate, getMyCases, getMyDisputes, getMyDocs, id, isCompleted, title])
}


export function useHandleUnToggleTasks (
    id: string,
    isCompleted: boolean,
    fatherId: string,
    title: string,
    formattedDate: number,
    creator: string,
    completor: string,
    completeTime: number
) {
    const clients = useAppSelector(state => state.newClients.clients)
    const cases = useAppSelector(state => state.newCases.cases)
    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const getMyDocs = useGetClients();
    const getMyCases = useGetCases()
    const getMyDisputes = useGetDisputs()

    const clientIndex = clients.findIndex(
        (client) => client.id === fatherId
    )
    const caseIndex = cases.findIndex(
        (cas) => cas.id === fatherId
    )
    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === fatherId
    )

    return useCallback(() => {
        const oldTodo = {
            id: id,
            isCompleted: isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: completor,
            completeTime: completeTime
        }
        const newTodo = {
            id: id,
            isCompleted: !isCompleted,
            title: title,
            formattedDate: formattedDate,
            creator: creator,
            completor: '',
            completeTime: null
        }
        if(clientIndex > -1) {
            updateDoc(doc(db, 'clients', `${fatherId}`), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, 'clients', `${fatherId}`), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDocs)
        }    
        if(caseIndex > -1) {
            updateDoc(doc(db, 'cases', `${fatherId}`), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, 'cases', `${fatherId}`), {
                tasks: arrayUnion(newTodo)
            }).then(getMyCases)
        } 
        if(DisputeIndex > -1) {
            updateDoc(doc(db, 'disputes', `${fatherId}`), {
                tasks: arrayRemove(oldTodo)
            })
            updateDoc(doc(db, 'disputes', `${fatherId}`), {
                tasks: arrayUnion(newTodo)
            }).then(getMyDisputes)
        }     
    }, [id, isCompleted, title, formattedDate, creator, completor, completeTime, clientIndex, caseIndex, DisputeIndex, fatherId, getMyDocs, getMyCases, getMyDisputes])
}

export function useHandleDeleteTasks (
    id: string,
    isCompleted: boolean,
    fatherId: string,
    title: string,
    formattedDate: number,
    creator: string,
    completor: string,
    completeTime: number) {
        const clients = useAppSelector(state => state.newClients.clients)
        const cases = useAppSelector(state => state.newCases.cases)
        const disputes = useAppSelector(state => state.newDisputes.disputes)
        const getMyDocs = useGetClients();
        const getMyCases = useGetCases()
        const getMyDisputes = useGetDisputs()

        const clientIndex = clients.findIndex(
            (client) => client.id === fatherId
        )
        const caseIndex = cases.findIndex(
            (cas) => cas.id === fatherId
        )
        const disputeIndex = disputes.findIndex(
            (disput) => disput.id === fatherId
        )
        return useCallback(() => {
            const oldTodo = {
                id: id,
                isCompleted: isCompleted,
                title: title,
                formattedDate: formattedDate,
                creator: creator,
                completor: completor,
                completeTime: completeTime
            }
            if(clientIndex > -1) {
                updateDoc(doc(db, 'clients', `${fatherId}`), {
                    tasks: arrayRemove(oldTodo)
                }).then(getMyDocs)
            }    
            if(caseIndex > -1) {
                updateDoc(doc(db, 'cases', `${fatherId}`), {
                    tasks: arrayRemove(oldTodo)
                }).then(getMyCases)
            } 
            if(disputeIndex > -1) {
                updateDoc(doc(db, 'disputes', `${fatherId}`), {
                    tasks: arrayRemove(oldTodo)
                }).then(getMyDisputes)
            }     
    }, [id, isCompleted, title, formattedDate, creator, completor, completeTime, clientIndex, caseIndex, disputeIndex, fatherId, getMyDocs, getMyCases, getMyDisputes])
}

export function useHandleUpdateDisputInfo (
    id: string,
    caseName: string,
    clientPaymentSystem: string,
    clientBillSum: number,
    clientCourtPrice: number,
    clientLawyerPrice: number,
    clientCompensationSystem: string
) {
    const getMyDisputes = useGetDisputs()

    return useCallback(() =>{
        updateDoc(doc(db, 'disputes', `${id}`), {
            name: caseName,
            paymentsystem: clientPaymentSystem,
            billSum: clientBillSum,
            courtPrice: clientCourtPrice,
            lawyerPrice: clientLawyerPrice,
            compensationSystem:clientCompensationSystem
        }).then(getMyDisputes)
    }, [caseName, clientBillSum, clientCompensationSystem, clientCourtPrice, clientLawyerPrice, clientPaymentSystem, getMyDisputes, id])
}