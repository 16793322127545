import {FC, useState} from "react";
import { ClientsProps } from "./interface";
import classes from './clients.module.scss'
import { useParams } from "react-router";
import { useAppSelector } from "../../store/reduxHooks";
import cx from 'classnames';
import { AddClientForm } from "../../components/AddClientForm";
import {RiCloseFill} from 'react-icons/ri';
import {BsFillPersonPlusFill} from 'react-icons/bs'
import { useFindMyUser } from "../../hooks/useUsers";

export const NewClients: FC<ClientsProps> = (props) => {
  const {id} = useParams();

  const myUser = useFindMyUser()

  const clients = useAppSelector(state => state.newClients.clients);
  const [myFilterState, setMyFilterState] = useState('')

  const findClients = () => {
    if(myFilterState.length) {
      let filteredClients = clients.filter((client) => client.name.toLocaleLowerCase().includes(myFilterState.toLocaleLowerCase()))
      return filteredClients
    }
    else {
      let filteredClients = clients
      return filteredClients
    }
  }
  const filteredClients = findClients()
  const activeClients = filteredClients.filter((client) => client.isActive === true)

  const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
  const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
  const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
  const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
  const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
  const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
  const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudSimpleCases]

  const disputes = useAppSelector(state => state.newDisputes.disputes);

  const myDisputes = disputes.filter((disput) => disput.lawyers.includes(myUser))
  const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])

  const myCases = sudBancrotCases.filter((cas) => myDisputesIds.includes(cas.id))
  const myNewCases = cases.filter((cas) => cas.lawyers.includes(myUser))
  const allMyCases = [...myCases, ...myNewCases]
  
  const myClientsIds = allMyCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])


  const myClients = activeClients.filter((client) => myClientsIds.includes(client.id))
  const otherClients = activeClients.filter((client) => !myClientsIds.includes(client.id))

  const client = clients.find((client) => client.id === id);
  const [open, setOpen] = useState(true);
  const handleCheck = () => setOpen(value => !value);


  const [openArchiveMenuClick, setOpenArchiveMenuClick] = useState(true)
  const handleOpenArchiveMenuClick =() => setOpenArchiveMenuClick(value => !value)

  const [openDeletedMenuClick, setOpenDeletedMenuClick] = useState(true)
  const handleOpenDeletedMenuClick = () => setOpenDeletedMenuClick(value => !value)  

  const [openClientRoot, setOpenClientRoot] = useState(false)
  const handleOpenClientRootClick = () => setOpenClientRoot(value => !value)

  return (
    <div className={classes.root}>
      <div className={classes.list}>
          {/* <div>
          <input placeholder="поиск" className={classes.input} onChange={(e) => setMyFilterState(e.target.value)} type="text" />
        </div> */}
        <div className={classes.clientList}>
            {/* <div className={classes.title}>Мои Клиенты</div>
            {myClients.map((client) => (
                <ClientItem clientId={client.id} data={client} key={client.id} />
              ))}
              <div onClick={handleOpenClientRootClick} className={classes.titleVsButton}>
                <div  className={classes.title}>Все Клиенты</div>
                <div  
                  className={cx(classes.iconRotate, openClientRoot === true && classes.iconRotateTrue )}
                >
                  <MdArrowDropDown size={25} />
                </div>
              </div>
              <div className={cx(classes.clientRoot, openClientRoot === true && classes.clientRootOpen )}>
                {otherClients.map((client) => (
                  <ClientItem clientId={client.id} data={client} key={client.id} />
                ))}              
              </div> */}

        </div>
        <div>
            <div className={classes.addClient} onClick={handleCheck}>
              <BsFillPersonPlusFill size={35} />
            </div>
            {/* <div className={classes.archiveAndDeletedClient}>
              <div className={classes.archDel} onClick={handleOpenArchiveMenuClick}>
              <BiArchiveIn size={35} />
              </div>
              <div className={classes.archDel} onClick={handleOpenDeletedMenuClick}>
              <BsTrash size={35} />
              </div>
            </div> */}
        </div>
      </div>
      <div className={classes.description}>
          {/* {client ? <ClientScreen data={client} {...client} /> : null} */}
      </div>

      <div className={cx(classes.popUp, open === true && classes.popUpClose)}>
        <div className={classes.form} onClick={e => e.stopPropagation()}>
          <div className={classes.buttonRoot}>
            <button className={classes.icon} onClick={handleCheck}>
                <RiCloseFill size={22} />
            </button>          
          </div>
          <AddClientForm handleClose={handleCheck} />
        </div>
      </div>

      <div className={cx(classes.popUp, openArchiveMenuClick === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
        <div className={classes.archDelScreen} onClick={e => e.stopPropagation()}>
        <button className={classes.icon} onClick={handleOpenArchiveMenuClick}>
                <RiCloseFill size={22} />
            </button>
        </div>
      </div>

      <div className={cx(classes.popUp, openDeletedMenuClick === true && classes.popUpClose)} onClick={handleOpenDeletedMenuClick}>
        <div className={classes.archDelScreen} onClick={e => e.stopPropagation()}>
        <button className={classes.icon} onClick={handleOpenDeletedMenuClick}>
                <RiCloseFill size={22} />
          </button>
        </div>
      </div>

    </div>
  )
}
