import { FC, useState } from 'react';
import { MoreInformationEditMenuProps } from './interface';
import classes from './moreinformationeditmenu.module.scss'
import { RiCloseFill } from 'react-icons/ri';
import { BsPencilSquare } from 'react-icons/bs';
import cx from 'classnames'
import { useHandleAddMoreInfoNewDisputes } from '../../hooks/useNewDisputes';

export const MoreInformationEditMenu:FC <MoreInformationEditMenuProps> = (props) => {
    const { handleOpenInfoMenuClick, annotation, plan, data} = props;
    const [open, setOpen] = useState(true);
    const handleEditAnnotationMenu = () => setOpen(value => !value);

    const [inputAnnotationValue, setInputAnnotationValue] = useState<string>(annotation)
    const [inputPlanValue, setInputPlanValue] = useState<string>(plan);

    const addCommentsToDispute = useHandleAddMoreInfoNewDisputes(data, inputAnnotationValue, inputPlanValue)
    const handleClick = () => {
        addCommentsToDispute()
        handleOpenInfoMenuClick()
        handleEditAnnotationMenu()
    }


  return (
    <div>
        <button className={classes.closeIcon} onClick={handleOpenInfoMenuClick}>
            <RiCloseFill size={22} />
        </button>
        <div className={classes.editButton}>
            <BsPencilSquare size={18} onClick={handleEditAnnotationMenu} />
        </div>
        <div className={cx(classes.infoBlock, open === false && classes.infoBlockHide)}>
            <div className={classes.infoBlockSplit}>
                <div className={classes.annotationTitle}>
                    Аннотация
                </div>
                <p className={classes.annotationText}>{annotation}</p>                        
            </div>
            <div className={classes.infoBlockSplit}>
                <div className={classes.annotationTitle}>
                    План действий
                </div>
                <p className={classes.annotationText}>{plan}</p>
            </div>
        </div>
        <div className={cx(classes.myBlock, open === true && classes.myBlockHide)}>
            <div className={classes.infoBlock}>
                <div className={classes.infoBlockSplit}>
                    <div className={classes.annotationTitle}>
                        Аннотация
                    </div>
                    <textarea wrap='true' className={classes.myArea} value={inputAnnotationValue} onChange={e => setInputAnnotationValue(e.target.value)}  name="" id="" placeholder={annotation}></textarea>
                </div>
                <div className={classes.infoBlockSplit}>
                    <div className={classes.annotationTitle}>
                        План действий
                    </div>
                    <textarea wrap='true' className={classes.myArea} value={inputPlanValue} onChange={e => setInputPlanValue(e.target.value)}  name="" id="" placeholder={plan}></textarea>
                </div>
            </div>
            <button className={classes.button} onClick={handleClick}>Изменить</button>
        </div>
    </div>
  )
}
