import {FC, useState} from 'react';
import { LeftNavBarProps } from './interface';
import {useNavigate} from 'react-router-dom'
import {
    MdOutlineDesktopWindows,
    MdOutlineCases,
    MdCalendarMonth,
    MdAddTask,
    MdOutlineDocumentScanner
    } from 'react-icons/md';
import {BsPeopleFill} from 'react-icons/bs'
import {HiUsers} from 'react-icons/hi2';
// import {FaMoneyBillWaveAlt} from 'react-icons/fa'
import classes from "./leftnavbar.module.scss";
import cx from 'classnames'
import { RiAdminFill, RiCloseFill } from 'react-icons/ri';
import { MyInput } from '../MyInput';
import Select from 'react-select'
import { useAppSelector } from '../../store/reduxHooks';
import { useHandleAddNewAdminTasks } from '../../hooks/useNewTasks';
import { useFindMyUser } from '../../hooks/useUsers';
import { FaMoneyBillWaveAlt } from 'react-icons/fa';
import { GoLaw } from 'react-icons/go';


export const LeftNavBar: FC<LeftNavBarProps> = (props) => {
    const navigate = useNavigate();
    const currentPath = window.location.pathname;  

    const [openInfoMenu, setOpenInfoMenu] = useState(true)
    const handleOpenInfoClick = () => setOpenInfoMenu(value => !value)

    const [taskValue, setTaskValue] = useState('')
    const users = useAppSelector(state => state.users.users)

    const usersOptions = users.map(item => {
      const user = {
        value: '',
        label: ''
      }
      user.value = item.id
      user.label = item.label
      return user
    })

    const [user, setUser] = useState<{value:string, label:string} | null>({value:'', label:''})
    const checkUser = () => {
        if(user !== null) {
            let userID = user.value
            return userID
        } else {
            let userID = ''
            return userID
        }
    }
    const myUser = useFindMyUser()
    const admins = [
      'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
      'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
      '1zNyYjM7AKgkkIYvBI3d2tB4kJ43',
      'KQydQPbF73YFmJeUB6L5thnsUnq2',
      'XkIjsIdw69ghLudgDDztks3g23S2',

    ]

    const bossAdmins = [
        'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
        'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
        '1zNyYjM7AKgkkIYvBI3d2tB4kJ43',
        'pqGwnLn8NscjvFsiY8J89iAxWp72'
    ]
    const checkImportant = () => {
        if(admins.includes(myUser)) {
            let type = 'admin'
            return type
        } else {
            let type = 'simple'
            return type
        }
    }
    const type = checkImportant()

    const userID = checkUser()
    const addAdminTask = useHandleAddNewAdminTasks(userID, taskValue, myUser, type)
    const handleAddNewMission = () => {
        if(user?.value !== '') {
            addAdminTask()
            handleOpenInfoClick()
            setUser({value:'', label:''})
            setTaskValue('')        
        } else {alert('Добавьте ответственного')}

    }

    // const [show, setShow] = useState(false)
    // const changeShowClick = () => setShow(value => !value)


  return (
    <div className={classes.root}>
        <div className={classes.listRoot}>
            {/* Открыть меню */}
            <div className={cx(classes.popUp, openInfoMenu === true && classes.popUpClose)}>
                <div className={classes.infoMenuOpen} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenInfoClick}>
                        <RiCloseFill size={22} />
                    </button>
                    Добавить поручение
                    <div className={classes.taskBody}>
                        <MyInput inputName={'Название'} value={taskValue} handleInput={setTaskValue} placeholder={'Введите поручение'} type={'text'} />
                        <div className={classes.title}>
                            Выбрать ответственного
                        </div>
                        <div className={classes.select}>
                            <Select
                                isClearable={false}
                                classNamePrefix="select"
                                options={usersOptions}
                                onChange={(chioce) => setUser(chioce)} 
                                defaultValue={user}
                            />                    
                        </div>

                        <div className={classes.buttonRoot}>
                            <button className={classes.addButton} onClick={handleAddNewMission}>
                                Добавить
                            </button>        
                        </div>
                    </div>
                </div>
            </div>

            {admins.includes(myUser) ? (<div className={cx(classes.menuItem, currentPath.includes('/admin') && classes.activeMenuItem)}
                onClick={() => navigate('/admin')}>
                <RiAdminFill className={classes.icon} size={30}/>
                <div className={classes.text}>Сводка</div>
            </div>) : null}
            <div className={cx(classes.menuItem, currentPath === '/' && classes.activeMenuItem)}
                onClick={() => navigate('/')}>
                <MdOutlineDesktopWindows className={classes.icon} size={30}/>
                <div className={classes.text}>Доска</div>
            </div>
            {/* <div className={cx(classes.menuItem, currentPath === '/tasks' && classes.activeMenuItem)}
            onClick={() => navigate('/tasks')}>
                <MdOutlineAssignmentLate className={classes.icon} size={30}/>
                <div className={classes.text}>Поручения</div>

            </div> */}
            <div className={cx(classes.menuItem, currentPath.includes('/calendar') && classes.activeMenuItem)}
                onClick={() => navigate('/calendar')}>
                <MdCalendarMonth className={classes.icon} size={30}/>
                <div className={classes.text}>Календарь</div>
            </div>
            <div className={cx(classes.menuItem, currentPath.includes('/clients') && classes.activeMenuItem)}
                onClick={() => navigate('/clients')}>
                <BsPeopleFill className={classes.icon} size={30}/>
                <div className={classes.text}>Клиенты</div>
            </div>
            {/* <div className={cx(classes.menuItem, currentPath.includes('/newClients') && classes.activeMenuItem)}
                onClick={() => navigate('/newClients')}>
                <BsPeopleFill className={classes.icon} size={30}/>
                <div className={classes.text}>Редизайн</div>
            </div> */}
            <div className={cx(classes.menuItem, currentPath.includes('/cases') && classes.activeMenuItem)}
                onClick={() => navigate('/cases')}>
                <MdOutlineCases className={classes.icon} size={30}/>
                <div className={classes.text}>Дела</div>
            </div>
            {currentPath.includes('/disputes') ? (<div className={cx(classes.menuItem, currentPath.includes('/disputes') && classes.activeMenuItem)}
                onClick={() => navigate('/disputes')}>
                <GoLaw className={classes.icon} size={30}/>
                <div className={classes.text}>Споры</div>
            </div>) : null}

            { bossAdmins.includes(myUser) ? (<div className={cx(classes.menuItem, currentPath.includes('/documents') && classes.activeMenuItem)}
                onClick={() => navigate('/documents')}>
                <MdOutlineDocumentScanner  className={classes.icon} size={30}/>
                <div className={classes.text}>Документы</div>
            </div>) : null}
            {bossAdmins.includes(myUser) ? (<div className={cx(classes.menuItem, currentPath.includes('/bills') && classes.activeMenuItem)}
            onClick={() => navigate('/bills')}>
                <FaMoneyBillWaveAlt className={classes.icon} size={30}/>
                <div className={classes.text}>Счета</div>
            </div>) : null}
            <div className={cx(classes.menuItem, currentPath.includes('/users') && classes.activeMenuItem)}
                onClick={() => navigate('/users')}>
                <HiUsers className={classes.icon} size={30}/>
                <div className={classes.text}>Коллеги</div>
            </div>
            { admins.includes(myUser) ? (<div className={classes.menuItem} onClick={handleOpenInfoClick}>
                <MdAddTask  className={classes.icon} size={30}/>
                <div className={classes.text}>Поручения</div>
            </div>) : null}
            {/* <div className={classes.menuItem}
                onClick={changeShowClick}
            >
                <TbNotes className={classes.icon} size={30}/>
                <div className={classes.text}>Заметки</div>
                
            </div> */}

        </div>
        {/* <div className={cx(classes.rootHide, show === true && classes.rootShow)}>

        </div> */}
    </div>
  )
}
