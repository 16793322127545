import {FC} from 'react'
import { DisputCompletedTasksItemProps } from './interface'
import classes from './disputcompletedtasksitem.module.scss'
import { TodoDashboardItem } from '../../MyDashboardUi/TodoDashboardItem'
import cx from 'classnames'
import { useAppSelector } from '../../../store/reduxHooks'

export const DisputCompletedTasksItem: FC<DisputCompletedTasksItemProps> = (props) => {
  const {data, lawyerId, filteredTasks, disputeId} = props
  const {name, teg} = data

  const tasks = useAppSelector(state => state.newDiputTasks.tasks)

  const thisDiputeTasksIds = tasks.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

  const completedTasks = filteredTasks.filter((task) => task.isCompleted === true && task.completor === lawyerId && task.creator === lawyerId && task.fatherId === disputeId)
  const realTasks = completedTasks.filter((task) => thisDiputeTasksIds.includes(task.id))

  return (
    <div className={cx(classes.root, realTasks.length === 0 && classes.emptyRoot)}>
      <div className={classes.disputItem}>
        <div className={classes.rootName}>
          {name}
          <div className={cx(classes.tagMarkTable, 
            teg === 'Взыскание задолженности' && classes.tagMarkDolgiTable,
            teg === 'Деликты' && classes.tagMarkDelictiTable,
            teg === 'Банкротство' && classes.tagMarkBancrotTable,
            teg === 'Недвижимость' && classes.tagMarkRealEstateTable,
            teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSporiTable,
            teg === 'Корпоративные споры' && classes.tagMarkKorporatiTable,
            teg === 'Налоговые споры' && classes.tagMarkNalogiTable,
            teg === 'Интеллектуальная собственность' && classes.tagMarkIntellectTable,
            )}>
            {teg}
          </div>
        </div>
        <div className={classes.rootTasks}>
          {realTasks.map((task) => (
            <TodoDashboardItem data={task} key={task.id} />
          ))}
        </div>      
      </div>
      
      <div className={cx(classes.tagMark, 
        teg === 'Взыскание задолженности' && classes.tagMarkDolgi,
        teg === 'Деликты' && classes.tagMarkDelicti,
        teg === 'Банкротство' && classes.tagMarkBancrot,
        teg === 'Недвижимость' && classes.tagMarkRealEstate,
        teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
        teg === 'Корпоративные споры' && classes.tagMarkKorporati,
        teg === 'Налоговые споры' && classes.tagMarkNalogi,
        teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
        )}>
        </div>
    </div>
  )
}

