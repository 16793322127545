import {FC} from 'react'
import { CompletedEventsMenuProps } from './interface'
import { useParams } from 'react-router'
import classes from './completedeventsmenu.module.scss'
import { useAppSelector } from '../../store/reduxHooks'
import { BigEventInfo } from '../BigEventInfo'

export const CompletedEventsMenu:FC<CompletedEventsMenuProps> = () => {
    const {id} = useParams()
    const events = useAppSelector(state => state.newEvents.events)
    const myEvents = events.filter((event) => event.disputId === id)

    const bancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const myBancrotCase = bancrotCases.find((cas) => cas.id === id)
    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const myDisputes = disputes.filter((disput) => disput.caseId === myBancrotCase?.id)
    const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const allIds = [...myDisputesIds, id]

    const checkMyEvents = () => {
      if(myBancrotCase) {
        let eventList = events.filter((event) => allIds.includes(event.disputId))
        return eventList
      } 
      else {
        let eventList = myEvents
        return eventList
      }
    }

    const eventList = checkMyEvents()

  return (
    <>
          {eventList.length ? (<div className={classes.root}>
              {eventList.reverse().map((event) => (
                  <BigEventInfo data={event} key={event.id} />
              ))}
          </div>) : (
            <div>
            Заседаний по этому делу еще не было
            </div>
          )}
        </>
  )
}
