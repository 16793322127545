import {FC, useState} from 'react';
import { ContactCardProps } from './interface';
import classes from './contactcard.module.scss';
import {HiPhone, HiMail} from 'react-icons/hi';
import cx from 'classnames';
import {BsTrash, BsPencilSquare} from 'react-icons/bs';
import { useHandleChangeContacts, useHandleDeleteContacts } from '../../hooks/useContacts';
import { RiCloseFill } from 'react-icons/ri';
import { MyInput } from '../../components/MyInput';
import InputMask from 'react-input-mask';


export const ContactCard: FC<ContactCardProps> = (props) => {
  const {data} = props;
  const {name, job, phone, mail, id, clientId} = data;

  const [show, setShow] = useState(false)
  const contactName = name
  const contactJob = job
  const contactMail = mail
  const contactPhone = phone

  const deleteContact =  useHandleDeleteContacts(id, contactName, contactJob, contactMail, contactPhone, clientId)
  const handleDeleteClick = () => {
    deleteContact()
  }

  const [newContactName, setNewContactName] = useState(contactName)
  const [newContactJob, setNewContactJob] = useState(contactJob)
  const [newContactMail, setNewContactMail] = useState(contactMail)
  const [newContactPhone, setNewContactPhone] = useState(contactPhone)

  const changeContact =  useHandleChangeContacts(id, contactName, contactJob, contactMail, contactPhone, clientId, newContactName, newContactJob, newContactMail, newContactPhone)

  const [openContactDeleteMenu, setOpenContactDeleteMenu] = useState(true)
  const handleOpenContactDeleteMenuClick = () => setOpenContactDeleteMenu(value => !value)

  const [openContactChangeMenu, setOpenContctChangeMenu] = useState(true)
  const handleOpenContactChangeMenuClick = () => setOpenContctChangeMenu(value => !value)

  const handleChangeContactClick = () => {
    changeContact()
    handleOpenContactChangeMenuClick()
  }

  return (
    <div className={classes.root}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {/* Удаление контакта */}
      <div className={cx(classes.popUp, openContactDeleteMenu === true && classes.popUpClose)} onClick={handleOpenContactDeleteMenuClick}>
        <div className={classes.contactDeleteMenu} onClick={e => e.stopPropagation()}>
          <button className={classes.closeIcon} onClick={handleOpenContactDeleteMenuClick}>
            <RiCloseFill size={22} />
          </button>
          Вы уверены что хотите удалить клиента {name}?
                <div className={classes.deleteButtons}>
                    <button className={classes.button} onClick={handleDeleteClick}>Да</button> <button className={classes.button} onClick={handleOpenContactDeleteMenuClick}>Нет</button>
                </div>
        </div>
      </div>

      {/* Изменение контакта */}
      <div className={cx(classes.popUp, openContactChangeMenu === true && classes.popUpClose)}>
        <div className={classes.contactChangeMenu} onClick={e => e.stopPropagation()}>
          <button className={classes.closeIcon} onClick={handleOpenContactChangeMenuClick}>
            <RiCloseFill size={22} />
          </button>
          <MyInput inputName={'ФИО'} value={newContactName} handleInput={setNewContactName} placeholder={newContactName} type={'text'} />
          <MyInput inputName={'Должность'} value={newContactJob} handleInput={setNewContactJob} placeholder={newContactJob} type={'text'} />
          <MyInput inputName={'E-mail'} value={newContactMail} handleInput={setNewContactMail} placeholder={newContactMail} type={'text'} />
          <div>Телефон</div>
          <InputMask 
            className={classes.input}
            mask='+7(999) 999-99-99' 
            placeholder={newContactPhone} 
            value={newContactPhone} 
            onChange={(e) => setNewContactPhone(e.target.value)}>
          </InputMask>        
          <button className={classes.addButton} onClick={handleChangeContactClick}>Добавить</button>

        </div>
      </div>

      <div className={classes.name}>
        {name}
      </div>
      <div className={classes.job}>
        {job}
      </div>
      <div className={classes.contacts}>
        <HiPhone className={classes.icon} size={16} />
        {phone}
      </div>
      <div className={classes.contacts}>
       <HiMail className={classes.icon} size={16} />
        {mail}
      </div>
      <div className={cx(classes.buttonBox, show === true && classes.buttonBoxShown)}>
        <button className={classes.smallButton}>
          <BsPencilSquare onClick={handleOpenContactChangeMenuClick} size={20}/>
        </button>
        <button className={classes.smallButton} onClick={handleOpenContactDeleteMenuClick}>
          <BsTrash size={20}/>
        </button>
      </div>
      
    </div>
  )
}