import { FC, useState, useEffect} from 'react'
import { AdminProps } from './interface'
import classes from './admin.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/reduxHooks';
import { ClientRootForAdmin } from '../../ui/AdminUi/ClientRoot';
import { CalendarForAdmin } from '../../ui/AdminUi/CalendarForAdmin';
import { useFindMyUser } from '../../hooks/useUsers';
import { TaskMisson } from '../../ui/AdminUi/TaskMission';
import cx from 'classnames'
import { Mission } from '../../api/interface';
import { RiCloseFill } from 'react-icons/ri';
import { EventPopUp } from '../../ui/CalendarUi/EventPopUp';
import { returnInterface } from '../../store/newSlices/calendarEventShowInterface';
import { TodoDashboardItem } from '../../ui/MyDashboardUi/TodoDashboardItem';
import dayjs from 'dayjs';
import { useAuth } from '../../hooks/useAuth';


export const Admin:FC <AdminProps> = (props) => {

  const {isAuth} = useAuth()

  useEffect(() => {
    if(!isAuth) {
      window.location.assign('/')
    }
  })
  
  const clients = useAppSelector(state => state.newClients.clients)
  const activeClients = clients.filter((client) => client.isActive === true)

  const hardClients = activeClients.filter((client) => client.priority === 'hard')
  const mediumClients = activeClients.filter((client) => client.priority === 'medium')
  const softClients = activeClients.filter((client) => client.priority === 'soft')

  const myUser = useFindMyUser()
  const missions = useAppSelector(state => state.newAdminTasks.tasks)

  const [notTasks, setNotTasks] = useState<boolean>(true)
  const [inWorkTasks, setInWorkTasks] = useState<boolean>(true)
  const [completedTasks, setCompletedTasks] = useState<boolean>(false)


  // const setAllClick = () => {
  //   setNotTasks(true)
  //   setInWorkTasks(true)
  //   setCompletedTasks(true)
  // }

  const setNotTaskClick = () => {
    setNotTasks(value => !value)
  }
  const setInWorkClick = () => {
    setInWorkTasks(value => !value)
  }
  const setCompletedClick = () => {
    setCompletedTasks(value => !value)
  }

  const checkNotTaskMissions = () => {
    if(notTasks === true) {
      let notTaskMissions = missions.filter((mission) => mission.status === 'not')
      return notTaskMissions
    } else {
      let notTaskMissions:Mission[] = []
      return notTaskMissions
    }
  }
  const notTaskMissions = checkNotTaskMissions()

  const checkInWorkTaskMissions = () => {
    if(inWorkTasks === true) {
      let inWorkTaskMissions = missions.filter((mission) => mission.status === 'inwork')
      return inWorkTaskMissions
    } else {
      let inWorkTaskMissions:Mission[] = []
      return inWorkTaskMissions
    }
  }
  const inWorkTaskMissions = checkInWorkTaskMissions()

  const checkCompletedTaskMissions = () => {
    if(completedTasks === true) {
      let completedTaskMissions = missions.filter((mission) => mission.status === 'comleted')
      return completedTaskMissions
    } else {
      let completedTaskMissions:Mission[] = []
      return completedTaskMissions
    }
  }
  const completedTaskMissions = checkCompletedTaskMissions()

  const myMissions = [...notTaskMissions, ...inWorkTaskMissions, ...completedTaskMissions]

  const [myTasks, setMyTasks] = useState<boolean>(true)
  const [allTasks, setAllTasks] = useState<boolean>(false)

  const setMyTasksClick = () => {
    setMyTasks(true)
    setAllTasks(false)
  }
  const setAllTasksClick = () => {
    setAllTasks(true)
    setMyTasks(false)
  }

  const checkAuthorMissions = () => {
    if(myTasks ===  true) {
      let filteredMissions = myMissions.filter((mission) => mission.author === myUser)
      return filteredMissions
    } else {
      let filteredMissions = myMissions
      return filteredMissions
    }
  } 
  const dispatch = useAppDispatch()

  const filteredMisssions = checkAuthorMissions()
  const eventPopUpForm = useAppSelector(state => state.newCalendarChange.myId)
  const handleCheck = () => {
    dispatch(returnInterface())
  }
  const myEventsList = useAppSelector(state => state.newEvents.events)

  const disputTasks = useAppSelector(state => state.newDiputTasks.tasks)
  const caseTasks = useAppSelector(state => state.newCaseTasks.tasks)
  const clientTasks = useAppSelector(state => state.newClientTasks.tasks)

  const timeNow = dayjs().valueOf()


  const uncompletedDisputTasks = disputTasks.filter(task => task.isCompleted === false && task.mustFinishDate - 259200000 < timeNow )

  const uncompletedCaseTasks = caseTasks.filter(task => task.isCompleted === false && task.mustFinishDate - 259200000 < timeNow)

  const uncompletedClientTasks = clientTasks.filter(task => task.isCompleted === false && task.mustFinishDate - 259200000 < timeNow)

  
  return (
    <div className={classes.root}>
      {/* PopUp info */}
      <div className={cx(classes.popUp, eventPopUpForm === null && classes.popUpClose)}>
        <div className={classes.moreEventInfoRoot} onClick={e => e.stopPropagation()}>
          <div className={classes.buttonRootForm}>
            <button className={classes.icon} onClick={handleCheck}>
                <RiCloseFill size={22} />
            </button>    
          </div>
          <div>
          <EventPopUp eventPopUpForm={eventPopUpForm}  />
          </div>
        </div>
      </div>

      <div className={classes.caseColumn}>
        {hardClients.map(item => (
          <ClientRootForAdmin priority={item.priority} data={item} key={item.id} clientId={item.id} name={item.name} />
        ))}
        {mediumClients.map(item => (
          <ClientRootForAdmin priority={item.priority} data={item} key={item.id} clientId={item.id} name={item.name} />
        ))}
        {softClients.map(item => (
          <ClientRootForAdmin priority={item.priority} data={item} key={item.id} clientId={item.id} name={item.name} />
        ))}
      </div>
      <div className={classes.content}>
        <div className={classes.calendar}>
          <CalendarForAdmin myEventsList={myEventsList} />
        </div>
        <div className={classes.mainRoot}>
          <div className={classes.semiRoot}>
            Статус
            <div className={classes.buttonBox}>
              <button className={cx(classes.outlinedButton, notTasks === true && classes.outlinedButtonActive)} onClick={setNotTaskClick}>
                Не приступали
              </button>
              <button className={cx(classes.outlinedButton, inWorkTasks === true && classes.outlinedButtonActive)} onClick={setInWorkClick}>
                В работе
              </button>
              <button className={cx(classes.outlinedButton, completedTasks === true && classes.outlinedButtonActive)} onClick={setCompletedClick}>
                Завершено
              </button>

            </div>
            Автор
            <div className={classes.buttonBox}>
              <button className={cx(classes.outlinedButton, myTasks === true && classes.outlinedButtonActive)} onClick={setMyTasksClick}>
                Мои поручения
              </button>
              <button className={cx(classes.outlinedButton, allTasks === true && classes.outlinedButtonActive)} onClick={setAllTasksClick}>
                Все поручения
              </button>


            </div>
            <div className={classes.missionRoot}>
              {filteredMisssions.map(item => (
                <TaskMisson data={item} key={item.id} />
              ))}
            </div>

          </div>
          <div className={classes.semiRoot}>
              Споры
              {uncompletedDisputTasks.map((task) => (<TodoDashboardItem data={task} key={task.id} />))}
              Дела
              {uncompletedCaseTasks.map((task) => (<TodoDashboardItem data={task} key={task.id} />))}
              Клиенты
              {uncompletedClientTasks.map((task) => (<TodoDashboardItem data={task} key={task.id} />))}

          </div>
        </div>
      </div>
    </div>
  )
}
