import { FC } from 'react';
import { ArchivedCasesProps } from './interface';
import classes from './archivedcases.module.scss'
import { useAppSelector } from '../../store/reduxHooks';
import { Case } from '../../api/interface';
import { useParams, useNavigate } from 'react-router';
import { ArchivedCaseScreen } from '../../ui/ArchivedCaseUi/ArchivedCaseScreen';
import { BsFolder2Open, BsTrash } from 'react-icons/bs';
import { ArchivedCaseClientRoot } from '../../components/ArchivedCasesClientRoot';

export const ArchivedCases: FC<ArchivedCasesProps> = (props) => {
    const clients = useAppSelector(state => state.newClients.clients);
    const cases = useAppSelector(state => state.newCases.cases);
    const { id } = useParams();
    const archivedCases = cases.filter((cas) => cas.isArchived === true)
    const completedCases = cases.filter((cas) => cas.isCompleted === true)
    const myCases = [...archivedCases, ...completedCases]
    const oneCase = myCases.find((cas) => cas.id === id);
    const navigate = useNavigate()
    const handleGotoActiveClick = () => {
      navigate('/cases');
    }
    const handleGotoArchiveClick = () => {
      navigate('/deletedcases');
    }

  return (
    <div className={classes.root}>
        <div className={classes.leftList}>
          <div className={classes.list}>
          {cases.length ? (
                    <div>
                  {clients.map((cas) => (
                      <ArchivedCaseClientRoot clientId ={cas.id} data={cas} key={cas.id} />
                  ))}
                    </div>
                  ) : null}


          </div>
                  <div className={classes.archiveAndDeletedClient}>
              <div className={classes.archDel} onClick={handleGotoActiveClick}>
              <BsFolder2Open size={35} />
              </div>
              <div className={classes.archDel} onClick={handleGotoArchiveClick}>
              <BsTrash size={35} />
              </div>
            </div>
        </div>
          <div className={classes.screen}>
            {oneCase ? <ArchivedCaseScreen data={oneCase} {...oneCase} /> : null}
          </div>
    </div>
  )
}
