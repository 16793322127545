import {FC, useState} from 'react'
import { AddEventComponentProps } from './interface'
import classes from './addeventcomponent.module.scss'
import cx from 'classnames'
import { RiCloseFill } from 'react-icons/ri'
import { TbCalendarPlus } from 'react-icons/tb'
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import { useAppSelector } from '../../../store/reduxHooks'
import { MyInput } from '../../../components/MyInput'
import { useHandleAddEvents } from '../../../hooks/useNewEvents'
import dayjs from 'dayjs';
import { setHours, setMinutes } from 'date-fns'
import { useFindMyUser } from '../../../hooks/useUsers'



export const AddEventComponent: FC<AddEventComponentProps> = (props) => {
    const {disputId} = props
    const [addEventMenuOpen, setAddEventMenuOpen] = useState(true);
    const handleOpenAddEventMenuClick = () => setAddEventMenuOpen(value => !value)

    const [user, setUser] = useState<any>([] as any)
    const [eventName, setEventName] = useState<string>('');
    const [startDate, setStartDate] = useState<any>(new Date());


    const users = useAppSelector(state => state.users.users)

    const usersOptions = users.map(item => {
      const user = {
        value: '',
        label: ''
      }
      user.value = item.id
      user.label = item.label
      return user
    })

    const myUser = useFindMyUser()

    const checkLawyer = () => {
        if(user.value === undefined) {
          let fixLawyer = myUser
          return fixLawyer
        }
        else {
          let fixLawyer = user.value
          return fixLawyer
        }
      }
  
    const lawyer = checkLawyer()
    const updateTime = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss+07:00')
    const myTime = dayjs(startDate).valueOf()
    const finishTimeMS = myTime + 3600000
    const finishTime = dayjs(finishTimeMS).format('YYYY-MM-DDTHH:mm:ss+07:00')

    const addEvent = useHandleAddEvents(eventName, updateTime, finishTime, lawyer, disputId )


    const addEventToList = () => {
      if(eventName.length) {
        addEvent()
        setEventName('')
        handleOpenAddEventMenuClick()
        setStartDate(new Date())      
      } else {alert('Введите название заседания')}

    }


  return (
    <div>
        <div className={classes.root} onClick={handleOpenAddEventMenuClick}>
            <TbCalendarPlus size={40} />
        </div>
        <div className={cx(classes.popUp, addEventMenuOpen === true && classes.popUpClose)}>
            <div className={classes.addEventForDiputMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenAddEventMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                <MyInput 
                inputName={'Судебное заседание'} 
                value={eventName} 
                handleInput={setEventName} 
                placeholder={'Добавить судебное заседание'} 
                type={'text'} />
                <Select
                    isClearable={false}
                    className={classes.select}
                    classNamePrefix="select"
                    options={usersOptions}
                    onChange={(chioce) => setUser(chioce)} 
                    defaultValue={{label: `Пользователь`, value: ``}}
                />
                <ReactDatePicker 
                    className={classes.datepicker}
                    locale='ru'
                    dateFormat='dd.MM.yyyy'
                    selected={startDate} 
                    showTimeSelect
                    timeIntervals={10}       
                    minTime={setHours(setMinutes(new Date(), 0), 8)}
                    maxTime={setHours(setMinutes(new Date(), 30), 17)}             
                    onChange={(date) => setStartDate(date)} 
                />
                <div className={classes.buttonRoot}>
                    <button className={classes.addButton} onClick={addEventToList}>
                        Добавить
                    </button>        
                </div>
            </div>
        </div>
    </div>
  )
}