import {FC, useState} from 'react'
import { RiCloseFill } from 'react-icons/ri'
import { TiDocumentAdd } from 'react-icons/ti'
import classes from './adddocumentcomponent.module.scss'
import { AddDocumentComponentProps } from './interface'
import Select from 'react-select'
import cx from 'classnames'
import { MyInput } from '../MyInput'
import { useHandleAddNewDocs } from '../../hooks/useNewDocs'

export const AddDocumentComponent:FC <AddDocumentComponentProps> = (props) => {
    const {clientId} = props
    const [open, setOpen] = useState(true);
    const handleOpenAddTodoMenuClick = () => setOpen(value => !value);
    const documentOptions = [
        { value: "billing", label: "Биллинговый договор" },
        { value: "abonent", label: "Абонентский договор" },
    ]
    const [documentType, setDocumentType] = useState<any>('')
    const [name, setName] = useState<string>('');
    const [number, setNumber] = useState<string>('');
    const [summ, setSumm] = useState<number>(0);
    const [lawyerPrice, setLawyerPrice] = useState<number>(0);
    const [courtPrice, setCourtPrice] = useState<number>(0);
    const type = documentType.value
    const addNewDocument = useHandleAddNewDocs(
        name, 
        number, 
        type, 
        lawyerPrice, 
        courtPrice, 
        summ, 
        clientId
    )
    const handleAddDocumentClick = () => {
        addNewDocument()
        handleOpenAddTodoMenuClick()
        setDocumentType({label: 'Выберите тип договора', value: ''})
        setName('')
        setNumber('')
        setSumm(0)
        setLawyerPrice(0)
        setCourtPrice(0)
    }


  return (
    <>
        <div className={classes.addDocs} onClick={handleOpenAddTodoMenuClick}>
            <TiDocumentAdd size={30} />
        </div>
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} >
                    <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                        <button className={classes.closeIcon} onClick={handleOpenAddTodoMenuClick}>
                            <RiCloseFill size={22} />
                        </button>
                        <div className={classes.inputBox}>
                            <div className={classes.mainInfoMenu}>
                                <div className={classes.title}>
                                    Выбрать тип договора
                                </div>
                                <Select
                                    isClearable={false}
                                    className={classes.select}
                                    classNamePrefix="select"
                                    options={documentOptions}
                                    onChange={(chioce) => setDocumentType(chioce)} 
                                    defaultValue={{label: 'Выберите тип договора', value: ''}}
                                />
                                <MyInput 
                                    value={name}
                                    type={'text'}
                                    handleInput={setName}
                                    inputName={'Название договора'}
                                    placeholder={'Введите имя договора'} 
                                />
                                <MyInput 
                                    value={number}
                                    type={'text'}
                                    handleInput={setNumber}
                                    inputName={'Номер договора'}
                                    placeholder={'Введите номер договора'} 
                                />                            
                            </div>
                            <div className={classes.extraInfoMenu}>
                                <MyInput 
                                    value={lawyerPrice}
                                    type={'number'}
                                    handleInput={setLawyerPrice}
                                    inputName={'Стоимость часа юриста'}
                                    placeholder={'4000'} 
                                /> 
                                {documentType.value === 'billing' ? (<MyInput 
                                    value={courtPrice}
                                    type={'number'}
                                    handleInput={setCourtPrice}
                                    inputName={'Стоимость суд. заседания'}
                                    placeholder={'10000'} 
                                />) : null}                                 
                                <MyInput 
                                    value={summ}
                                    type={'number'}
                                    handleInput={setSumm}
                                    inputName={'Сумма для счета'}
                                    placeholder={'50000'} 
                                /> 
                            </div>
                        </div>
                        <button className={classes.button} onClick={handleAddDocumentClick}>Добавить</button>
                    </div>
        </div>
    </>
    
  )
}
