import  { FC, useState } from 'react';
import { ButtonBoxProps } from './interface';
import classes from './buttonbox.module.scss';
import {BiDotsVerticalRounded, BiArchiveIn} from 'react-icons/bi';
import {BsTrash, BsPencilSquare, BsPlusCircleFill} from 'react-icons/bs';
import {MdCalendarMonth} from 'react-icons/md';
import {RiCloseFill, RiInformationLine} from 'react-icons/ri';
import {SlDocs} from 'react-icons/sl';
import {BiTask} from 'react-icons/bi'
import {HiOutlineCheckCircle} from 'react-icons/hi2'
import cx from 'classnames';
import { MyInput } from '../../components/MyInput';
import { useHandleAddNewProof } from '../../hooks/useNewProofs';
import { useFindMyUser } from '../../hooks/useUsers';
import { useAppSelector } from '../../store/reduxHooks';
import { ProofComponent } from '../../components/ProofComponent';


export const ButtonBox: FC<ButtonBoxProps> = (props) => {
  const {handleOpenTaskInfoClick, 
    handleCompleteCaseClick, 
    handleOpenDeleteMenuClick, 
    handleOpenInfoMenuClick, 
    handleOpenChangeMenuClick, 
    handleArchiveCaseClick, 
    handleOpenCalendarClick,
    handeOpenLastEventsClick,
    fatherId} = props
  const [open, setOpen] = useState(true)
  const openSideBarClick = () => setOpen(value => !value)
  const handleOpenDocumentsMenu = () => alert('Откроем список документов')

  const [addNewInfoMenuOpen, setAddNewInfoMenuOpen] = useState(true)
  const handleOpenNewInfoClick = () => setAddNewInfoMenuOpen(value => !value)

  const [showInputFields, setShowInputDields] = useState(true)
  const handleOpenInputFieldsClick = () => setShowInputDields(value => !value)

  const [argument, setArgument] = useState<string>('')
  const [objection, setObjection] = useState<string>('')
  const [proof, setProof] = useState<string>('')
  const creator = useFindMyUser()


  const addProof = useHandleAddNewProof(creator, argument, objection, proof, fatherId)

  const handleAddProofClick =  () => {
    if (argument.trim().length) {
      addProof()
      setArgument('')
      setObjection('')
      setProof('')
      setShowInputDields(true)
    } else {
      alert('Заполните поле: "Требования/Доводы"')
    }
  }
  const proofs = useAppSelector(state => state.newProofs.proofs)
  const myProofs = proofs.filter(proof => proof.fatherId === fatherId)

  const arrayForSort = [...myProofs]
  const finalArray = arrayForSort.sort((a, b) => a.date > b.date ? 1 : -1)


  return (
  <div>
    <div className={cx(classes.popUpMenu, addNewInfoMenuOpen === true && classes.popUpMenuClose)}>
      <div className={classes.completedMenu} onClick={e => e.stopPropagation()}>
          <button className={classes.closeIcon} onClick={handleOpenNewInfoClick}>
            <RiCloseFill size={22} />
          </button>
          <div className={classes.mainContent}>   
            <div className={classes.addNewInfoButton} onClick={handleOpenInputFieldsClick}>
              <BsPlusCircleFill size={25} />
            </div>
            <div className={cx(classes.inputFields, showInputFields === true && classes.inputFieldsClose)}>
              <input className={classes.inputBlock}
                value={argument}
                type={'text'}
                placeholder={'Требования/Доводы'}
                onChange={(e) => setArgument(e.target.value)}
              />
              <input className={classes.inputBlock}
                value={objection}
                type={'text'}
                placeholder={'Возражения'}
                onChange={(e) => setObjection(e.target.value)}
              />
              <input className={classes.inputBlock}
                value={proof}
                type={'text'}
                placeholder={'Доказательства'}
                onChange={(e) => setProof(e.target.value)}
              />
              <button className={classes.popUpButton} onClick={handleAddProofClick}>
                Подтвердить
              </button>
            </div>
            <div className={cx(classes.mainProofContent, showInputFields === true && classes.mainProofContentClose)}>
              <div className={classes.proofMainInfo}>
                <div className={classes.argumentInfo}>
                  Требования/Доводы
                </div>
                <div className={classes.argumentInfo}>
                  Возражения
                </div>
                <div className={classes.argumentInfo}>
                  Доказательства
                </div>
              </div>
              {finalArray.map((proof) => (
                <div>
                  <ProofComponent data={proof} key={proof.id} />
                </div>
              ))}
            </div>
          </div>
      </div>
    </div>

    <div className={classes.buttonBox}>    
      <div className={cx(classes.bigButton, !myProofs.length && classes.bigButtonFailed)} onClick={handleOpenNewInfoClick}>
        Доводы
      </div>
      <div className={classes.bigButton} onClick={handeOpenLastEventsClick}>
        Архив
      </div>
      <div className={classes.button} title='Выполненные задачи' onClick={handleOpenTaskInfoClick}>
        <BiTask size={22}/>
      </div>
      <div className={classes.button} title='Завершить дело' onClick={handleCompleteCaseClick}>
        <HiOutlineCheckCircle size={22}/>
      </div>
      <div className={classes.button} title='Информация' onClick={handleOpenInfoMenuClick}>
        <RiInformationLine size={22}/>
      </div>
      <div className={classes.button} >
        <SlDocs size={22} title='Документы' onClick={handleOpenDocumentsMenu}/>
      </div>
      <div className={classes.button}>
        <MdCalendarMonth size={22} title='Календарь' onClick={handleOpenCalendarClick}/>
      </div>
      <div className={classes.button}>
        <BiDotsVerticalRounded onClick={openSideBarClick} size={22} title='Увидеть больше'/>               
      </div>
      <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={e => e.stopPropagation()}>
          <div className={classes.sideBarButton}>
            <BsPencilSquare size={22} title='Редактировать' onClick={handleOpenChangeMenuClick}/>
          </div>
          <div className={classes.sideBarButton} title='В Архив' onClick={handleArchiveCaseClick}>
            <BiArchiveIn size={22}/>
          </div>
          <div className={classes.sideBarButton} title='Удалить' onClick={handleOpenDeleteMenuClick}>
          <BsTrash size={22}/>
          </div>
          
      </div>
    </div>
  </div>
  )
}
