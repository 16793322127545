import {createSlice} from '@reduxjs/toolkit';
import { Task } from '../../api/interface';

interface TaskState {
    tasks: Task[];
}
const initialState: TaskState = {
    tasks: [],
}

export const newCaseTaskSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewCaseTasks(state, action) {
            return action.payload
        },
    }
})

export const {
    getNewCaseTasks
} = newCaseTaskSlice.actions
export default newCaseTaskSlice.reducer