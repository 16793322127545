import {FC} from 'react';
import { DisputeItemProps } from './interface';
import classes from './disputeitem.module.scss';
import {BsFolder2Open} from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames'

export const DisputeItem: FC<DisputeItemProps> = (props) => {
    const {data, disputeId} = props;
    const {name, teg} = data;
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(`/disputes/${disputeId}`);
    }
    const {id} =useParams()

  return (
      <div className={cx(classes.caseItem, disputeId === id && classes.caseItemActive,
        teg === 'Взыскание задолженности' && disputeId === id && classes.caseBorderDolgi,
        teg === 'Деликты' && disputeId === id && classes.caseBorderDelicti,
        teg === 'Банкротство' && disputeId === id && classes.caseBorderBancrot,
        teg === 'Недвижимость' && disputeId === id && classes.caseBorderRealEstate,
        teg === 'Споры с гос. и имущ. организациями' && disputeId === id && classes.caseBorderGosSpori,
        teg === 'Корпоративные споры' && disputeId === id && classes.caseBorderKorporati,
        teg === 'Налоговые споры' && disputeId === id && classes.caseBorderNalogi,
        teg === 'Интеллектуальная собственность' && disputeId === id && classes.caseBorderIntellect,
        teg === 'Иное' && disputeId === id && classes.caseBorderOther,
        )}
      onClick={handleClick}>
        <div className={classes.icon}>
          <BsFolder2Open size={25} />
        </div>
        <div className={classes.caseBody}>
          <div className={classes.title}>
            {name}
          </div>
          <div className={classes.client}>
          </div>
        </div>
        <div className={cx(classes.tagMark, 
        teg === 'Взыскание задолженности' && classes.tagMarkDolgi,
        teg === 'Деликты' && classes.tagMarkDelicti,
        teg === 'Банкротство' && classes.tagMarkBancrot,
        teg === 'Недвижимость' && classes.tagMarkRealEstate,
        teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
        teg === 'Корпоративные споры' && classes.tagMarkKorporati,
        teg === 'Налоговые споры' && classes.tagMarkNalogi,
        teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
        disputeId === id && classes.tagMarkActive
        )}>
        </div>
      </div>
  )
}