import {FC, useState} from 'react';
import { TodoDashboardItemProps } from './interface';
import classes from './todoclientitem.module.scss';
import {AiFillCheckCircle} from 'react-icons/ai';
import cx from 'classnames';
import { RiCloseFill } from 'react-icons/ri';
import dayjs from 'dayjs';
import { useAppSelector } from '../../../store/reduxHooks';
import { MdInfo } from 'react-icons/md';
import { useHandleToggleNewAdminTask, useHandleToggleNewTasks } from '../../../hooks/useNewTasks';
import { useFindMyUser } from '../../../hooks/useUsers';
import { useNavigate } from 'react-router';


export const TodoDashboardItem: FC<TodoDashboardItemProps> = (props) => {
    const {data} = props;
    const {title, isCompleted, id, formattedDate, creator, completor, completeTime, fatherId, mustFinishDate} = data;
    const navigate = useNavigate();

    function takeStatus() {
        if (isCompleted === false) {
            return 'В работе'
        }
        else {
            return 'Завершено'
        }
    }
    const [openInfoMenu, setOpenInfoMenu] = useState(true)
    const handleOpenInfoClick = () => setOpenInfoMenu(value => !value)
    const status = takeStatus()
    
    const startDate = dayjs(formattedDate).format('HH:mm DD.MM.YYYY')
    const finishDate = dayjs(completeTime).format('HH:mm DD.MM.YYYY')
    const mustFinishDateFormat = dayjs(mustFinishDate).format('HH:mm DD.MM.YYYY')

    const users = useAppSelector(state => state.users.users)
    const myUser = users.find((user) => user.id === creator)
    const completedUser = users.find((user) => user.id === completor)
    const completeUser = useFindMyUser()
    const completedTimeForHook = dayjs().valueOf()
    const missions = useAppSelector(state => state.newAdminTasks.tasks)
    const chekId = missions.find((mission) => mission.id === id)



    const toggleTask = useHandleToggleNewTasks(id, isCompleted, fatherId, title, formattedDate, creator, completeUser, completedTimeForHook, mustFinishDate)
    const toggleAdminTask = useHandleToggleNewAdminTask(chekId)

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const clients = useAppSelector(state => state.newClients.clients)
    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]


    const DisputeIndex = disputes.findIndex(
        (disput) => disput.id === fatherId
    )
    const clientIndex = clients.findIndex(
        (client) => client.id === fatherId
    )
    const СaseIndex = cases.findIndex(
        (cas) => cas.id === fatherId
    )

    const handleJumpToCaseClick = () => {
        if(clientIndex > -1) {
            navigate(`/clients/${fatherId}`)        
        }    
        if(DisputeIndex > -1) {
            navigate(`/disputes/${fatherId}`)
        }
        if(СaseIndex > -1) {
            navigate(`/cases/${fatherId}`)
        }
    }     

    const findClientID = () => {
        if(clientIndex > -1) {
            let clientId = fatherId
            return clientId
        }    
        if(DisputeIndex > -1) {
            let myDisput = disputes.find((disput) => disput.id === fatherId)
            let clientId = myDisput?.clientId
            return clientId
        }
        if(СaseIndex > -1) {
            let myCase = cases.find((cas) => cas.id === fatherId)
            let clientId = myCase?.clientId
            return clientId
        }    
    }

    const myNewClientID = findClientID()

    const myNewClientLabel = clients.find((client) => client.id === myNewClientID)

    const handleToggleTodoClick = () => {
        if(isCompleted === false) {
            if(chekId) {
                toggleTask()
                toggleAdminTask()
            } else {
                toggleTask()
            }
        } else {
            handleOpenInfoClick()
        }
    }

    const timeNow = dayjs().valueOf()

    const checkFailedTodo = () => {
        if(mustFinishDate - 259200000 < timeNow) {
            return true
        } else {return false}
    }
    const failedTodo = checkFailedTodo()

    const checkTrueFailedTodo = () => {
        if(mustFinishDate - 86400000 < timeNow) {
            return true
        } else {return false}
    }
    const trueFailedTodo = checkTrueFailedTodo()

    
    const checkFuckedTodo = () => {
        if(mustFinishDate < timeNow) {
            return true
        } else {return false}
    }

    const fuckedTodo = checkFuckedTodo()

    return (
        <div>
                        {/* Открыть меню */}
                        <div className={cx(classes.popUp, openInfoMenu === true && classes.popUpClose)} onClick={handleOpenInfoClick}>
                <div className={classes.infoMenuOpen} onClick={e => e.stopPropagation()}>
                    <button className={classes.closeIcon} onClick={handleOpenInfoClick}>
                        <RiCloseFill size={22} />
                    </button>
                    <div className={classes.titleMenu}>
                    Информационное меню
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlock}>
                            <div className={classes.infoTitle}>
                            {title}
                            </div>
                        
                        </div>
                        <div className={cx(classes.information, isCompleted === true && classes.informationCompleted)}>
                        {status}
                        </div>
                    </div>
                    <div className={classes.infoBlock}>
                        <div className={classes.informationBlock}>
                            <div>
                                Создано: {myUser?.label}
                            </div>
                            <div>
                                Время: {startDate}
                            </div>
                        </div>
                        <div className={classes.informationBlock}>
                            <div className={cx(classes.show, isCompleted === false && classes.hide)}>
                                <div>
                                    Завершено: {completedUser?.label}
                                </div>
                                <div>
                                    Время: {finishDate}
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>
             </div>
            <div className={
                cx(classes.todoItem, 
                failedTodo === true && isCompleted === false && classes.failedTodoItem, 
                trueFailedTodo=== true && isCompleted === false && classes.trueFailedTodoItem,
                fuckedTodo === true && isCompleted === false && classes.fuckedTodoItem)}>
                <div className={classes.MainTodoItem}>
                    <div className={classes.item}>
                        <div className={cx(classes.icon, isCompleted && classes.iconCompleted)}>
                            <AiFillCheckCircle size={25} onClick={handleToggleTodoClick}/>
                        </div>
                        <div className={cx(classes.title, isCompleted === true && classes.titleCompleted)}>
                            {title}
                        </div>
                    </div>
                    <div className={classes.chatIcon}>
                        <MdInfo size={25} onClick={handleJumpToCaseClick}/>
                    </div>
                </div>
                <div className={classes.clientLawyerInfoBlock}>
                    <div>
                        Клиент: {myNewClientLabel?.name}
                    </div>
                    <div>
                        Ответственный: {myUser?.label}
                    </div>

                </div>
                <div className={classes.timeInfoBlock}>
                    <div>
                        Создано: {startDate}
                    </div>
                    {!isCompleted ? (<div>
                        Срок: {mustFinishDateFormat}
                    </div>) : (<div>
                        Выполнено: {finishDate}
                    </div>)}
                </div>
            </div>
        </div>
        
  )
}