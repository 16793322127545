import { useCallback } from "react";
import { v4 as uuid } from 'uuid';
import { useGetNewProofs } from "./useGetDocs";
import dayjs from 'dayjs';
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { ProofItem } from "../api/interface";

export function useHandleAddNewProof (
    creator: string,
    argument: string,
    objection: string,
    proof: string,
    fatherId: string
) {
    const getMyDocs = useGetNewProofs()
    const UniqeId = uuid()
    const date = dayjs().valueOf()
    return useCallback(() => {
        const newProof = {
            id: UniqeId,
            creator: creator,
            date: date,
            argument: argument,
            objection: objection,
            proof: proof,
            fatherId: fatherId,
            boss: false,
            director: false,
            owner: false
        }
        updateDoc(doc(db, "newProofs", "newProofs"), {
            proofs: arrayUnion(newProof)
        }).then(getMyDocs)
    }, [UniqeId, creator, argument, objection, proof, fatherId, date, getMyDocs])
}

export function useHandleAddObjection (
    data:ProofItem,
    newObjection: string
) {
    const getMyDocs = useGetNewProofs()
    return useCallback(() =>{
        const oldProofItem = data
        const newProofItem = {
            id: data.id,
            creator: data.creator,
            date: data.date,
            argument: data.argument,
            objection: newObjection,
            proof: data.proof,
            fatherId: data.fatherId,
            boss: data.boss,
            director: data.date,
            owner: data.owner
        }
        updateDoc(doc(db, "newProofs", "newProofs"), {
            proofs: arrayRemove(oldProofItem)
        })
        updateDoc(doc(db, "newProofs", "newProofs"), {
            proofs: arrayUnion(newProofItem)
        }).then(getMyDocs)
    }, [data, newObjection,getMyDocs ])
}

export function useHandleAddProof (
    data:ProofItem,
    newProof: string
) {
    const getMyDocs = useGetNewProofs()
    return useCallback(() =>{
        const oldProofItem = data
        const newProofItem = {
            id: data.id,
            creator: data.creator,
            date: data.date,
            argument: data.argument,
            objection: data.objection,
            proof: newProof,
            fatherId: data.fatherId,
            boss: data.boss,
            director: data.date,
            owner: data.owner
        }
        updateDoc(doc(db, "newProofs", "newProofs"), {
            proofs: arrayRemove(oldProofItem)
        })
        updateDoc(doc(db, "newProofs", "newProofs"), {
            proofs: arrayUnion(newProofItem)
        }).then(getMyDocs)
    }, [data, newProof,getMyDocs ])
}