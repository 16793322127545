import {FC} from 'react';
import { CaseItemProps } from './interface';
import classes from './caseitem.module.scss';
import {BsFolder2Open} from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames'
import { TbMapPin, TbMoneybag,   } from "react-icons/tb";
import { GoLaw } from "react-icons/go";
import { FaRubleSign } from 'react-icons/fa';
import { useAppSelector } from '../../../store/reduxHooks';
import dayjs from 'dayjs';

export const CaseItem: FC<CaseItemProps> = (props) => {
  const {data, caseId} = props;
  const {name, caseType} = data;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/cases/${caseId}`);
  }
  const {id} = useParams()

  const timeNow = dayjs().valueOf()


  const todos = useAppSelector(state => state.newCaseTasks.tasks)
  const myUncompletedThreeDaysTodos = todos.filter(task => task.fatherId === caseId && task.isCompleted === false && task.mustFinishDate - 259200000 < timeNow)
  const myUncompletedOneDayTodos = todos.filter(task => task.fatherId === caseId && task.isCompleted === false && task.mustFinishDate - 86400000 < timeNow)
  
  const checkActiveAndFailed = () => {
    if(caseId === id && myUncompletedThreeDaysTodos.length && !myUncompletedOneDayTodos.length) {
      let check = '3days'
      return check
    } 
    if(caseId === id && myUncompletedOneDayTodos.length) {
      let check = '1day'
      return check
    }
  }

  const check = checkActiveAndFailed()

    
  return (
      <div className={cx(
        classes.caseItem, 
        caseId === id && classes.caseItemActive,
        myUncompletedThreeDaysTodos.length && classes.failedCaseItem,
        myUncompletedOneDayTodos.length && classes.trueFailedCaseItem,
        check === '3days' && classes.failedActiveCaseItem,
        check === '1day' && classes.trueFailedActiveCaseItem,
        )}
      onClick={handleClick}>
        <div>
        {caseType === 'kadastr' ? (
          <div className={classes.icon}>
            <TbMapPin size={25} />
          </div>
        ) : 
        caseType === 'otcenka' ? (
          <div className={classes.icon}>
            <FaRubleSign size={25} />
          </div>        
        ) :
        caseType === 'abonent' ? (
          <div className={classes.icon}>
            <BsFolder2Open size={25} />
          </div>        
        ) :
        caseType === 'sudSimple' ? (
          <div className={classes.icon}>
            <GoLaw size={25} />
          </div>        
        ) :
        caseType === 'sudArbitr' ? (
          <div className={classes.icon}>
            <GoLaw size={25} />
          </div>        
        ) :
        caseType === 'sudBancrot' || 'sudBankrot' ? (
          <div className={classes.icon}>
            <TbMoneybag size={25} />
          </div>        
        ) :
        null}
        </div>

        <div className={classes.caseBody}>
          <div className={classes.title}>
            {name}
          </div>
          <div className={classes.client}>
          </div>
        </div>
      </div>
  )
}