import {createSlice} from '@reduxjs/toolkit';
import { Bill } from '../../api/interface';

interface EventsState {
    bills: Bill[]
}
const initialState: EventsState = {
    bills: []
}

export const newBillSlice = createSlice({
    name: 'newBills',
    initialState,
    reducers: {
        getNewBills(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewBills
} = newBillSlice.actions
export default newBillSlice.reducer