import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { ChangeCalendarEventId } from '../../api/payloadInterface';

interface calendarShow {
    myId: string | null
}

const initialState: calendarShow = {
    myId: null
}

export const calendarEventSlice = createSlice({
    name: 'newCalendarEvent',
    initialState,
    reducers: {
        changeInterface(state, action: PayloadAction<ChangeCalendarEventId>) {
            state.myId = action.payload.id
        },
        returnInterface(state) {
            state.myId = null
        }
    }
})

export const {
    changeInterface,
    returnInterface
} = calendarEventSlice.actions
export default calendarEventSlice.reducer