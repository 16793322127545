import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { useCallback } from "react";
import { v4 as uuid } from 'uuid';
import { useGetNewContacts } from "./useGetDocs";
import { db } from "../firebase.config";

export function useHandleAddContacts(
    id: string,
    contactName: string,
    contactJob: string,
    contactMail: string,
    contactPhone: string
    ) {
    const UniqeId = uuid()
    const getMyContacts = useGetNewContacts()
    return useCallback(() =>{
        const newContactItem = {
            id: UniqeId,
            clientId: id,
            job: contactJob,
            mail: contactMail,
            name: contactName,
            phone: contactPhone
        }
        updateDoc(doc(db, 'newContacts', 'clientContacts'),{
            contacts: arrayUnion(newContactItem)
        }).then(getMyContacts)
    }, [UniqeId, contactJob, contactMail, contactName, contactPhone, getMyContacts, id])
};

export function useHandleDeleteContacts(
    id: string,
    contactName: string,
    contactJob: string,
    contactMail: string,
    contactPhone: string,
    clientId: string
    ) {
    const getMyContacts = useGetNewContacts()
    return useCallback(() =>{
        const ContactItem = {
            clientId: clientId,
            id: id,
            job: contactJob,
            mail: contactMail,
            name: contactName,
            phone: contactPhone
        }
        updateDoc(doc(db, 'newContacts', 'clientContacts'),{
            contacts: arrayRemove(ContactItem)
        }).then(getMyContacts)
    }, [clientId, contactJob, contactMail, contactName, contactPhone, getMyContacts, id])
};


export function useHandleChangeContacts(
    id: string,
    contactName: string,
    contactJob: string,
    contactMail: string,
    contactPhone: string,
    clientId: string,
    newContactName: string,
    newContactJob: string,
    newContactMail: string,
    newContactPhone: string

    ) {
    const getMyContacts = useGetNewContacts()
    return useCallback(() =>{
        const contactItem = {
            clientId: clientId,
            id: id,
            job: contactJob,
            mail: contactMail,
            name: contactName,
            phone: contactPhone
        }
        const newContactItem = {
            clientId: clientId,
            id: id,
            job: newContactJob,
            mail: newContactMail,
            name: newContactName,
            phone: newContactPhone
        }
        updateDoc(doc(db, 'newContacts', 'clientContacts'),{
            contacts: arrayRemove(contactItem)
        })
        updateDoc(doc(db, 'newContacts', 'clientContacts'), {
            contacts: arrayUnion(newContactItem)
        }).then(getMyContacts)
    }, [clientId, contactJob, contactMail, contactName, contactPhone, getMyContacts, id, newContactJob, newContactMail, newContactName, newContactPhone])
};