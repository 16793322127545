import {createSlice} from '@reduxjs/toolkit';
import { Event } from '../../api/interface';

const initialState: Event[] = []

export const eventSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        getEvents(state, action) {
            return action.payload
        }
    }
})

export const {
    getEvents
} = eventSlice.actions
export default eventSlice.reducer