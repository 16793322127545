import {FC, useState, useEffect} from 'react';
import { DisputeScreenProps } from './interface';
import classes from './disputescreen.module.scss'
import { useAppSelector } from '../../../store/reduxHooks';
import { UserCard } from '../../UserUi/UserCard';
import { ButtonBox } from '../../ButtonBox';
import { TodoItem } from '../../Clientui/TodoItem/MainTodoItem';

import Select from 'react-select'
import { BillInput } from '../../../components/BillInput';
import { BillingItem } from '../../../components/BillingItem';
import cx from 'classnames';
import {RiCloseFill} from 'react-icons/ri';
import { BsPlusCircleFill } from 'react-icons/bs';
import { selectOptions } from '../../../api/data';
import { MoreInformationEditMenu } from '../../../components/MoreInformationEditMenu';
import { AddTodoComponent } from '../../../components/AddTodoComponent';
import { useHandleAddBillingItem, useHandleAddCustomBillingItem } from '../../../hooks/useCase';
import {CompletedTasksScreen} from '../CompletedTasksScreen';
import { useNavigate } from 'react-router';
import {EventForDisput} from '../EventForDisput';
import { AddEventComponent } from '../AddEventComponent';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import { FaRegCalendarTimes } from 'react-icons/fa';
import { useHandleAddUserToNewDisputes, useHandleArchiveNewDisputes, useHandleCompleteNewDisputes, useHandleDeleteNewDisputes } from '../../../hooks/useNewDisputes';
import { ChangeDisputForm } from '../ChangeDisputForm';
import { UserCardForDisput } from '../../UserUi/UserCardForDisputes';
import { CompletedEventsMenu } from '../../../components/CompletedEventsMenu';

export const DisputeScreen: FC<DisputeScreenProps> = (props) => {
    const { data} = props;
    const { id, caseId, name, cas, number, lawyers, lawyerPrice, courtPrice, annotation, plan, billSum, teg, clientId} = data;
    const users = useAppSelector(state => state.users.users);
    const magicUser = users.filter((user) => lawyers.includes(user.id));

    require('dayjs/locale/ru')
    dayjs.locale('ru')
    const localizer = dayjsLocalizer(dayjs)

    const tasks = useAppSelector(state => state.newDiputTasks.tasks)

    const completedTasks = tasks.filter((item) => item.isCompleted === true && item.fatherId === id);
    const unCompletedTasks = tasks.filter((item) => item.isCompleted === false && item.fatherId === id);
    unCompletedTasks.sort((a, b) => a.formattedDate < b.formattedDate ? 1 : -1)
    completedTasks.sort((a, b) => a.formattedDate < b.formattedDate ? 1 : -1)

    const billingItems = useAppSelector(state => state.newBillingItems.billingItems)
    const myBillingItems = billingItems.filter((item) => item.fatherId === id)    
 
    // const totalPrice = myBillingItems.reduce((acc, billItem) => acc + billItem.price, 0)

    // useEffect(() => {
    //     if(totalPrice >= billSum) {
    //         alert('Самое время выставить счет.')
    //     }
    // })

    const [userChoice, setUserChoice] = useState<any>([] as any)

    const [billCountStr, setBillCountStr] = useState<number>([] as any);
    const billTitle = userChoice.value;
    const lawyerBillingPrice = lawyerPrice;

    const [open, setopen] = useState(true);
    const handleOpenCloseClick = () => setopen(value => !value)
    const billCount = + billCountStr
    const totalBillPrice = billCount * lawyerBillingPrice

    const [userAddChoice, setUserAddChoice] = useState<any>([] as any);

    const cases = useAppSelector((state) => state.newCases.cases)
    const myCase = cases.find((cas) => cas.id === caseId)


    const addBillingItem = useHandleAddBillingItem(id, totalBillPrice, billTitle, billCount)

    const handleAddBillingItemToDispute = () => {
        if( billTitle !== 'other') {
            addBillingItem()
            setBillCountStr(0)
        } else {
            handleOpenCloseClick()
        }
    }

    const handleBillKeyPress = (e: any) => {
        if(e.key === 'Enter'){
            handleAddCustomBillingItemToDispute()
        }
    }

    const addCustomBillingItem = useHandleAddCustomBillingItem(id, totalBillPrice, userAddChoice, billCount)

    const handleAddCustomBillingItemToDispute = () => {
        addCustomBillingItem()
        setBillCountStr(0)
        handleOpenCloseClick()
    }

    const myEventsList = useAppSelector(state => state.newEvents.events)

    const customEventList = myEventsList.filter((event) => event.disputId === id)




    const [archiveMenuOpen, setArchiveMenuOpen] = useState(true)
    const handleOpenArchiveMenuClick = () => setArchiveMenuOpen(value => !value)

    const [deleteMenuOpen, setDeleteMenuOpen] = useState(true);
    const handleOpenDeleteMenuClick = () => setDeleteMenuOpen(value => !value)

    const [informationMenuOpen, setInformationMenuOpen] = useState(true);
    const handleOpenInfoMenuClick = () => setInformationMenuOpen(value => !value);

    const [addUserOpen, setAddUserOpen] = useState(true);
    const handleAddUserClick = () => setAddUserOpen(value => !value)

    const [changeMenuOpen, setChangeMenuOpen] = useState(true);
    const handleOpenChangeMenuClick = () => setChangeMenuOpen(value => !value)

    const [calendarMenuOpen, setCalendarMenuOpen] = useState(true);
    const handleOpenCalendarClick = () => setCalendarMenuOpen(value => !value)

    const [userOptionChoice, setUserOptionChoice] = useState<any>([] as any)
    const addUserValue = userOptionChoice.value


    const addUserToDispute = useHandleAddUserToNewDisputes(data, addUserValue)
    const handleAddUserDispatchClick = () => {
        if(addUserValue) {
            addUserToDispute()
            setUserOptionChoice({})         
            handleAddUserClick()
        }
    }
    
    const [completeCaseMenuOpen, setCompleteCaseMenuOpen] = useState(true)
    const handleCompleteCaseMenuOpenClick = () => setCompleteCaseMenuOpen(value => !value)
    
    const addUsersArray = users.filter((item) => !lawyers.includes(item.id))

    const [inputValue, setInputValue] = useState([] as any)

    const completeDispute = useHandleCompleteNewDisputes(data, inputValue)

    const handleCompleteCase = () => {
        completeDispute()
        handleCompleteCaseMenuOpenClick()
        setInputValue('')
    }
    const [taskMenuOpen, setTaskMenuOpen] = useState(true);
    const handleOpenTaskInfoClick = () => setTaskMenuOpen(value => !value)

    const archiveCase = useHandleArchiveNewDisputes(data)
    const navigate = useNavigate()
    const handleArchiveCaseClick = () => {
        archiveCase()
        navigate('/disputes')
    }

    const deleteCase = useHandleDeleteNewDisputes(data)
    const handleDeleteCaseClick = () => {
        deleteCase()
        navigate('/disputes')
    }

    const events = useAppSelector(state => state.newEvents.events)
    const myDisputEvents = events.filter((event) => event.disputId === id)
    const filteredEvents = myDisputEvents.sort((a, b) => a.start > b.start ? 1 : -1)
    
    const [lastEventsMenuOpen, setLastEventsMenuOpen] = useState(true);
    const handeOpenLastEventsClick = () => setLastEventsMenuOpen(value => !value)
    
  return (
    <div className={classes.root}>
         <div className={classes.header}>
            <div className={classes.task}>
                <div className={classes.task}>
                    {cas}
                </div>
                <div className={classes.description}>
                    <div className={classes.title}>
                        {myCase?.name}
                    </div>
                    <div className={classes.titleforKad}>
                        Номер дела:                          
                        <span className={classes.linkToKad} onClick={() => window.open(`https://kad.arbitr.ru/Card?number=${myCase?.number}`)}>
                        {myCase?.number}
                        </span>
                    </div>
                <div>
                        Стоимость часа: {lawyerBillingPrice}
                    </div>
                    <div>
                        Стоимость судебного заседания: {courtPrice}
                    </div>
                    <div>
                        Сумма выставления счета {billSum}
                    </div>
                </div>
            </div>
            <div className={classes.clientRoot}>
                <div className={classes.client}>
                    {name}
                    <div className={cx(classes.tagMark, 
                        teg === 'Взыскание задолженности' && classes.tagMarkDolgi,
                        teg === 'Деликты' && classes.tagMarkDelicti,
                        teg === 'Банкротство' && classes.tagMarkBancrot,
                        teg === 'Недвижимость' && classes.tagMarkRealEstate,
                        teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
                        teg === 'Корпоративные споры' && classes.tagMarkKorporati,
                        teg === 'Налоговые споры' && classes.tagMarkNalogi,
                        teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
                        )}>
                        {teg}
                    </div>
                    <div className={classes.clientDescription}>
                        {/* Сумма к биллингу {totalPrice} <br /> */}
                    </div>
                </div>
                <ButtonBox 
                handleOpenTaskInfoClick={handleOpenTaskInfoClick}
                handleCompleteCaseClick={handleCompleteCaseMenuOpenClick}
                handleOpenInfoMenuClick={handleOpenInfoMenuClick}
                handleOpenChangeMenuClick={handleOpenChangeMenuClick}
                handleArchiveCaseClick={handleOpenArchiveMenuClick}
                handleOpenDeleteMenuClick={handleOpenDeleteMenuClick} 
                handleOpenCalendarClick={handleOpenCalendarClick}
                handeOpenLastEventsClick={handeOpenLastEventsClick}
                fatherId={id}

                />
            </div>
        </div>

        {/* Завершить кейс */}
        <div className={cx(classes.popUp, completeCaseMenuOpen === true && classes.popUpClose)}>
            <div className={classes.completedMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleCompleteCaseMenuOpenClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.annotationTitle}>
                    Завершить дело
                </div>
                <div className={classes.completeCase}>
                    <textarea wrap='true' className={classes.myArea} value={inputValue} onChange={e => setInputValue(e.target.value)}  name="" id="" placeholder='Введите заключение'></textarea>
                </div>
                <button className={classes.buttonComplete} onClick={handleCompleteCase}>
                    Завершить
                </button>
            </div>
        </div>
                {/* Прошлые заседания */}
                <div className={cx(classes.popUp, lastEventsMenuOpen === true && classes.popUpClose)}>
            <div className={classes.lastEventsMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handeOpenLastEventsClick}>
                    <RiCloseFill size={22} />
                </button>
                <CompletedEventsMenu />
            </div>
        </div>

        {/* Открыть календарь */}
        <div className={cx(classes.popUp, calendarMenuOpen === true && classes.popUpClose)}>
            <div className={cx(classes.calendarMenu, customEventList.length === 0 && classes.clandarSmallMenu)} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenCalendarClick}>
                    <RiCloseFill size={22} />
                </button>
                {customEventList.length ? (<Calendar
                localizer={localizer}
                events={customEventList}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 'calc(100% - 50px)'}}
                views={{
                    month: true, 
                    agenda: true
                }}
                messages={{
                    month: 'Месяц',
                    work_week: 'Рабочая неделя',
                    date: 'Дата',
                    time: 'Время',
                    event: 'Событие',
                    previous: 'Назад',
                    next: 'Вперед',
                    today: 'Сегодня',
                    agenda: 'Сводка',
                    noEventsInRange: 'В указанный период заседания отсутствуют'
                }}
                />) : (
                    <div className={classes.alertMenu}>
                    <div className={classes.alertText}>
                        <FaRegCalendarTimes size={50} />
                        <div className={classes.alertTitle}>
                            Судебных заседаний на ближайший месяц нет                           
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>

        {/* Доп Информация о кейсе */}
        <div className={cx(classes.popUp, informationMenuOpen === true && classes.popUpClose)} >
            <div className={classes.infoMenu} onClick={e => e.stopPropagation()}>
                <MoreInformationEditMenu 
                handleOpenInfoMenuClick={handleOpenInfoMenuClick} 
                annotation={annotation} 
                plan={plan}
                fatherID={id}
                data={data}
                />
            </div>
        </div>

        {/* Завершенные задачи */}
        <div className={cx(classes.popUp, taskMenuOpen === true && classes.popUpClose)} >
            <div className={classes.taskMenu} onClick={e => e.stopPropagation()}>
                <CompletedTasksScreen disputId={id} handleOpenTaskInfoClick={handleOpenTaskInfoClick} data={data} lawyers={lawyers} />
            </div>
        </div>

        {/* Архивировать кейс */}
        <div className={cx(classes.popUp, archiveMenuOpen === true && classes.popUpClose)} onClick={handleOpenArchiveMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenArchiveMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите перенести в архив дело "{name}"?
                <div className={classes.deleteButtons}>
                    <button className={classes.button} onClick={handleArchiveCaseClick}>Да</button> <button className={classes.button} onClick={handleOpenArchiveMenuClick}>Нет</button>
                </div>
            </div>
        </div>

        {/* Удалить Кейс */}
        <div className={cx(classes.popUp, deleteMenuOpen === true && classes.popUpClose)} onClick={handleOpenDeleteMenuClick}>
            <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenDeleteMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Вы уверены что хотите удалить спор "{name}"?
                <div className={classes.deleteButtons}>
                    <button className={classes.button} onClick={handleDeleteCaseClick}>Да</button> <button className={classes.button} onClick={handleOpenDeleteMenuClick}>Нет</button>
                </div>
            </div>
        </div>

        {/* Изменить Кейс */}
        <div className={cx(classes.popUp, changeMenuOpen === true && classes.popUpClose)}>
            <div className={classes.changeCaseMenu} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenChangeMenuClick}>
                    <RiCloseFill size={22} />
                </button>
                Изменить спор "{name}"
                <ChangeDisputForm
                    data={data}
                    handleClose={handleOpenChangeMenuClick}
                />
            </div>
        </div>

        <div className={classes.section}>
            <div className={classes.users}>
                <div className={classes.userBox}>
                {magicUser.map((user) => (
                    <UserCardForDisput data={user} myDisput={data} userId={user.id} key={user.id}/>
                ))}
                <div className={classes.addLawyer} onClick={handleAddUserClick}>
                    <BsPlusCircleFill size={40}  />
                </div>
                </div>
            </div>
            <div className={classes.calendar}>
                {filteredEvents.map((event) => (
                    <EventForDisput data={event} key={event.id} />
                ))}
                <AddEventComponent disputId={id} />
            </div>
        </div> 
        <div className={classes.inputBlock}>
                        <div className={classes.inputBox}>
                        <AddTodoComponent id={id} />

                        </div>
                        {/* <div className={classes.inputBox}>
                        <div>
                        <Select
                            isClearable={false}
                            defaultValue={{label: 'Выберете услугу', value: ''}}
                            className={classes.select}
                            classNamePrefix="select"
                            options={selectOptions}
                            onChange={(chioce) => setUserChoice(chioce)}
                        />
                        </div>
                        <BillInput 
                            value={billCount}
                            type={number}
                            handleInput={setBillCountStr}
                            placeholder={0} 
                        />
                        <button onClick={handleAddBillingItemToDispute} className={classes.button} >Добавить</button>
                        <button  className={cx(classes.addButtonHide, totalPrice >= billSum && classes.addButton)}>Счет</button>
                        </div>       */}
        </div>
        {/* Добавить билиинг не из сущетвующего */}
        {/* <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={handleOpenCloseClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleOpenCloseClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.popUpInput}>
                    <input 
                    value={userAddChoice}
                    className={classes.input}
                    placeholder='Что сделано'
                    onChange={(e) => setUserAddChoice(e.target.value)}
                    onKeyDown={handleBillKeyPress}
                    />
                    <button className={classes.button} onClick={handleAddCustomBillingItemToDispute}>Добавить</button>
                </div>
            </div>
        </div> */}
        <div className={classes.content}>
            <div className={classes.todos}>
                {unCompletedTasks.length ? (<div>
                    Задачи
                    {unCompletedTasks.map((task) => (
                        <TodoItem clientId={clientId} lawyerBillingPrice={lawyerBillingPrice} data={task} key={task.id} />  
                    ))}
                </div>) : null}

                {completedTasks.length ? (<div>
                    Выполненные задачи
                    {completedTasks.map((task) => (
                        <TodoItem clientId={clientId} data={task} key={task.id} />  
                    ))}
                </div>) : null}

            </div>
            <div className={classes.todos}>
                Биллинг
                <div>{myBillingItems.map((item) => (
                    <BillingItem data={item} key={item.id} />
                ))}</div>
            </div>
        </div>
        
        <div className={cx(classes.popUp, addUserOpen === true && classes.popUpClose)} onClick={handleAddUserClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
            <button className={classes.closeIcon} onClick={handleAddUserClick}>
                    <RiCloseFill size={22} />
                </button>
                <div className={classes.popUpInput}>
                    <Select
                        defaultValue={{label: 'Добавить пользователя', value: ''}}
                        name="colors"
                        options={addUsersArray}
                        className={classes.select}
                        classNamePrefix="select"
                        onChange={(chioce) => setUserOptionChoice(chioce)}
                    />
                    <button onClick={handleAddUserDispatchClick} className={classes.button}>Добавить</button>
                </div>
            </div>
        </div>
    </div>
  )
}
