import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles"; 
import type { ISourceOptions } from "tsparticles-engine";

export const MyBackground = () => {
  const particlesInit = useCallback(async (engine: any) => {
    await loadFull(engine)
}, []);

const particlesLoaded = useCallback(async (container: any) => {

}, []);
const options:ISourceOptions = {
    "fullScreen": {
      'enable': true,
      'zIndex': 0
    },
    background: {
        color: {
            value: "transparent",
        },
    },
    fpsLimit: 120,
    interactivity: {
        events: {
            onClick: {
                enable: true,
                mode: "push",
            },
            onHover: {
                enable: true,
                mode: "repulse",
            },
            resize: true,
        },
        modes: {
            push: {
                quantity: 5,
            },
            repulse: {
                distance: 150,
                duration: 0.4,
            },
        },
    },
    particles: {
        color: {
            value: "#525252",
        },
        links: {
            color: "#a3a3a3",
            distance: 200,
            enable: true,
            opacity: 0.5,
            width: 1,
        },
        move: {
            direction: "none",
            enable: true,
            outModes: {
                default: "bounce",
            },
            random: false,
            speed: 3,
            straight: false,
        },
        number: {
            density: {
                enable: true,
                area: 800,
            },
            value: 80,
        },
        opacity: {
            value: 1,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 1, max: 5 },
        },
    },
    detectRetina: true,
}

// const snowOptions:ISourceOptions = {
//     name: "Snow",
//     particles: {
//         number: {
//             value: 1500,
//             density: {
//                 enable: true,
//             },
//         },
//         color: {
//             value: "#fff",
//         },
//         shape: {
//             type: "circle",
//         },
//         opacity: {
//             value: 1,
//         },
//         size: {
//             value: 5,
//         },
//         move: {
//             enable: true,
//             speed: 4,
//             direction: "bottom",
//             straight: true,
//         },
//         wobble: {
//             enable: true,
//             distance: 10,
//             speed: 30,
//         },
//         zIndex: {
//             value: {
//                 min: 0,
//                 max: 100,
//             },
//             opacityRate: 10,
//             sizeRate: 10,
//             velocityRate: 10,
//         },
//     },
//     background: {
//         color: "#262626",
//     },
// };

  return (
    <Particles 
    id="tsparticles"
    init={particlesInit}
    loaded={particlesLoaded}
    options={options}
    />
  )
}
