import {FC, useState, useCallback} from 'react';
import { AddCaseFormProps } from './intrface';
import classes from './addcaseform.module.scss'
import { MyInput } from '../MyInput';
import Select from 'react-select'
import { selectTagsOptions } from '../../api/data';
import { useHandleAddNewCases } from '../../hooks/useNewCases';
import { useFindMyUser } from '../../hooks/useUsers';

export const AddCaseForm: FC <AddCaseFormProps> = (props) => {
    const {handleClose, clientId} = props;

    const [caseName, setCaseName] = useState<string>('');
    const [caseNumber, setCaseNumber] = useState<string>('');
    const [tagtoCase, setTagtoCase] = useState<any>('')
    const [caseType, setCaseType] = useState<any>('')
    const [lawType, setLawType] = useState<any>([] as any)
    const [sudType, setSudType] = useState<any>([] as any)

    const myCaseTag = tagtoCase.value
    const myUser = useFindMyUser()
    const setCustomCaseType = () => {
        if(caseType.value === 'otcenka') {
            let customCaseType = 'otcenka' 
            return customCaseType
        }
        if(caseType.value === 'kad') {
            let customCaseType = 'kadastr' 
            return customCaseType
        }
        if(lawType.value === 'abonent') {
            let customCaseType = 'abonent' 
            return customCaseType
        }
        if(sudType.value === 'arbitr') {
            let customCaseType = 'sudArbitr' 
            return customCaseType
        }
        if(sudType.value === 'simple') {
            let customCaseType = 'sudSimple' 
            return customCaseType
        }
        if(sudType.value === 'bancrot') {
            let customCaseType = 'sudBancrot' 
            return customCaseType
        }
        else {let customCaseType = 'nd' 
        return customCaseType}
    }

    const customCaseType = setCustomCaseType()

    const addCase = useHandleAddNewCases(
        clientId,
        caseName,
        myCaseTag,
        caseNumber,
        customCaseType,
        myUser
    )

    const handleClick = useCallback(() => {
        if (myCaseTag) {
            addCase()
            handleClose()
            setCaseName('')
            setCaseNumber('')
            setTagtoCase({label: '', value: ''})
        } else {alert('Добавьте Тэг')}
    }, [addCase, handleClose, myCaseTag])   

    const caseOptions = [
        { value: "law", label: "Юридическое дело" },
        { value: "otcenka", label: "Оценка" },
        { value: "kad", label: "Кадастровые работы" },
    ]

    const lawOptions = [
        { value: "abonent", label: "Абонентское обслуживание" },
        { value: "sud", label: "Судебное дело" },
    ]

    const sudOptions = [
        { value: "arbitr", label: "Арбитражное судопроизводство" },
        { value: "simple", label: "Общая юрисдикция" },
        { value: "bancrot", label: "Банкротство" },
    ]


  return (
    <div className={classes.root}>
        <div className={classes.content}>
        <div className={classes.title}>
                Добавить дело 
        </div>
                    Выбор дела
                    <Select
                        isClearable={false}
                        className={classes.select}
                        classNamePrefix="select"
                        options={caseOptions}
                        onChange={(chioce) => setCaseType(chioce)} 
                        defaultValue={{label: '', value: ''}}
                    />
                    {caseType.value === 'law' ? (<>
                        Тип дела
                        <Select
                            isClearable={false}
                            className={classes.select}
                            classNamePrefix="select"
                            options={lawOptions}
                            onChange={(chioce) => setLawType(chioce)} 
                            defaultValue={{label: '', value: ''}}
                        />
                    </>): null}

                    {lawType.value === 'sud' ? (<>
                        Вид дела
                        <Select
                            isClearable={false}
                            className={classes.select}
                            classNamePrefix="select"
                            options={sudOptions}
                            onChange={(chioce) => setSudType(chioce)} 
                            defaultValue={{label: '', value: ''}}
                        />
                    </>) : null}

                    {caseType.value === 'otcenka' || caseType.value === 'kad' || lawType.value === 'abonent' || sudType.value ? (<>
                        <MyInput 
                            value={caseName}
                            type={'text'}
                            handleInput={setCaseName}
                            inputName={'Название дела'}
                            placeholder={'Введите текст'} 
                        />
                        {lawType.value === "sud" ? (<MyInput 
                            value={caseNumber}
                            type={'text'}
                            handleInput={setCaseNumber}
                            inputName={'Номер дела'}
                            placeholder={'Введите номер'} 
                        />) : null}
                        Тэг
                        <Select
                        isClearable={false}
                        className={classes.select}
                        classNamePrefix="select"
                        options={selectTagsOptions}
                        onChange={(chioce) => setTagtoCase(chioce)} 
                        defaultValue={{label: '', value: ''}}
                        />
                        <button className={classes.button} onClick={handleClick}>Добавить</button>
                    </>) : null}


                    {/* <MyInput 
                        value={clientPaymentSystem}
                        type={'text'}
                        handleInput={setClientPaymentSystem}
                        inputName={'Система оплаты'}
                        placeholder={'Выберете из предложенного'} 
                    />
                    <MyInput 
                        value={clientBillSum}
                        type={'number'}
                        handleInput={setClientBillSum}
                        inputName={'Сумма выставления счета'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={clientCourtPrice}
                        type={'number'}
                        handleInput={setClientCourtPrice}
                        inputName={'Стоимость судебного заседания'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={clientLawyerPrice}
                        type={'number'}
                        handleInput={setClientLawyerPrice}
                        inputName={'Стоимость часа работы юриста'}
                        placeholder={'Введите сумму'} 
                    />
                    <MyInput 
                        value={clientCompensationSystem}
                        type={'text'}
                        handleInput={setClientCompensationSystem}
                        inputName={'Способ компенсации накладных расходов'}
                        placeholder={'Введите текст'} 
                    /> */}

        </div>
    </div>
  )
}
