import {FC} from 'react'
import { BigEventInfoProps } from './interface'
import classes from './bigeventinfo.module.scss'
import { useAppSelector } from '../../store/reduxHooks'
import cx from 'classnames';
import dayjs from 'dayjs';

export const BigEventInfo: FC<BigEventInfoProps> = (props) => {
    const {data} = props
    const {conclusion, title, start, end, lawyer} = data
    const users = useAppSelector(state => state.users.users)
    const myUser = users.find(user => user.id === lawyer)
    

  return (
    <div className={cx(classes.bigEventComponent, 
    conclusion === 'В старой версии не предусмотрена информация о результатах заседания' && classes.empty,
    dayjs().valueOf() < dayjs(end).valueOf() && classes.empty)}>
        <div className={classes.mainInfo}>
            <div className={classes.infoBlock}>
                <div className={classes.infoBlockTitle}>
                    Заседание:
                </div>
                <div className={classes.infoBlockInfo}>
                    {title}
                </div>
            </div>
            <div className={classes.infoBlock}>
                <div className={classes.infoBlockTitle}>
                    Ответственный:
                </div>
                <div className={classes.infoBlockInfo}>
                    <div className={cx(classes.specsSud, 
                        myUser?.specs === 'IT-отдел' && classes.specsIt,
                        myUser?.specs === 'CEO' && classes.specsCeo,
                        myUser?.specs === 'Ассистент CEO' && classes.specsCeo,
                        myUser?.specs === 'Бухгалтер' && classes.specsCeo,
                        myUser?.specs === 'Отдел консалтинга' && classes.specsAbonent,
                        myUser?.specs === 'Отдел оценки' && classes.specsOcenka,
                        myUser?.specs === 'Кадастровый отдел' && classes.specsKadastr)}
                    >
                        {myUser?.label}                    
                    </div>
                    
                </div>
            </div>
            <div className={classes.infoBlock}>
                <div className={classes.infoBlockTitle}>
                    Дата:
                </div>
                <div className={classes.infoBlockInfo}>
                    {dayjs(start).format('HH.mm DD.MM.YYYY')}
                </div>
            </div>
        </div>
        <div className={classes.conclusionInfo}>
            <div className={classes.infoBlockTitle}>
                Заключение:
            </div>
            <div className={classes.infoBlockInfo}>
                {conclusion}
            </div>
        </div>
    </div>  )
}
