import {createSlice} from '@reduxjs/toolkit';
import { BillingItem } from '../../api/interface';

interface BillingItemsState {
    billingItems: BillingItem[];
}
const initialState: BillingItemsState = {
    billingItems: [],
}

export const newBillingItemsSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewBillingItems(state, action) {
            return action.payload
        },
    }
})

export const {
    getNewBillingItems
} = newBillingItemsSlice.actions
export default newBillingItemsSlice.reducer