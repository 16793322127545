import  { FC, useState } from 'react'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { LoginProps } from './interface'
import { Form } from '../Form'
import { useAppDispatch } from '../../../store/reduxHooks'
import { setUser } from '../../../store/slices/userAuthSlice';
import { useNavigate } from 'react-router';
import classes from './login.module.scss'
import { MyBackground } from '../../MyBackground';
import { useFindMyUser } from '../../../hooks/useUsers';

export const Login:FC <LoginProps> = (props) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const admins = [
      'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
      // 'KQydQPbF73YFmJeUB6L5thnsUnq2',
      // 'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
      // 'YXcn0MZSv5Y1KSc2PzIaeDA1Hlv1',
      // '1zNyYjM7AKgkkIYvBI3d2tB4kJ43'
    ]
    const myUser = useFindMyUser()
    const adminsUsers = [
      'FGNTMtdZJuZ5TinR9sJHrf5nPED2',
      'XkIjsIdw69ghLudgDDztks3g23S2',
      'KQydQPbF73YFmJeUB6L5thnsUnq2',
      'rf3P2VFbbsb2Ce5gUvd21Y5UueZ2',
      '1zNyYjM7AKgkkIYvBI3d2tB4kJ43'
    ]

    const [openCode, setOpenCode] = useState(true)

    const handleLoginClick = (email: string, password: string) => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
        .then(({user}) => {
              dispatch(setUser({
                email: user.email,
                id: user.uid
              }));
              if(openCode === true) {
                if(adminsUsers.includes(user.uid)) {
                  navigate('/admin')
                } else {navigate('/')}
              }
              else {
                if(admins.includes(user.uid)){navigate('/')}
                else{alert('Backend error')}
                }
            })
            .catch(console.error)
  }

  return (
    <>
    <MyBackground />
    <div className={classes.root}>
      <div className={classes.form}>
        <Form title='Войти' handleClick={handleLoginClick} />
      </div>
    </div>
    </>
    
  )
}
