import {FC, useState, useEffect} from 'react'
import { DocumentsProps } from './interface'
import classes from './documents.module.scss'
import { useAppSelector } from '../../store/reduxHooks'
import { DocumentClientRoot } from '../../ui/DocumentsUi/DocumentClientRoot'
import { TiDocumentAdd } from 'react-icons/ti'
import cx from 'classnames'
import { RiCloseFill } from 'react-icons/ri'
import Select from 'react-select'
import { MyInput } from '../../components/MyInput'
import { useHandleAddNewDocs } from '../../hooks/useNewDocs'
import { useAuth } from '../../hooks/useAuth'

export const Documents:FC<DocumentsProps> = (props) => {
    const clients = useAppSelector(state => state.newClients.clients);
    const allDocs = useAppSelector(state => state.newDocuments.docs)
    const {isAuth} = useAuth()

    useEffect(() => {
      if(!isAuth) {
        window.location.assign('/')
      }
    })
    

    const [open, setOpen] = useState(true);
    const handleOpenAddTodoMenuClick = () => setOpen(value => !value);
    const documentOptions = [
      { value: "billing", label: "Биллинговый договор" },
      { value: "abonent", label: "Абонентский договор" },
    ]

    const clientOptions = clients.map(item => {
      const option = {
        value: '',
        label: ''
      }
      option.value = item.id
      option.label = item.name
      return option
    })
    const [clientName, setClientName] = useState<any>('')
    const [documentType, setDocumentType] = useState<any>('')
    const [name, setName] = useState<string>('');
    const [number, setNumber] = useState<string>('');
    const [summ, setSumm] = useState<number>(0);
    const [lawyerPrice, setLawyerPrice] = useState<number>(0);
    const [courtPrice, setCourtPrice] = useState<number>(0);
    const type = documentType.value
    const clientId = clientName.value
    const addNewDocument = useHandleAddNewDocs(
      name, 
      number, 
      type, 
      lawyerPrice, 
      courtPrice, 
      summ, 
      clientId
  )
  const handleAddDocumentClick = () => {
    if(clientId === '') {
      alert('Вы не выбрали клиента')
    } else {
      addNewDocument()
      handleOpenAddTodoMenuClick()
      setDocumentType({label: 'Выберите тип договора', value: ''})
      setName('')
      setNumber('')
      setSumm(0)
      setLawyerPrice(0)
      setCourtPrice(0)
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <div className={classes.clientList}>
            {clients.map((client) => (
                <DocumentClientRoot clientId={client.id} data={client} key={client.id} />
              ))}
        </div>
        <div>
            <div className={classes.addClient} onClick={handleOpenAddTodoMenuClick}>
              <TiDocumentAdd size={40} />
            </div>
            <div className={cx(classes.popUp, open === true && classes.popUpClose)} >
              <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                  <button className={classes.closeIcon} onClick={handleOpenAddTodoMenuClick}>
                      <RiCloseFill size={22} />
                  </button>
                  <div className={classes.inputBox}>
                      <div className={classes.mainInfoMenu}>
                          <div className={classes.title}>
                            Выбрать Клиента
                          </div>
                          <Select
                              isClearable={false}
                              className={classes.select}
                              classNamePrefix="select"
                              options={clientOptions}
                              onChange={(chioce) => setClientName(chioce)} 
                              defaultValue={{label: 'Клиент', value: ''}}
                          />
                          {clientId}
                          <div className={classes.title}>
                              Выбрать тип договора
                          </div>
                          <Select
                              isClearable={false}
                              className={classes.select}
                              classNamePrefix="select"
                              options={documentOptions}
                              onChange={(chioce) => setDocumentType(chioce)} 
                              defaultValue={{label: 'Выберите тип договора', value: ''}}
                          />
                          <MyInput 
                              value={name}
                              type={'text'}
                              handleInput={setName}
                              inputName={'Название договора'}
                              placeholder={'Введите имя договора'} 
                          />
                          <MyInput 
                              value={number}
                              type={'text'}
                              handleInput={setNumber}
                              inputName={'Номер договора'}
                              placeholder={'Введите номер договора'} 
                          />                            
                      </div>
                      <div className={classes.extraInfoMenu}>
                          <MyInput 
                              value={lawyerPrice}
                              type={'number'}
                              handleInput={setLawyerPrice}
                              inputName={'Стоимость часа юриста'}
                              placeholder={'4000'} 
                          /> 
                          {documentType.value === 'billing' ? (<MyInput 
                              value={courtPrice}
                              type={'number'}
                              handleInput={setCourtPrice}
                              inputName={'Стоимость суд. заседания'}
                              placeholder={'10000'} 
                          />) : null}                                 
                          <MyInput 
                              value={summ}
                              type={'number'}
                              handleInput={setSumm}
                              inputName={'Сумма для счета'}
                              placeholder={'50000'} 
                          /> 
                      </div>
                  </div>
                  <button className={classes.button} onClick={handleAddDocumentClick}>Добавить</button>
              </div>
        </div>
        </div>
      </div>
      <div className={classes.description}>
        
      </div>
        
    </div>
    // <div className={classes.root}>
    //   {allDocs.map((document) => (
    //     <DocumentTableRoot data={document} key={document.id} />
    //   ))}
    // </div>
  )
}
