import { FC, useState } from 'react'
import { useAppSelector } from '../../../store/reduxHooks'
import { CaseAdminItem } from '../CaseAdminItem'
import { ClientRootForAdminProps } from './interface'
import dayjs from 'dayjs';
import { TodoDashboardItem } from '../../MyDashboardUi/TodoDashboardItem';
import classes from './clientroot.module.scss'
import { useNavigate } from 'react-router';
import { MdArrowDropDown } from 'react-icons/md';
import cx from 'classnames';
import { TbFlag3Filled } from 'react-icons/tb';
import { useHandleChangeProrityToHardClients, useHandleChangeProrityToMediumClients, useHandleChangeProrityToSoftClients } from '../../../hooks/useNewClients';
import { BsPlusCircleFill } from 'react-icons/bs';
import { RiCloseFill } from 'react-icons/ri';
import { MyInput } from '../../../components/MyInput';
import { useHandleAddNewAdminForClientTasks } from '../../../hooks/useNewTasks';
import { useFindMyUser } from '../../../hooks/useUsers';

export const ClientRootForAdmin: FC<ClientRootForAdminProps> = (props) => {
  const {clientId, name, priority, data} = props

  const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
  const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
  const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
  const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
  const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
  const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
  const cases = [...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases, ...abonentCases, ...kadastrCases, ...otcenkaCases]
  const activeCases = cases.filter((cas) => cas.isActive === true && cas.clientId === clientId)
  const clientsTasks = useAppSelector(state => state.newClientTasks.tasks)
  const todayMidnight = dayjs().startOf('day').valueOf()

  const twoDaysAgo = todayMidnight - 604800000
  const myTasks = clientsTasks.filter((task) => task.fatherId === clientId)

  const foundedTasks = myTasks.filter((task) => task.completeTime > twoDaysAgo && task.isCompleted === true)
  const navigate = useNavigate()
  const [openClientRoot, setOpenClientRoot] = useState(false)
  const handleOpenClientRootClick = () => setOpenClientRoot(value => !value)
  
  const softPriority = useHandleChangeProrityToSoftClients(data)
  const mediumPriority = useHandleChangeProrityToMediumClients(data)
  const hardPriority = useHandleChangeProrityToHardClients(data)

  const handleSoftClick = () => {
    softPriority()
  }

  const handleMediumClick = () => {
    mediumPriority()
  }

  const handleHardClick = () => {
    hardPriority()
  }

  const [show, setShow] = useState(false)

  const [addUserOpen, setAddUserOpen] = useState(false)
  const handleAddTaskClick = () => setAddUserOpen(value => !value)

  const [newAdminTaskName, setNewAdminTaskName] = useState('')

  const myUser = useFindMyUser()

  const addTasks = useHandleAddNewAdminForClientTasks(newAdminTaskName, myUser, clientId)
  
  const handleAddNewAdminTaskClick = () => {
    addTasks()
    setNewAdminTaskName('')
    handleAddTaskClick()
  }
  const casesIds = activeCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

  const allIds = [...casesIds, clientId]
  const caseTodos = useAppSelector(state => state.newCaseTasks.tasks)
  const clientTodos = useAppSelector(state => state.newClientTasks.tasks)
  const allTodos = [...caseTodos, ...clientTodos]

  const timeNow = dayjs().valueOf()

  const myUncompletedThreeDaysTodos = allTodos.filter(task => allIds.includes(task.fatherId)  && task.isCompleted === false && task.mustFinishDate - 259200000 < timeNow)
  const myUncompletedOneDayTodos = allTodos.filter(task => allIds.includes(task.fatherId) && task.isCompleted === false && task.mustFinishDate - 86400000 < timeNow)

    
  return (
    <div className={cx(classes.root,
      myUncompletedThreeDaysTodos.length && classes.failedRoot,
      myUncompletedOneDayTodos.length && classes.trueFailedRoot,

    )}
    onMouseEnter={() => setShow(true)}
    onMouseLeave={() => setShow(false)}
    >
      <div className={classes.titleRoot}>
        <div className={classes.clientTitle} onClick={() => navigate(`/clients/${clientId}`)}>
          {name}
        </div>
        <div  className={cx(classes.iconRotate, openClientRoot === true && classes.iconRotateTrue )} onClick={handleOpenClientRootClick}>
          <MdArrowDropDown size={25} />
        </div>      
      </div>
      <div className={classes.flagRoot}>
        <div className={cx(classes.flagIconGreen, priority === 'soft' && classes.flagIconGreenActive)} onClick={handleSoftClick}>
          <TbFlag3Filled size={30}  />
        </div>
        <div className={cx(classes.flagIconYellow, priority === 'medium' && classes.flagIconYellowActive)} onClick={handleMediumClick}>
          <TbFlag3Filled size={30} />
        </div>
        <div className={cx(classes.flagIconRed, priority === 'hard' && classes.flagIconRedActive)} onClick={handleHardClick}>
          <TbFlag3Filled size={30}  />
        </div>
      </div>
      {openClientRoot === true ? (<>
        <div className={classes.smallTitle}>
        Дела:
        </div>
          {activeCases.map(item => (
            <CaseAdminItem 
              key={item.id} 
              caseId={item.id} 
              clientId={item.clientId} 
              caseType={item.caseType} 
              caseName={item.name} 
            />
          ))}
          {foundedTasks.length ? (
                  <>
                        <div className={classes.smallTitle}>
                        Задачи:
                        </div>
                  {foundedTasks.map((item) =>
                  <TodoDashboardItem key={item.id} data={item}/>
                  )}
                  </>
              ) : null}
      </>) : null}

      <div className={cx(classes.addTaskBlock, show === false && classes.addTaskBlockHide)} onClick={handleAddTaskClick}>
        <BsPlusCircleFill size={25} />
      </div>
  {/* Добавить задачу */}
          <div className={cx(classes.popUp, addUserOpen === false && classes.popUpClose)} onClick={handleAddTaskClick}>
            <div className={classes.form} onClick={e => e.stopPropagation()}>
                <button className={classes.closeIcon} onClick={handleAddTaskClick}>
                  <RiCloseFill size={22} />
                </button>
                <MyInput 
                  inputName={'Задача'} 
                  value={newAdminTaskName} 
                  handleInput={setNewAdminTaskName} 
                  placeholder={'Задача'} 
                  type={'text'} 
                />
                <button className={classes.inputButton} onClick={handleAddNewAdminTaskClick}>
                  Создать
                </button>
            </div>
        </div>
    </div>
  )
}
