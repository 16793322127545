import {FC} from 'react';
import { DisputesCaseRootProps } from './interface';
import classes from './disputescaseroot.module.scss'
import { DisputeItem } from '../DisputeItem';
import {RiInformationLine} from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames'
import { useAppSelector } from '../../../store/reduxHooks';

export const DisputeCaseRoot: FC<DisputesCaseRootProps> = (props) => {
  const {data, caseId} = props
  const {name} = data
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/cases/${caseId}`);
  }
  const {id} = useParams()
  const disputes = useAppSelector(state => state.newDisputes.disputes)
  const foundedDisput = disputes.find((disput) => disput.id === id)
  const activeDisputes = disputes.filter((disput) => disput.isActive === true)
  const myDisputes = activeDisputes.filter((disput) => disput.caseId === caseId)


  return (
    <div className={cx(classes.root, foundedDisput?.caseId === caseId  && classes.selectedRoot, !myDisputes.length && classes.emptyRoot)}>
        <div className={classes.title}>
            {name}
            <div className={cx(classes.icon, foundedDisput  && classes.selectedIcon)}>
            <RiInformationLine onClick={handleClick} size={25} />

            </div>
        </div>
        <div className={classes.list}>
        {myDisputes.map((cas) => (
            <DisputeItem disputeId={cas.id} data={cas} key={cas.id} />  
        ))}
        </div>

    </div>
  )
}
