import {FC, useState, useEffect} from "react";
import { BillsProps } from "./interface";
import classes from './bills.module.scss'
import { useAppSelector } from "../../store/reduxHooks";
import { BillTableItem } from "../../ui/BillUi/BillTableItem";
import cx from 'classnames'
import { RiCloseFill } from "react-icons/ri";
import { MyInput } from "../../components/MyInput";
import Select from 'react-select'
import { useHandleAddNewBill } from "../../hooks/useNewBills";
import dayjs from 'dayjs';
import { useHandleAddNewDocs } from "../../hooks/useNewDocs";
import DatePicker, {registerLocale} from 'react-datepicker'
import { useAuth } from "../../hooks/useAuth";

export const Bills: FC<BillsProps> = (props) => {
  const bills = useAppSelector(state => state.newBills.bills)
  console.log(bills)
  const arrayForSort = [...bills]
  const finalArray = arrayForSort.sort((a, b) => a.date < b.date ? 1 : -1)

  const {isAuth} = useAuth()

  useEffect(() => {
    if(!isAuth) {
      window.location.assign('/')
    }
  })

  const [startDate, setStartDate] = useState<any>(new Date());

  const [openEventMenu, setOpenEventMenu] = useState(true)
  const handleOpenAddEventMenu = () => setOpenEventMenu(value => !value)

  const clients = useAppSelector(state => state.newClients.clients)
  const clientsOptions = clients.map(item => {
    const option = {
      value: '',
      label: ''
    }
    option.value = item.id
    option.label = item.name
    return option
  })

  const [doc, setDoc] = useState<{label: string, value: string} | null>({label: '', value: ''})
  const [client, setClient] = useState<{label: string, value: string} | null>({label: '', value: ''})
  const documents = useAppSelector(state => state.newDocuments.docs)
  const filteredDocuments = documents.filter((doc) => doc.clientId === client?.value)
  const documentsOptions = filteredDocuments.map(item => {
    const option = {
      value: '',
      label: ''
    }
    option.value = item.id
    option.label = `${item.name} ${item.number}`
    return option
  })
  const docIdCheck = () => {
    if(doc) {
      let docId = doc.value
      return docId
    } else {
      let docId = ''
      return docId
    }
  }
  const docId = docIdCheck()

  const docNameCheck = () => {
    if(doc) {
      let docId = doc.label
      return docId
    } else {
      let docId = ''
      return docId
    }
  }
  const docName = docNameCheck()

  const todayBills = bills.filter((bill) => dayjs(bill.date).valueOf() > dayjs().startOf('day').valueOf() && dayjs(bill.date).valueOf() < dayjs().endOf('day').valueOf())
  const dateFix = todayBills.length + 1

  const [billNumber, setBillNumber] = useState<string>('')
  const [billBase, setBillBase] = useState<string>('')
  const [billSumm, setBillSumm] = useState<number>(0)
  const billDate = dayjs(startDate).valueOf() + dateFix



  const addBill = useHandleAddNewBill(billNumber, docId, billDate, billSumm, billBase )
  const handleClick = () => {
    if(docId !== '') {
      addBill()
      handleOpenAddEventMenu()
      } else {
        alert('Выберите договор')
      }
  }
  

  const [open, setOpen] = useState(true);
  const handleOpenAddTodoMenuClick = () => setOpen(value => !value);
  const documentOptions = [
    { value: "billing", label: "Биллинговый договор" },
    { value: "abonent", label: "Абонентский договор" },
  ]
  const clientOptions = clients.map(item => {
    const option = {
      value: '',
      label: ''
    }
    option.value = item.id
    option.label = item.name
    return option
  })
  const [clientName, setClientName] = useState<any>('')
  const [documentType, setDocumentType] = useState<any>('')
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [summ, setSumm] = useState<number>(0);
  const [lawyerPrice, setLawyerPrice] = useState<number>(0);
  const [courtPrice, setCourtPrice] = useState<number>(0);
  const type = documentType.value
  const clientId = clientName.value
  const addNewDocument = useHandleAddNewDocs(
    name, 
    number, 
    type, 
    lawyerPrice, 
    courtPrice, 
    summ, 
    clientId,
  )
  const handleAddDocumentClick = () => {
    if(clientId === '') {
      alert('Вы не выбрали клиента')
    } else {
      addNewDocument()
      handleOpenAddTodoMenuClick()
      setDocumentType({label: 'Выберите тип договора', value: ''})
      setName('')
      setNumber('')
      setSumm(0)
      setLawyerPrice(0)
      setCourtPrice(0)
      setStartDate(new Date())
    }
  }

  const janBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '01.24')
  const janSumm = janBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const febBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '02.24')
  const febSumm = febBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const marBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '03.24')
  const marSumm = marBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const aipBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '04.24')
  const aipSumm = aipBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const mayBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '05.24')
  const maySumm = mayBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const junBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '06.24')
  const junSumm = junBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const julBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '07.24')
  const julSumm = julBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const augBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '08.24')
  const augSumm = augBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const sepBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '09.24')
  const sepSumm = sepBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const octBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '10.24')
  const octSumm = octBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const nowBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '11.24')
  const nowSumm = nowBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)

  const decBills = finalArray.filter((bill) => dayjs(bill.date).format('MM.YY') === '12.24')
  const decSumm = decBills.reduce<number>((acc, arrayItem) => acc + +arrayItem.summ, 0)



return (
    <div className={classes.root}>
      <div className={classes.addBillRoot}>
      <button className={classes.addButton} onClick={handleOpenAddTodoMenuClick}>
            Добавить договор
          </button>
        <button className={classes.addButton} onClick={handleOpenAddEventMenu}>
            Добавить счет
          </button>
        {/* Добавить Счет */}
        <div className={cx(classes.popUp, openEventMenu === true && classes.popUpClose)}>
          <div className={classes.form} onClick={e => e.stopPropagation()}>
              <button className={classes.closeIcon} onClick={handleOpenAddEventMenu}>
                  <RiCloseFill size={22} />
              </button>       
              <div>
              Плательщик
              <Select
                isClearable={false}
                className={classes.select}
                classNamePrefix="select"
                options={clientsOptions}
                onChange={(chioce) => setClient(chioce)} 
                defaultValue={{label: 'Клиент', value: ''}}
              />
                Выберите договор
              <Select
                isClearable={false}
                className={classes.select}
                classNamePrefix="select"
                options={documentsOptions}
                onChange={(chioce) => setDoc(chioce)} 
                defaultValue={{label: 'Договор', value: ''}}
              />
              <DatePicker 
                locale='ru'
                dateFormat='dd.MM.yyyy'
                selected={startDate} 
                onChange={(date) => setStartDate(date)} 
              />
                <MyInput inputName={"Номер счета"} value={billNumber} handleInput={setBillNumber} placeholder={'Введите номер счета'} type={"text"} />
                <MyInput inputName={"Основание платежа"} value={billBase} handleInput={setBillBase} placeholder={'Введите основание платежа'} type={"text"} />
                <MyInput inputName={"Сумма"} value={billSumm} handleInput={setBillSumm} placeholder={0} type={"number"} />
                <button className={classes.confirmButton} onClick={handleClick}>
                  Добавить
                </button>
              </div>   
          </div>
        </div>      
        {/* Добавить договор */}
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} >
              <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
                  <button className={classes.closeIcon} onClick={handleOpenAddTodoMenuClick}>
                      <RiCloseFill size={22} />
                  </button>
                  <div className={classes.inputBox}>
                      <div className={classes.mainInfoMenu}>
                          <div className={classes.title}>
                            Выбрать Клиента
                          </div>
                          <Select
                              isClearable={false}
                              className={classes.select}
                              classNamePrefix="select"
                              options={clientOptions}
                              onChange={(chioce) => setClientName(chioce)} 
                              defaultValue={{label: 'Клиент', value: ''}}
                          />
                          {clientId}
                          <div className={classes.title}>
                              Выбрать тип договора
                          </div>
                          <Select
                              isClearable={false}
                              className={classes.select}
                              classNamePrefix="select"
                              options={documentOptions}
                              onChange={(chioce) => setDocumentType(chioce)} 
                              defaultValue={{label: 'Выберите тип договора', value: ''}}
                          />
                          <MyInput 
                              value={name}
                              type={'text'}
                              handleInput={setName}
                              inputName={'Название договора'}
                              placeholder={'Введите имя договора'} 
                          />
                          <MyInput 
                              value={number}
                              type={'text'}
                              handleInput={setNumber}
                              inputName={'Номер договора'}
                              placeholder={'Введите номер договора'} 
                          />                            
                      </div>
                      <div className={classes.extraInfoMenu}>
                          <MyInput 
                              value={lawyerPrice}
                              type={'number'}
                              handleInput={setLawyerPrice}
                              inputName={'Стоимость часа юриста'}
                              placeholder={'4000'} 
                          /> 
                          {documentType.value === 'billing' ? (<MyInput 
                              value={courtPrice}
                              type={'number'}
                              handleInput={setCourtPrice}
                              inputName={'Стоимость суд. заседания'}
                              placeholder={'10000'} 
                          />) : null}                                 
                          <MyInput 
                              value={summ}
                              type={'number'}
                              handleInput={setSumm}
                              inputName={'Сумма для счета'}
                              placeholder={'50000'} 
                          /> 
                      </div>
                  </div>
                  <button className={classes.button} onClick={handleAddDocumentClick}>Добавить</button>
              </div>
        </div>
      </div>
      <div className={classes.billRoot}>
            <div className={classes.infoBlock}>
              <div>
                  Номер
              </div>
              <div className={classes.dateBlock}>
                  Дата
              </div>
            </div>
            <div className={classes.infoBlock}>
            Клиент
            </div>
            <div className={classes.infoBlock}>
            Договор
            </div>
            <div className={classes.infoBlock}>
            Основание
            </div>
            <div className={classes.infoSummBlock}>
            Сумма
            </div>

            <div className={classes.isPayedBlock}>
            Статус
            </div>
            <div className={classes.isActedBlock}>
            Акт
            </div>
        </div>


        <div className={classes.billListRoot}>
          <div className={cx(classes.monthBillList, !decBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Декабрь
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {decSumm} ₽
              </div>
              <div>
                {decBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>   

            <div className={cx(classes.monthBillList, !nowBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Ноябрь
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {nowSumm} ₽
              </div>
              <div>
                {nowBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>  

            <div className={cx(classes.monthBillList, !octBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Октябрь
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {octSumm} ₽
              </div>
              <div>
                {octBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div> 

            <div className={cx(classes.monthBillList, !sepBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Сентябрь
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {sepSumm} ₽
              </div>
              <div>
                {sepBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div> 

            <div className={cx(classes.monthBillList, !augBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Август
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {augSumm} ₽
              </div>
              <div>
                {augBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div> 

            <div className={cx(classes.monthBillList, !julBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Июль
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {julSumm} ₽
              </div>
              <div>
                {julBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div> 

            <div className={cx(classes.monthBillList, !junBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Июнь
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {junSumm} ₽
              </div>
              <div>
                {junBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>

            <div className={cx(classes.monthBillList, !mayBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                    Май
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {maySumm} ₽
              </div>
              <div>
                {mayBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>

            <div className={cx(classes.monthBillList, !aipBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Апрель
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {aipSumm} ₽
              </div>
              <div>
                {aipBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>

            <div className={cx(classes.monthBillList, !marBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Март
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {marSumm} ₽
              </div>
              <div>
                {marBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>

            <div className={cx(classes.monthBillList, !febBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Февраль
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {febSumm} ₽              
              </div>
              <div>
                {febBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>

            <div className={cx(classes.monthBillList, !janBills.length && classes.monthBillListHide)}>
              <div className={classes.monthTitleBlock}>
                <div className={classes.lineBlock}></div>
                <div className={classes.monthTitle}>
                  Январь
                </div>
                <div className={classes.lineBlock}></div>
              </div>
              <div className={classes.summ}>
                {janSumm} ₽
              </div>
              <div>
                {janBills.map((bill) => (<BillTableItem key={bill.id} data={bill} />))}
              </div>
            </div>

        </div>

    </div>

  )
}
