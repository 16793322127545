import {createSlice} from '@reduxjs/toolkit';
import { Task } from '../../api/interface';

interface TaskState {
    tasks: Task[];
}
const initialState: TaskState = {
    tasks: [],
}

export const newDisputTaskSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewDisputTasks(state, action) {
            return action.payload
        },

    }
})

export const {
    getNewDisputTasks
} = newDisputTaskSlice.actions
export default newDisputTaskSlice.reducer