import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Client } from '../../api/interface';
import { AddCaseMessagePayload, AddCasePayload, AddClientPayload, AddContactPayload, AddTodoPayload, DeleteCasePayload, DeleteTodoPayload, AddBillingItemPayload, AddBillingItemSpecialPayload, AddUserInCasePayload, ChangeCaseProps, ArchiveCaseProps, ChangeClientProps, CompleteCasePayload, ArchivedCasePayload, ChangeAnnotationPayload, AddBillandDeleteBillingItemsPayload, ToggleTodoPayload, AddDisputePayload } from '../../api/payloadInterface';
import { v4 as uuid } from 'uuid';

const initialState: Client[] = []

export const clientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers:{
        getClients(state, action) {
            return action.payload
          },
        // addClient(state, action: PayloadAction<AddClientPayload>) {
        //     state.unshift({
        //         id: '',
        //         name: action.payload.clientName,
        //         phone: action.payload.clientPhone,
        //         inn: action.payload.clientINN,
        //         ogrn: action.payload.clientOGRN,
        //         kpp: action.payload.clientKPP,
        //         isActive: true,
        //         isArchived: false,
        //         isDeleted: false,
        //         contacts: [],
        //         cases: [],
        //         clientType: action.payload.clientType
        //     })
        // },
        // addCase(state, action: PayloadAction<AddCasePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
            
        //     const UniqeId = uuid();
        //     if (action.payload.creatorUserId !== null) {
        //         state[clientIndex].cases.unshift({
        //             id: UniqeId,
        //             annotation: 'Здесь мы рассказываем о деле',
        //             plan: 'Здесь мы рассказываем как будем действовать',
        //             conclusion: '',
        //             clientId: action.payload.clientId,
        //             name: action.payload.caseName,
        //             tasks: [],
        //             isActive: true,
        //             isCompleted: false,
        //             isArchived: false,
        //             isDeleted: false,
        //             client: action.payload.clientName,
        //             missions: [],
        //             disputes: [],
        //             teg: action.payload.myCaseTag
        //         })
        //     }
        // },

        // addDispute(state, action: PayloadAction<AddDisputePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     )
        //     const UniqeId = uuid();
        //     if (action.payload.creatorUserId !== null) {
        //         state[clientIndex].cases[caseIndex].disputes.unshift({
        //             id: UniqeId,
        //             annotation: 'Здесь мы рассказываем о деле',
        //             plan: 'Здесь мы рассказываем как будем действовать',
        //             conclusion: '',
        //             clientId: action.payload.clientId,
        //             name: action.payload.disputeName,
        //             lawyers: [action.payload.myUserId],
        //             tasks: [],
        //             isActive: true,
        //             isCompleted: false,
        //             isArchived: false,
        //             isDeleted: false,
        //             missions: [],
        //             teg: action.payload.myCaseTag,
        //             cas: '',
        //             caseId: action.payload.caseId,
        //             number: '',
        //             creator: action.payload.myUserId,
        //             compensationSystem: action.payload.clientCompensationSystem,
        //             paymentsystem: action.payload.clientPaymentSystem,
        //             lawyerPrice: action.payload.clientLawyerPrice,
        //             courtPrice: action.payload.clientCourtPrice,
        //             billSum: action.payload.clientBillSum,
        //             billingItems: [],
        //             bills: []
        //         })
        //     }
            
            
        // },

        // addCaseMessage(state, action: PayloadAction<AddCaseMessagePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.bestID
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     const taskIndex = state[clientIndex].cases[caseIndex].tasks.findIndex(
        //         (task) => task.id === action.payload.taskId
        //     );
        //     const UniqeId = new Date().toISOString();
        //         state[clientIndex].cases[caseIndex].tasks[taskIndex].comments.push({
        //             id: UniqeId,
        //             text: action.payload.text,
        //             user: '1'
        //     });
        // },
        // addContact(state, action: PayloadAction<AddContactPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const UniqeId = new Date().toISOString();
        //     state[clientIndex].contacts.push({
        //         id: UniqeId,
        //         name: action.payload.contactName,
        //         job: action.payload.contactJob,
        //         phone: action.payload.contactPhone,
        //         mail: action.payload.contactMail
        //     })
        // },
        // addTodo(state, action: PayloadAction<AddTodoPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     const disputIndex = state[clientIndex].cases[caseIndex].disputes.findIndex(
        //         (disput) => disput.id === action.payload.disputId
        //     );
        //     const UniqeId = new Date().toISOString();
        //     const CommentId = UniqeId + 1;
        //     state[clientIndex].cases[caseIndex].disputes[disputIndex].tasks.unshift({
        //         id: UniqeId,
        //         title: action.payload.todo,
        //         isCompleted: false,
        //         comments: [
        //             {
        //                 id: CommentId,
        //                 text: `Пользователь ${action.payload.creator} создал задачу ${action.payload.todo}`,
        //                 user: '2',
        //             }
        //         ],
        //         caseId: action.payload.caseId,
        //         disputId: action.payload.disputId
        //     })
        // },
        // toggleTodo (state, action: PayloadAction <ToggleTodoPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     const disputIndex = state[clientIndex].cases[caseIndex].disputes.findIndex(
        //         (disput) => disput.id === action.payload.disputId
        //     );
        //     if (caseIndex > -1) {
        //         const toggledTodo = state[clientIndex].cases[caseIndex].disputes[disputIndex].tasks.find(
        //             (todo) => todo.id === action.payload.id
        //         );
        //         if (toggledTodo) {
        //             toggledTodo.isCompleted = !toggledTodo.isCompleted
        //         }
        //     }
        // },
        // deleteTodo (state, action: PayloadAction <DeleteTodoPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     const disputIndex = state[clientIndex].cases[caseIndex].disputes.findIndex(
        //         (disput) => disput.id === action.payload.disputId
        //     );
        //     if (caseIndex > -1) {
        //         state[clientIndex].cases[caseIndex].disputes[disputIndex].tasks = state[clientIndex].cases[caseIndex].disputes[disputIndex].tasks.filter(
        //             (task) => task.id !== action.payload.id
        //         )
        //     }
        // },
        // addBillingItem (state, action: PayloadAction<AddBillingItemPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     const disputIndex = state[clientIndex].cases[caseIndex].disputes.findIndex(
        //         (disput) => disput.id === action.payload.disputId
        //     );
        //     const UniqeId = new Date().toISOString();
        //     const billingTotalPrice = Number(action.payload.lawyerBillingPrice) * action.payload.billCount
        //     if (caseIndex > -1) {
        //         state[clientIndex].cases[caseIndex].disputes[disputIndex].billingItems.unshift({
        //             id: UniqeId,
        //             name: action.payload.billTitle,
        //             price: billingTotalPrice,
        //             hours: action.payload.billCount
        //         })
        //     }
        // },
        // addBillMainItem (state, action: PayloadAction<AddBillingItemSpecialPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     const disputIndex = state[clientIndex].cases[caseIndex].disputes.findIndex(
        //         (disput) => disput.id === action.payload.disputId
        //     )
        //     const UniqeId = new Date().toISOString();
        //     const billingTotalPrice = Number(action.payload.lawyerBillingPrice) * action.payload.billCount
        //     if (caseIndex > -1) {
        //         state[clientIndex].cases[caseIndex].disputes[disputIndex].billingItems.push({
        //             id: UniqeId,
        //             name: action.payload.userAddChoice,
        //             price: billingTotalPrice,
        //             hours: action.payload.billCount
        //         })
        //     }
        // },
        // addUserInCase (state, action: PayloadAction <AddUserInCasePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     const disputIndex = state[clientIndex].cases[caseIndex].disputes.findIndex(
        //         (disput) => disput.id === action.payload.disputId
        //     )
        //     const lawyerId = action.payload.addUserValue
        //     if(caseIndex > -1) {
        //         state[clientIndex].cases[caseIndex].disputes[disputIndex].lawyers.push(lawyerId)
        //     }
        // },
        // changeCase (state, action: PayloadAction<ChangeCaseProps>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.id
        //     ); 
        //     if(caseIndex > -1) {
        //         state[clientIndex].cases[caseIndex].name = action.payload.caseName
        //         // state[clientIndex].cases[caseIndex].task = action.payload.caseTask
        //         // state[clientIndex].cases[caseIndex].number = action.payload.caseNumber
        //         // state[clientIndex].cases[caseIndex].paymentsystem = action.payload.clientPaymentSystem
        //         // state[clientIndex].cases[caseIndex].billSum = action.payload.clientBillSum
        //         // state[clientIndex].cases[caseIndex].courtPrice = action.payload.clientCourtPrice
        //         // state[clientIndex].cases[caseIndex].lawyerPrice = action.payload.clientLawyerPrice
        //         // state[clientIndex].cases[caseIndex].compensationSystem = action.payload.clientCompensationSystem
        //     }
        // },
        // changeClient (state, action: PayloadAction<ChangeClientProps>){
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.id
        //     );
        //     if(clientIndex > -1) {
        //         state[clientIndex].name = action.payload.clientName
        //         state[clientIndex].phone = action.payload.clientPhone
        //         state[clientIndex].inn = action.payload.clientINN
        //         state[clientIndex].ogrn = action.payload.clientOGRN
        //         state[clientIndex].kpp = action.payload.clientKPP
        //     }
        // },
        // archiveCase (state, action: PayloadAction<ArchiveCaseProps>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     if(clientIndex > -1) {
        //         const archivedCase = state[clientIndex].cases.find(
        //             (cas) => cas.id === action.payload.id
        //         );
        //         if (archivedCase) {
        //             archivedCase.isArchived = true
        //             archivedCase.isActive = false
        //         } 
        //     }
        // },
        // deArchived (state, action: PayloadAction<ArchivedCasePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     if(clientIndex > -1) {
        //         const archivedCase = state[clientIndex].cases.find(
        //             (cas) => cas.id === action.payload.id
        //         );
        //         if (archivedCase) {
        //             archivedCase.isArchived = false
        //             archivedCase.isActive = true                } 
        //     }
        // },  
        // deleteCase(state, action: PayloadAction<DeleteCasePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     if(clientIndex > -1) {
        //         const deletedCase = state[clientIndex].cases.find(
        //             (cas) => cas.id === action.payload.id
        //         );
        //         if (deletedCase) {
        //             deletedCase.isActive = false
        //             deletedCase.isDeleted = true
        //         } 
        //     }
        // },
        // deDeleted (state, action: PayloadAction<ArchivedCasePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     if(clientIndex > -1) {
        //         const deletedCase = state[clientIndex].cases.find(
        //             (cas) => cas.id === action.payload.id
        //         );
        //         if (deletedCase) {
        //             deletedCase.isActive = true
        //             deletedCase.isDeleted = false
        //         } 
        //     }
        // },  
        // completeCase (state, action: PayloadAction<CompleteCasePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     if(clientIndex > -1) {
        //         const deletedCase = state[clientIndex].cases.find(
        //             (cas) => cas.id === action.payload.id
        //         );
        //         if (deletedCase) {
        //             deletedCase.isCompleted = true
        //             deletedCase.isActive = false
        //             deletedCase.conclusion = action.payload.inputValue
        //         } 
        //     }
        // },
        // deCompleted (state, action: PayloadAction<ArchivedCasePayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     if(clientIndex > -1) {
        //         const deletedCase = state[clientIndex].cases.find(
        //             (cas) => cas.id === action.payload.id
        //         );
        //         if (deletedCase) {
        //             deletedCase.isCompleted = false
        //             deletedCase.isActive = true
        //         } 
        //     }
        // },  
        // changeAnnotation (state, action: PayloadAction<ChangeAnnotationPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.caseId
        //     );
        //     if (caseIndex > -1) {
        //         state[clientIndex].cases[caseIndex].plan = action.payload.inputPlanValue
        //         state[clientIndex].cases[caseIndex].annotation = action.payload.inputAnnotationValue
        //     }
        // },
        // addBillandDeleteBillingItems (state, action: PayloadAction<AddBillandDeleteBillingItemsPayload>) {
        //     const clientIndex = state.findIndex(
        //         (client) => client.id === action.payload.clientId
        //     );
        //     const caseIndex = state[clientIndex].cases.findIndex(
        //         (cas) => cas.id === action.payload.id
        //     );
        //     const billingItems = state[clientIndex].cases[caseIndex].billingItems;
        //     const numberOfBillingItems = billingItems.length
        //     const removed = state[clientIndex].cases[caseIndex].billingItems.splice(0, numberOfBillingItems)
        //     const billingSumm = removed.reduce((acc, billItem) => acc + billItem.price, 0)
        //     const billingHours = action.payload.totalHours
        //     const UniqeId = uuid();

        //     if(caseIndex > -1) {
        //         state[clientIndex].cases[caseIndex].bills.push({
        //             id: UniqeId,
        //             billingItems: removed,
        //             totalSumm: billingSumm,
        //             totalHours: billingHours,
        //             teg: action.payload.teg,
        //             clientId: action.payload.clientId
        //         })
        //     }

        // }
    },   
})
export const {
    // addClient,
    // addContact, 
    // addCase, 
    // addDispute,
    // deleteCase,  
    // addCaseMessage, 
    // addTodo, 
    // deleteTodo, 
    // addBillingItem, 
    // addBillMainItem,
    // addUserInCase,
    // changeCase,
    // archiveCase,
    // changeClient,
    // completeCase,
    // deArchived,
    // deCompleted,
    // deDeleted,
    // changeAnnotation,
    // addBillandDeleteBillingItems,
    // toggleTodo,
    getClients
} = clientSlice.actions
export default clientSlice.reducer