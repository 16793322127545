import {createSlice} from '@reduxjs/toolkit';
import { Contacts } from '../../api/interface';

interface ContactsState {
    contacts: Contacts[]
}
const initialState: ContactsState = {
    contacts: []
}

export const newContactSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewContacts(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewContacts
} = newContactSlice.actions
export default newContactSlice.reducer