import {FC} from 'react'
import { BigCalendarEventComponentProps } from './interface'
import { useAppSelector, useAppDispatch } from '../../store/reduxHooks'
import classes from './bigcalendareventcomponent.module.scss'
import dayjs from 'dayjs';
import cx from 'classnames'
import { BsFillBookmarkFill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { changeInterface } from '../../store/newSlices/calendarEventShowInterface';
import { FaExclamation } from 'react-icons/fa';

export const BigCalendarEventComponent:FC <BigCalendarEventComponentProps> = (props) => {
    const {event} = props
    const {title, start, lawyer, disputId, id, end, conclusion} = event
    const lawyers = useAppSelector(state => state.users.users)
    const myUser = lawyers.find((user) => user.id === lawyer)

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases) 
    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]

    const myCase = cases.find((disput) => disput.id === disputId)

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const disputesIDs = disputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const myDisput = disputes.find((disput) => disput.id === disputId)

    const customTime = dayjs(start).format('HH:mm')


    const navigate = useNavigate()
    const currentPath = window.location.pathname;  

    const dispatch = useAppDispatch()

    const handleLinkClick = () => {
        // if(currentPath.includes('/admin')) {
        //     if(disputesIDs.includes(disputId))
        //     {
        //         navigate(`/disputes/${disputId}`)
        //     } else {navigate(`/cases/${disputId}`)}
        // } else {dispatch(changeInterface({id}))}
        dispatch(changeInterface({id}))

    }

    // const handleLinkClick = () => dispatch(changeInterface({id}))
    const EventFinihTime = dayjs(end).valueOf()
    const now = dayjs().valueOf()

    const checkLostEvent = () => {
        if(EventFinihTime < now && conclusion === '') {
            return true 
        } else {return false}
    }
    const lostEvent = checkLostEvent()


  return (
    <div 
    // className={classes.loosedRoot}
    className={cx(classes.root, lostEvent === true && classes.loosedRoot)} 
    onClick={handleLinkClick}>
        <div className={classes.footer}>
            <div className={classes.title}>
                {title}
            </div>
            {lostEvent === false ? (<div className={cx(classes.tagMark,
                myDisput?.teg === 'Взыскание задолженности'  && classes.tagMarkDolgi,
                myDisput?.teg === 'Деликты' && classes.tagMarkDelicti,
                myDisput?.teg === 'Банкротство' && classes.tagMarkBancrot,
                myDisput?.teg === 'Недвижимость' && classes.tagMarkRealEstate,
                myDisput?.teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
                myDisput?.teg === 'Корпоративные споры' && classes.tagMarkKorporati,
                myDisput?.teg === 'Налоговые споры' && classes.tagMarkNalogi,
                myDisput?.teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,

                myCase?.teg === 'Взыскание задолженности'  && classes.tagMarkDolgi,
                myCase?.teg === 'Деликты' && classes.tagMarkDelicti,
                myCase?.teg === 'Банкротство' && classes.tagMarkBancrot,
                myCase?.teg === 'Недвижимость' && classes.tagMarkRealEstate,
                myCase?.teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
                myCase?.teg === 'Корпоративные споры' && classes.tagMarkKorporati,
                myCase?.teg === 'Налоговые споры' && classes.tagMarkNalogi,
                myCase?.teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
            )}>
                <BsFillBookmarkFill size={18} />
            </div>) : (
                <div className={classes.lostEvent}>
                    <FaExclamation size={18} />
                </div>
            )}
        </div>
        <div className={classes.footer}>
            <div className={cx(classes.specsSud, 
                        myUser?.specs === 'IT-отдел' && classes.specsIt,
                        myUser?.specs === 'CEO' && classes.specsCeo,
                        myUser?.specs === 'Ассистент CEO' && classes.specsCeo,
                        myUser?.specs === 'Отдел консалтинга' && classes.specsAbonent,
                        myUser?.specs === 'Отдел оценки' && classes.specsOcenka,
                        myUser?.specs === 'Кадастровый отдел' && classes.specsKadastr)}>
            {myUser?.label}
            </div>
            <div>
                {customTime}
            </div>
        </div>

    </div>
  )
}
