import {FC} from 'react';
import { ArchivedCaseClientRootProps } from './interface';
import classes from './deletedcases.module.scss'
import {RiInformationLine} from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames'
import { DeletedCaseItem } from '../../ui/DeletedCaseUi/DeletedCaseItem';
import { useAppSelector } from '../../store/reduxHooks';

export const DeletedCaseClientRoot: FC<ArchivedCaseClientRootProps> = (props) => {
  const {data, clientId} = props;
  const {name} = data;
  const cases = useAppSelector(state => state.newCases.cases)
  const myCases = cases.filter((cas) => cas.isDeleted === true)
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/clients/${clientId}`);
  }
  const {id} = useParams()
  const foundedCase = cases.find((cas) => cas.id === id)
  const activeCases = cases.filter((cas) => cas.isDeleted === true)

  return (
    <div className={cx(classes.root, foundedCase  && classes.selectedRoot, !activeCases.length && classes.emptyRoot)}>
        <div className={classes.title}>
            {name}
            <div className={cx(classes.icon, foundedCase  && classes.selectedIcon)}>
            <RiInformationLine onClick={handleClick} size={25} />

            </div>
        </div>
        <div className={classes.list}>
        {myCases.map((cas) => (
            <DeletedCaseItem caseId={cas.id} data={cas} key={cas.id} />  
        ))}
        </div>

    </div>
  )
}
