
export const selectOptions = [
    { value: "Составление искового заявления", label: "Составление искового заявления" },
    { value: "Составление правового документа", label: "Составление правового документа" },
    { value: "Переговоры с клиентом", label: "Переговоры с клиентом" },
    { value: "Переговоры с экспертной организацией", label: "Переговоры с экспертной организацией" },
    { value: "Поездка", label: "Поездка" },
    { value: "Подготовка к судебному заседанию", label: "Подготовка к судебному заседанию" },
    { value: "Консультирование", label: "Консультирование" },
    { value: "other", label: "Иное" },
  ];


  export const selectTagsOptions = [
    { value: "Взыскание задолженности", label: "Взыскание задолженности" },
    { value: "Деликты", label: "Деликты" },
    { value: "Банкротство", label: "Банкротство" },
    { value: "Недвижимость", label: "Недвижимость" },
    { value: "Споры с гос. и имущ. организациями", label: "Споры с гос. и имущ. организациями" },
    { value: "Корпоративные споры", label: "Корпоративные споры" },
    { value: "Налоговые споры", label: "Налоговые споры" },
    { value: "Интеллектуальная собственность", label: "Интеллектуальная собственность" },
    { value: "Иное", label: "Иное" },
  ];

  export const selectDepartmentOptions = [
    { value: "law", label: "Судебный отдел" },
    { value: "abonent", label: "Абонентский отдел" },
  ];

  export const userOptions = [
    { value: "1", label: "Иван Абрамов" },
    { value: "6", label: "Елена Батухтина" },
    { value: "7", label: "Дарья Печерская" },
    { value: "4", label: "Алена Лемешева" },
]