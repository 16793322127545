import {createSlice} from '@reduxjs/toolkit';
import { Disput } from '../../api/interface';

interface DisputesState {
    disputes: Disput[]
}
const initialState: DisputesState = {
    disputes: []
}

export const newDisputesSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewDisputes(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewDisputes
} = newDisputesSlice.actions
export default newDisputesSlice.reducer