import {FC, useState} from 'react'
import { CompleteCaseMenuProps } from './interface'
import classes from './completecasemenu.module.scss'
import { useAppSelector } from '../../../store/reduxHooks'
import { useHandleCompleteNewAbonentCase, useHandleCompleteNewKadastrCase, useHandleCompleteNewOtcenkaCase, useHandleCompleteNewSudArbitrCase, useHandleCompleteNewSudBancrotCase, useHandleCompleteNewSudSimpleCase } from '../../../hooks/useNewCases'
import { useNavigate } from 'react-router'

export const CompleteCaseMenu: FC <CompleteCaseMenuProps> = (props) => {
    const {handleCloseWindow, caseId, data} = props
    const [inputValue, setInputValue] = useState<string>('')

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const abonentCasesIds = abonentCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const kadastrCasesIds = kadastrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const otcenkaCasesIds = otcenkaCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudArbitrCasesIds = sudArbitrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudBancrotCasesIds = sudBancrotCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const sudSimpleCasesIds = sudSimpleCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])

    const completeAbonentCase = useHandleCompleteNewAbonentCase(data, inputValue)
    const completeKadastrCase = useHandleCompleteNewKadastrCase(data, inputValue)
    const completeOtchenkaCase = useHandleCompleteNewOtcenkaCase(data, inputValue)
    const completeSudArbitrCase = useHandleCompleteNewSudArbitrCase(data, inputValue)
    const completeSudSimpleCase = useHandleCompleteNewSudSimpleCase(data, inputValue)
    const completeSudBancrotCase = useHandleCompleteNewSudBancrotCase(data, inputValue)
    const navigate = useNavigate()

    const handleCompleteCaseClick = () => {
        if(inputValue.length > 50) {
            if(abonentCasesIds.includes(caseId)) {
                completeAbonentCase()
            }
            if(kadastrCasesIds.includes(caseId)) {
                completeKadastrCase()
            }
            if(otcenkaCasesIds.includes(caseId)) {
                completeOtchenkaCase()
            }
            if(sudArbitrCasesIds.includes(caseId)) {
                completeSudArbitrCase()
            }
            if(sudBancrotCasesIds.includes(caseId)) {
                completeSudSimpleCase()
            }
            if(sudSimpleCasesIds.includes(caseId)) {
                completeSudBancrotCase()
            }
            handleCloseWindow()
            setInputValue('')
            navigate('/cases')
        } else {alert('Необходимо написать заключение')}
    }

  return (
    <div className={classes.root}>
        <div className={classes.annotationTitle}>
            Завершить дело
        </div>
        <div className={classes.completeCase}>
            <textarea wrap='true' className={classes.myArea} value={inputValue} onChange={e => setInputValue(e.target.value)}  name="" id="" placeholder='Введите заключение'></textarea>
        </div>
        <button className={classes.button} onClick={handleCompleteCaseClick}>
            Завершить
        </button>
    </div>
  )
}
