import {FC} from 'react';
import { ArchivedCaseClientRootProps } from './interface';
import classes from './archivedcases.module.scss'
import {RiInformationLine} from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router-dom';
import cx from 'classnames'
import { ArchivedCaseItem } from '../../ui/ArchivedCaseUi/ArchivedCaseItem';
import { useAppSelector } from '../../store/reduxHooks';

export const ArchivedCaseClientRoot: FC<ArchivedCaseClientRootProps> = (props) => {
  const {data, clientId} = props;
  const {name} = data;
  const cases = useAppSelector(state => state.newCases.cases)

  const myCases = cases.filter((cas) => cas.isArchived === true)

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/clients/${clientId}`);
  }
  const {id} = useParams()
  const foundedCase = cases.find((cas) => cas.id === id)
  const activeCases = cases.filter((cas) => cas.isArchived === true)

  return (
    <div className={cx(classes.root, foundedCase  && classes.selectedRoot, !activeCases.length && classes.emptyRoot)}>
        <div className={classes.title}>
            {name}
            <div className={cx(classes.icon, foundedCase  && classes.selectedIcon)}>
            <RiInformationLine onClick={handleClick} size={25} />

            </div>
        </div>
        <div className={classes.list}>
        {myCases.map((cas) => (
            <ArchivedCaseItem caseId={cas.id} data={cas} key={cas.id} />  
        ))}
        </div>

    </div>
  )
}
