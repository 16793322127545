import {createSlice} from '@reduxjs/toolkit';
import { Document } from '../../api/interface';

interface DocumentState {
    docs: Document[];
}   
const initialState: DocumentState = {
    docs: [],
}

export const newDocumentSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewDocuments(state, action) {
            return action.payload
        },
    }
})

export const {
    getNewDocuments
} = newDocumentSlice.actions
export default newDocumentSlice.reducer