import {createSlice} from '@reduxjs/toolkit';
import { Task } from '../../api/interface';

interface TaskState {
    tasks: Task[];
}
const initialState: TaskState = {
    tasks: [],
}

export const newClientTaskSlice = createSlice({
    name: 'newEvents',
    initialState,
    reducers: {
        getNewClientTasks(state, action) {
            return action.payload
        },

    }
})

export const {
    getNewClientTasks
} = newClientTaskSlice.actions
export default newClientTaskSlice.reducer