import {FC, useState, useEffect} from 'react'
import { CompletedTasksScreenProps } from './interface'
import classes from './completedtasksscreen.module.scss'
import { useAppSelector } from '../../../store/reduxHooks'
import dayjs from 'dayjs'
import {CompletedTasksLawyerRoot} from '../CompletedTasksLawyerRoot'
import cx from 'classnames'
import { RiCloseFill } from 'react-icons/ri'
import {GiSadCrab} from 'react-icons/gi'

export const CompletedTasksScreen: FC<CompletedTasksScreenProps> = (props) => {
    const { disputId, handleOpenTaskInfoClick} = props
    
    const tasks = useAppSelector(state => state.newDiputTasks.tasks)
    const myTasks = tasks.filter((task) => task.fatherId === disputId)
    const allLawyers = useAppSelector((state) => state.users.users)

    const today = dayjs().endOf('day').valueOf()
    const monthAgo = today - 2629800000
    const weekAgo = today - 604800000
    const dayAgo = today - 86400000

    const filteredWeekTasks = myTasks.filter((task) => task.completeTime > weekAgo)
    const filteredDayTasks = myTasks.filter((task) => task.completeTime > dayAgo)
    const filteredMonthTasks = myTasks.filter((task) => task.completeTime > monthAgo)

    useEffect(() => {
      setFilter(filteredWeekTasks)
      setSelect('week')
    }, [handleOpenTaskInfoClick])

    const [filter, setFilter] = useState(filteredWeekTasks)
    const [select, setSelect] = useState('week')

    const setDayClick = () => {
      setFilter(filteredDayTasks)
      setSelect('day')
    }
    const setWeekClick = () => {
      setFilter(filteredWeekTasks)
      setSelect('week')
    }
    const setMonthClick = () => {
      setFilter(filteredMonthTasks)
      setSelect('month')
    }
    const checkLength = () => {
      if(filter.length) {
        return true
      }
      else {return false}
    }
    checkLength()
    const isCheck = checkLength()

    const handleClick = () => {
      handleOpenTaskInfoClick()
      setFilter([])
      setSelect('month')
    }

  return (
    <div className={classes.root}>
      <button className={classes.closeIcon} onClick={handleClick}>
        <RiCloseFill size={22} />
      </button>
      <div className={classes.buttonBox}>
        <button className={cx(classes.button, select === 'day' && classes.buttonActive)} onClick={setDayClick}>Сегодня</button>
        <button className={cx(classes.button, select === 'week' && classes.buttonActive)} onClick={setWeekClick}>Неделя</button>
        <button className={cx(classes.button, select === 'month' && classes.buttonActive)} onClick={setMonthClick}>Месяц</button>
      </div>
    {isCheck === true ? (
    <div className={classes.fullRoot}>
        {allLawyers.map(
              (lawyer) => (
                  <CompletedTasksLawyerRoot key={lawyer.id} tasks={filter} lawyer={lawyer} />
              )
          )}
    </div>
    ) : (
      <div className={classes.emptyRoot}>
        <div className={classes.title}>
          <div>
          <GiSadCrab size={50} />
          </div>
        УПС!!! <br/> Похоже, что вы забыли поработать :(
        </div>
      </div>
    )}
  </div>
  )
}

