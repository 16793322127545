import {createSlice} from '@reduxjs/toolkit';
import { ProofItem } from '../../api/interface';

interface ProofState {
    proofs: ProofItem[]
}

const initialState: ProofState = {
    proofs: []
}

export const newProofSlice = createSlice({
    name: 'newProofs',
    initialState,
    reducers: {
        getNewProofs(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewProofs
} = newProofSlice.actions
export default newProofSlice.reducer