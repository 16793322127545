import {FC, useState, useEffect} from "react";
import classes from './cases.module.scss'
import { CasesProps } from "./interface";
import { useAppSelector } from "../../store/reduxHooks";
import { useNavigate, useParams } from 'react-router-dom';
import { CaseScreen } from "../../ui/CaseUi/CaseScreen";
import { CaseClientRoot } from "../../ui/CaseUi/CasesClientRoot";
import { BsTrash} from 'react-icons/bs';
import {BiArchiveIn} from 'react-icons/bi';
import { useFindMyUser } from "../../hooks/useUsers";
import { MdArrowDropDown } from "react-icons/md";
import cx from 'classnames';
import { CaseScreenBancrot } from "../../ui/CaseUi/CaseScreenBancrot";
import { CaseScreenAbonent } from "../../ui/CaseUi/CaseScreenAbonent";
import { useAuth } from "../../hooks/useAuth";

export const Cases: FC<CasesProps> = (props) => {
    const { id } = useParams();
    const navigate = useNavigate()
    const myUser = useFindMyUser()

    const {isAuth} = useAuth()

    useEffect(() => {
      if(!isAuth) {
        window.location.assign('/')
      }
    })

    const checkId = () => {
      if(id !== undefined) {
        let newId = id
        return newId
      } else{
        let newId = '858'
        return newId
      }
    }
    const checkedId = checkId()

    const clients = useAppSelector(state => state.newClients.clients);

    const abonentCases = useAppSelector(state => state.newAbonentCases.cases)
    const kadastrCases = useAppSelector(state => state.newKadastrCases.cases)
    const otcenkaCases = useAppSelector(state => state.newOtcenkaCases.cases)
    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)

    const abonentCasesIDS = abonentCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const kadastrCasesIDS = kadastrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const otcenkaCasesIDS = otcenkaCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const allAbonentCasesIDS = [...abonentCasesIDS, ...kadastrCasesIDS, ...otcenkaCasesIDS]

    const sudArbitrCasesIDS = sudArbitrCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const sudSimpleCasesIDS = sudSimpleCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])
    const allSudCasesIDS = [...sudArbitrCasesIDS, ...sudSimpleCasesIDS]

    const sudBancrotCasesIDS = sudBancrotCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.id], [])


    const cases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]
    const otherCases = [...abonentCases, ...kadastrCases, ...otcenkaCases, ...sudArbitrCases,...sudSimpleCases]
    
    const disputes = useAppSelector(state => state.newDisputes.disputes);

    const myDisputes = disputes.filter((disput) => disput.lawyers.includes(myUser))
    const myDisputesIds = myDisputes.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.caseId], [])
  
    const myCases = cases.filter((cas) => myDisputesIds.includes(cas.id))
    const myClientsIds = myCases.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])

    const myCasesNew = otherCases.filter((cas) => cas.lawyers.includes(myUser))
    const myCasesNewIds = myCasesNew.reduce<string[]>((acc, arrayItem) => [...acc, arrayItem.clientId], [])

    const newMyClientsIds = [...myClientsIds, ...myCasesNewIds]

    const myClients = clients.filter((client) => newMyClientsIds.includes(client.id))
    const otherClients = clients.filter((client) => !newMyClientsIds.includes(client.id))

    const handleGotoArchiveClick = () => {
      navigate('/archivedcases');
    }
    const handleGotoDeletedClick = () => {
      navigate('/deletedcases');
    }

    const bancrotCase = sudBancrotCases.find((cas) => cas.id === id);  

    const sudCases = [...sudSimpleCases, ...sudArbitrCases]
    const oneCase = sudCases.find((cas) => cas.id === id);

    const abKadOtCases = [...abonentCases, ...kadastrCases, ...otcenkaCases,]
    const abonentCase = abKadOtCases.find((cas) => cas.id === id);
    
    const [openClientRoot, setOpenClientRoot] = useState(false)
    const handleOpenClientRootClick = () => setOpenClientRoot(value => !value)

  return (
    <div className={classes.root}>
      <div className={classes.navigationPanel}>

          <div className={classes.list}>
            <div className={classes.title}>Мои дела</div>
            {myClients.map((client) => (
                <CaseClientRoot clientId={client.id} data={client} key={client.id} />
              ))}
              <div onClick={handleOpenClientRootClick} className={classes.titleVsButton}>
                <div className={classes.title}>Все дела</div>
                <div  
                  className={cx(classes.iconRotate, openClientRoot === true && classes.iconRotateTrue )}
                >
                  <MdArrowDropDown size={25} />
                </div>
              </div>
              <div className={cx(classes.clientRoot, openClientRoot === true && classes.clientRootOpen )}>
                {otherClients.map((client) => (
                  <CaseClientRoot clientId={client.id} data={client} key={client.id} />
                ))}              
              </div>
        </div>
          <div className={classes.archiveAndDeletedClient}>
                <div className={classes.archDel} title="Архивные дела" onClick={handleGotoArchiveClick}>
                <BiArchiveIn size={35} />
                </div>
                <div className={classes.archDel} title="Удаленные дела" onClick={handleGotoDeletedClick}>
                <BsTrash size={35} />
                </div>
              </div>
      </div>
        
        <div className={classes.description}>
        {abonentCase  ? (
          <CaseScreenAbonent data={abonentCase} {...abonentCase} />
        ) : oneCase  ? (
          <CaseScreen data={oneCase} {...oneCase} />
        ) : bancrotCase  ? (
          <CaseScreenBancrot data={bancrotCase} {...bancrotCase} />
        ) : null}
        </div>

    </div>
  )
}
