import { FC, useState } from 'react'
import { AddEventFormProps } from './interface'
import DatePicker, {registerLocale} from 'react-datepicker'
import classes from './addeventform.module.scss'
import dayjs from 'dayjs';
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale";
import { useAppSelector } from '../../store/reduxHooks';
import Select from 'react-select'
import { useHandleAddEvents } from '../../hooks/useNewEvents';
import { MyInput } from '../MyInput';
import { setHours, setMinutes } from 'date-fns';
import { useFindMyUser } from '../../hooks/useUsers';

require('dayjs/locale/ru')
dayjs.locale('ru')
registerLocale('ru', ru)

const AddEventForm: FC<AddEventFormProps> = (props) => {
    const {handleClick} = props
    const [startDate, setStartDate] = useState<any>(new Date());

    const myUser = useFindMyUser()

    const sudArbitrCases = useAppSelector(state => state.newSudArbitrCases.cases)
    const sudBancrotCases = useAppSelector(state => state.newSudBancrotCases.cases)
    const sudSimpleCases = useAppSelector(state => state.newSudSimpleCases.cases)
    const cases = [...sudArbitrCases, ...sudBancrotCases, ...sudSimpleCases]
    const activeCases = cases.filter((cas) => cas.isActive === true)

    const disputes = useAppSelector(state => state.newDisputes.disputes)
    const activeDisputes = disputes.filter((disput) => disput.isActive === true)
  
    const casesWithNumbersOptions = activeCases.map(item => {
      const option = {
        value: '',
        label: ''
      }
      option.value = item.id
      option.label = `${item.name} ${item.number}`
      return option
      })
  

    const clientsOptions = activeDisputes.map(item => {
      const disput = {
        value: '',
        label: ''
      }
      disput.value = item.id
      disput.label = item.name
      return disput
    })
  
    const users = useAppSelector(state => state.users.users)

    const usersOptions = users.map(item => {
      const user = {
        value: '',
        label: ''
      }
      user.value = item.id
      user.label = item.label
      return user
    })


    const [client, setClient] = useState<any>([] as any)
    const [user, setUser] = useState<any>([] as any)
    const disputId = client.value
    const checkLawyer = () => {
      if(user.value === undefined) {
        let fixLawyer = myUser
        return fixLawyer
      }
      else {
        let fixLawyer = user.value
        return fixLawyer
      }
    }

    const lawyer = checkLawyer()
    const updateTime = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss+07:00')
    const myTime = dayjs(startDate).valueOf()
    const finishTimeMS = myTime + 3600000
    const finishTime = dayjs(finishTimeMS).format('YYYY-MM-DDTHH:mm:ss+07:00')

    const [eventName, setEventName] = useState<string>('');

    const addEvent = useHandleAddEvents(eventName, updateTime, finishTime, lawyer, disputId )

    const addEventToList = () => {
      if(eventName.length && lawyer && disputId) {
        addEvent()
        handleClick()
        setEventName('')      
      }
      else {alert('Проверьте корректность введенных данных')}

    }
  return (
    <div className={classes.root}>
        <MyInput 
          inputName={'Название'} 
          value={eventName} 
          handleInput={setEventName} 
          placeholder={'Введите название'} 
          type={'text'} 
        />
        <Select
          isClearable={false}
          className={classes.select}
          classNamePrefix="select"
          options={casesWithNumbersOptions}
          onChange={(chioce) => setClient(chioce)} 
          defaultValue={{label: 'Дело', value: ''}}
        />
        <Select
          isClearable={false}
          className={classes.select}
          classNamePrefix="select"
          options={clientsOptions}
          onChange={(chioce) => setClient(chioce)} 
          defaultValue={{label: 'Спор', value: ''}}
        />
        <Select
          isClearable={false}
          className={classes.select}
          classNamePrefix="select"
          options={usersOptions}
          onChange={(chioce) => setUser(chioce)} 
          defaultValue={{label: `Пользователь`, value: ``}}
        />
        <DatePicker 
          locale='ru'
          dateFormat='dd.MM.yyyy'
          selected={startDate} 
          showTimeSelect
          timeIntervals={10}
          minTime={setHours(setMinutes(new Date(), 0), 8)}
          maxTime={setHours(setMinutes(new Date(), 30), 17)}  
          onChange={(date) => setStartDate(date)} 
        />
        <div className={classes.buttonRoot}>
            <button className={classes.addButton} onClick={addEventToList}>
                Добавить
            </button>        
        </div>

    </div>
    
  )
}

export default AddEventForm