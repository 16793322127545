import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { Disput } from '../../api/interface';

const initialState: Disput[] = []

export const caseSlice = createSlice({
    name: 'cases',
    initialState,
    reducers: {
        getDisputes(state, action) {
            return action.payload
        }
    }
})

export const {
    getDisputes
} = caseSlice.actions
export default caseSlice.reducer