import  { FC, useState } from 'react';
import { ButtonClientBoxProps } from './interface';
import classes from './buttonclientbox.module.scss'
import {BiDotsVerticalRounded, BiArchiveIn} from 'react-icons/bi';
import {BsTrash, BsPencilSquare} from 'react-icons/bs';
import {RiInformationLine} from 'react-icons/ri';
import {SlDocs} from 'react-icons/sl';
import cx from 'classnames';

export const ButtonClientBox: FC<ButtonClientBoxProps> = (props) => {
  const {handleOpenArchiveMenuClick, handleOpenClientDeleteMenuClick, handleOpenChangeClientMenuClick} = props
  const [open, setOpen] = useState(true)
  const openSideBarClick = () => setOpen(value => !value)

  return (
    <div className={classes.buttonBox}>
        <div className={classes.button} title='Информация' >
            <RiInformationLine size={22}/>
        </div>
        <div className={classes.button} >
          <SlDocs size={22} title='Документы' />
        </div>
        <div className={classes.button}>
          <BiDotsVerticalRounded onClick={openSideBarClick} size={22} title='Увидеть больше'/>               
        </div>
        <div className={cx(classes.popUp, open === true && classes.popUpClose)} onClick={e => e.stopPropagation()}>
            <div className={classes.sideBarButton}>
              <BsPencilSquare size={22} title='Редактировать' onClick={handleOpenChangeClientMenuClick}/>
            </div>
            <div className={classes.sideBarButton} title='В Архив' onClick={handleOpenArchiveMenuClick} >
              <BiArchiveIn size={22}/>
            </div>
            <div className={classes.sideBarButton} title='Удалить' onClick={handleOpenClientDeleteMenuClick}>
            <BsTrash size={22}/>
            </div>
            
        </div>
    </div>
  )
}