import {FC, useState} from 'react';
import { AddClientFormProps } from './interface';
import classes from './addclientform.module.scss';
import { MyInput } from '../MyInput';
import InputMask from 'react-input-mask';
import { useHandleAddNewClients } from '../../hooks/useNewClients';

export const AddClientForm: FC<AddClientFormProps> = (props) => {
    const [clientName, setClientName] = useState<string>([] as any);
    const [clientPhone, setClientPhone] = useState<string>([] as any);
    const [clientINN, setClientINN] = useState<string>([] as any);
    const [clientOGRN, setClientOGRN] = useState<string>([] as any);
    const [clientKPP, setClientKPP] = useState<string>([] as any);
    const {handleClose, } = props;

    const [clientType, setClientType] = useState<string>('Юр. лицо');
   
    const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientType(event.target.value);
      };

    const addClients = useHandleAddNewClients(
        clientName,
        clientPhone,
        clientINN,
        clientOGRN,
        clientKPP,
        clientType
    ) 
    
    const handleClick = () => {
        if(clientName.trim().length) {
            addClients()
            handleClose()
            setClientName('')
            setClientPhone('')
            setClientINN('')
            setClientOGRN('')
            setClientKPP('')
        } else {alert('Ошибка')}
    };

    return (
    <div className={classes.root}>
        <div className={classes.header}>
            <div className={classes.content}>
            <div className={classes.title}>
                Клиент
            </div>
            <div>
                <input type="radio" name="radio" value="Юр. лицо"
                checked={clientType === 'Юр. лицо' ? true : false}
                onChange={radioHandler} />
                <label htmlFor="">Юр. лицо</label>

                <input type="radio" name="radio" value="Физ. лицо"
                checked={clientType === 'Физ. лицо' ? true : false}
                onChange={radioHandler} />
                        <label htmlFor="">Физ. лицо</label>


                <input type="radio" name="radio" value="ИП"
                checked={clientType === 'ИП' ? true : false}
                onChange={radioHandler} />
                <label htmlFor="">ИП</label>

            </div>
            
                <MyInput 
                    value={clientName}
                    type={'text'}
                    handleInput={setClientName}
                    inputName={'Название Организации'}
                    placeholder={'Введите название организации'} 
                />
                <div>Телефон</div>
                <InputMask 
                    className={classes.input}
                    mask='+7(999) 999-99-99' 
                    placeholder='+7(999) 123-45-67'
                    value={clientPhone} 
                    onChange={(e) => setClientPhone(e.target.value)}>
                </InputMask>

                <MyInput 
                    value={clientINN}
                    type={'number'}
                    handleInput={setClientINN}
                    inputName={'ИНН'}
                    placeholder={'ИНН'} 
                />

                <div>ОГРН</div>
                <InputMask 
                    className={classes.input}
                    mask='9999999999999' 
                    placeholder='ОГРН'
                    value={clientOGRN} 
                    onChange={(e) => setClientOGRN(e.target.value)}>
                </InputMask>

                <div>КПП</div>
                <InputMask 
                    className={classes.input}
                    mask='999999999' 
                    placeholder='КПП'
                    value={clientKPP} 
                    onChange={(e) => setClientKPP(e.target.value)}>
                </InputMask>

                <button className={classes.button} onClick={handleClick}>Добавить</button>
            </div>
        </div>
    </div>
  )
}