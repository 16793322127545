import {FC, useState} from 'react'
import { AddBillingItemProps } from './interface'
import classes from './addbillingitem.module.scss'
import { MyInput } from '../../../../components/MyInput';
import { RiCloseFill } from 'react-icons/ri';
import Select from 'react-select'
import { useAppSelector } from '../../../../store/reduxHooks';
import { useHandleAddBillingItemFromTasks } from '../../../../hooks/useNewBillingItem';
import { useFindMyUser } from '../../../../hooks/useUsers';

export const AddBillingTodoItem:FC<AddBillingItemProps> = (props) => {
    const {data, clientId,  handleOpenCloseBillingMenu} = props
    const {title, fatherId} = data
    const documents = useAppSelector(state => state.newDocuments.docs)
    const myClientDocuments = documents.filter((document) => document.clientId === clientId)
    const completeUser = useFindMyUser()
    const [biilingItemName, setBillingItemName] = useState(title)
    const [biilingItemHours, setBillingItemHours] = useState(1)
    const [userOptionChoice, setUserOptionChoice] = useState<{value: string, label:string} | null>({value: '', label: ''})
    const docId = userOptionChoice?.value
    const myOwnDoc = myClientDocuments.find(doc => doc.id === userOptionChoice?.value)
    const docPrice = myOwnDoc?.lawyerPrice

    const myDocsOption = myClientDocuments.map(item => {
        const option = {
            value: '',
            label: '',
            price: 0
        }
        option.value = item.id
        option.label = item.name
        option.price = item.lawyerPrice
        return option
    })
    
    const addBillingItem =  useHandleAddBillingItemFromTasks(biilingItemName, biilingItemHours,  fatherId, completeUser, docId, docPrice)
    const handleAddBillingFromTaskClick = () => {
        addBillingItem()
        handleOpenCloseBillingMenu()
        setBillingItemHours(1)
    }

  return (
    <div className={classes.deleteCaseMenu} onClick={e => e.stopPropagation()}>
        <button className={classes.closeIcon} onClick={handleOpenCloseBillingMenu}>
            <RiCloseFill size={22} />
        </button>
        Добавить в биллинг задачу "{title}"
        <div>
            <Select
                defaultValue={{label: 'Выберите договор', value: ''}}
                name="colors"
                options={myDocsOption}
                className={classes.select}
                classNamePrefix="select"
                onChange={(chioce) => setUserOptionChoice(chioce)}
            />  
        </div>
        {userOptionChoice?.value ? (
        <>
            <div className={classes.inputRoot}>          
                <div className={classes.inputName}>
                    <MyInput inputName={'Название'} value={biilingItemName} handleInput={setBillingItemName} placeholder={biilingItemName} type={'text'} />
                </div>
                <div className={classes.inpuIncrement}>
                    <MyInput inputName={'Часы'} value={biilingItemHours} handleInput={setBillingItemHours} placeholder={biilingItemHours} type={'number'} />
                </div>
            </div>
            <button className={classes.billButton} onClick={handleAddBillingFromTaskClick}>Добавить</button>
        </>
        ) : null}
        
    </div>
  )
}
