import {FC} from 'react';
import { ArchivedCaseItemProps } from './interface';
import classes from './archivedcaseitem.module.scss'
import { useNavigate, useParams } from 'react-router';
import cx from 'classnames';
import {BsFolder2Open} from 'react-icons/bs';

export const ArchivedCaseItem:FC <ArchivedCaseItemProps> = (props) => {
    // const {data, caseId} = props;
    // const {name, teg} = data;
    // const navigate = useNavigate();
    // const handleClick = () => {
    //   navigate(`/archivedcases/${caseId}`);
    // }
    // const {id} = useParams()
  return (
    // <div className={cx(classes.caseItem, caseId === id && classes.caseItemActive,
    //   teg === 'Взыскание задолженности' && caseId === id && classes.caseBorderDolgi,
    //   teg === 'Деликты' && caseId === id && classes.caseBorderDelicti,
    //   teg === 'Банкротство' && caseId === id && classes.caseBorderBancrot,
    //   teg === 'Недвижимость' && caseId === id && classes.caseBorderRealEstate,
    //   teg === 'Споры с гос. и имущ. организациями' && caseId === id && classes.caseBorderGosSpori,
    //   teg === 'Корпоративные споры' && caseId === id && classes.caseBorderKorporati,
    //   teg === 'Налоговые споры' && caseId === id && classes.caseBorderNalogi,
    //   teg === 'Интеллектуальная собственность' && caseId === id && classes.caseBorderIntellect,
    //   teg === 'Иное' && caseId === id && classes.caseBorderOther,
    //   )}
    // onClick={handleClick}>
    //             <div className={classes.icon}>
    //                <BsFolder2Open size={25} />
    //             </div>
    //             <div className={classes.caseBody}>

    //                 <div className={classes.title}>
    //                     {name}
    //                 </div>
    //             </div>
    //             <div className={cx(classes.tagMark, 
    //     teg === 'Взыскание задолженности' && classes.tagMarkDolgi,
    //     teg === 'Деликты' && classes.tagMarkDelicti,
    //     teg === 'Банкротство' && classes.tagMarkBancrot,
    //     teg === 'Недвижимость' && classes.tagMarkRealEstate,
    //     teg === 'Споры с гос. и имущ. организациями' && classes.tagMarkGosSpori,
    //     teg === 'Корпоративные споры' && classes.tagMarkKorporati,
    //     teg === 'Налоговые споры' && classes.tagMarkNalogi,
    //     teg === 'Интеллектуальная собственность' && classes.tagMarkIntellect,
    //     caseId === id && classes.tagMarkActive
    //     )}>
    //     </div>
    //         </div>
    <></>
  )
}
