import {createSlice} from '@reduxjs/toolkit';
import { SudBancrotCase } from '../../api/interface';

interface SudBancrotCasesState {
    cases: SudBancrotCase[]
}
const initialState: SudBancrotCasesState = {
    cases: []
}

export const newSudBancrotCasesSlice = createSlice({
    name: 'newSudSimpleSlice',
    initialState,
    reducers: {
        getNewBancrotCases(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewBancrotCases
} = newSudBancrotCasesSlice.actions
export default newSudBancrotCasesSlice.reducer