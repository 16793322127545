import {createSlice} from '@reduxjs/toolkit';
import { AbonentCase } from '../../api/interface';

interface OtcenkaCasesState {
    cases: AbonentCase[]
}
const initialState: OtcenkaCasesState = {
    cases: []
}

export const newOtcenkaCasesSlice = createSlice({
    name: 'newOtcenkaSlice',
    initialState,
    reducers: {
        getNewOtcenkaCases(state, action) {
            return action.payload
        }
    }
})

export const {
    getNewOtcenkaCases
} = newOtcenkaCasesSlice.actions
export default newOtcenkaCasesSlice.reducer